<template>
  <div
    class="
      absolute
      w-full
      bg-black bg-opacity-50
      h-screen
      flex
      justify-center
      items-center
      -mt-10
      z-40
    "
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6"
    >
    <p class="mt-3">{{$t("member_change_text_1")}}</p>
    <p class="mb-3"><b>{{$t("member_change_text_2")}}</b></p>
      <button
      @click="setModalErrorText"
        class="
          bg-tribugreen-light
          text-tribugreen-regular
          w-full
          py-3
          rounded-full
          mt-5
          cursor-pointer
          hover:bg-tribugreen-regular
          hover:text-white
        "
      >
        <p>OK</p>
      </button>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";

export default {
  directives: {
    ClickAway: directive
  },

  methods: {
    onClickAway() {
        this.setModalErrorText()

    },
    setModalErrorText(){
      this.$store.commit("setOutsideTextErrorModal",true)
      this.$store.commit("setModalErrorText", false);
      document.location.reload()
    },

  },
};
</script>
