<template>
  <div class="w-full flex  h-screen" :class="isMobile?' bg-tribugreen-light':'background-auth'">
    <div v-if="!isMobile" class="w-1/2"></div>
    <div :class="isMobile?'w-full':'w-1/2'" class="bg-white relative rounded-xl my-10 mx-10 h-full" style="max-width:500px; max-height:550px">
    <div class="absolute right-0 mr-5 mt-4 text-right">
      <div @click="showLanguages=true" class="flex pl-2 cursor-pointer">{{language}} <img class="ml-2" src="../../assets/icons/down-one.svg"/></div>
      <div class="border px-3 bg-white mt-1 cursor-pointer" v-if="showLanguages">
        <div @click="setLanguage('FR')" v-if="language !== 'FR'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">FR</div>
        <div @click="setLanguage('NL')" v-if="language !== 'NL'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">NL</div>
        <div @click="setLanguage('EN')" v-if="language !== 'EN'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">EN</div>
        <div @click="setLanguage('DE')" v-if="language !== 'DE'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">DE</div>
      </div>
    </div>
      <div class="flex flex-col w-full items-center">
        <div class="w-full flex justify-center border-b border-tribugray-light">
          <img src="../../assets/logo-black.svg" class="w-12 my-5"/>
        </div>
        <p v-if='tribe' style="font-size:16px" class="text-center my-5 font-inter font-bold">{{$t('login_invite_title')}}<br>{{tribe}}</p>
        <p v-else class="my-7 font-inter font-bold text-lg">{{$t('login_title')}}</p>
        <!--FORM-->
        <input type="email" v-model="email" v-on:keyup.enter="signIn()" placeholder="E-mail" style="width:280px" class="px-4 py-2 input-auth border border-tribugray-semilight rounded-full"/>
        <div style="width:280px" class="px-4 py-2 mt-4 input-auth border border-tribugray-semilight rounded-full flex justify-between">
            <input
              type="password"
              :placeholder="$t('password')"
              v-model="password"    
              v-on:keyup.enter="signIn()"
              id="passwordfield"
            />
          <div @click="switchVisibility()" class="cursor-pointer">
              <img v-if="showPassword" class="w-5" src="../../assets/icons/preview-close.svg"/>
               <img v-else class="w-5" src="../../assets/icons/preview-open.svg"/>
          </div>
        </div>
        
        <button @click="signIn()" style="width:280px; font-size:14px" class="mt-4 px-4 py-2 text-center bg-tribugreen-regular text-white rounded-full hover:bg-tribugreen-medium">{{$t("login")}}</button>
        <!--OR-->
        <div class="flex my-4 items-center" style="width:280px">
          <div style="width:120px; height:1px" class="border-t border-tribugray-semilight"></div>
          <div class="text-tribugray-semilight px-4">{{$t('or')}}</div>
          <div style="width:120px; height:1px" class="border-t border-tribugray-semilight"></div>
        </div>
        <!--GOOGLE - APPLE -->
        <div class="text-sm text-white w-full" style="width:280px">
          <div
            class="rounded-full px-4 py-2 mb-3 flex text-black border bg-white hover:bg-gray-50 border-gray-200 items-center cursor-pointer"
            @click="useAuthProvider('google')"
          >
            <img
              src="../../assets/img/google-logo.svg"
              class="mr-2"
              style="width:18px"
              alt=""
            />
            <p class="ml-2">{{ $t("connexion_with") }}Google</p>
          </div>
          <div
            class="rounded-full py-2 flex bg-black hover:bg-gray-800 items-center cursor-pointer"
            @click="useAuthProvider('apple')"
          >
            <img
              src="../../assets/img/apple-logo.svg"
              class="ml-4 mr-2"
              style="width:18px"
            />
            <p class="ml-2">{{ $t("connexion_with") }}Apple</p>
          </div>
          <!--FACEBOOK-->
          <div class="mt-2 ml-4">
            <a
              v-if="language==='FR'"
              class="py-2 flex items-start cursor-pointer"
              target="_blank"
              href='https://faq.mytribunews.com/fr/article/connection-via-facebook-facebook-connect-1iowk6l/'
            >
              <img
                src="../../assets/img/facebook-logo2.svg"
                style="width:18px"
              />
              <p class="ml-2 underline" style="color:#9B9B9B; font-size:10px; padding-bottom:1px">La connexion Facebook n'est plus disponible</p>
            </a>
              <a
              v-if="language==='NL'"
              class="py-2 flex items-start cursor-pointer"
              target="_blank"
              href='https://faq.mytribunews.com/nl/article/ik-kan-mij-niet-meer-met-facebook-inloggen-1y1s0nm/'
            >
              <img
                src="../../assets/img/facebook-logo2.svg"
                style="width:18px"
              />
              <p class="ml-2 underline" style="color:#9B9B9B; font-size:10px; padding-bottom:1px">Facebook connect is niet langer beschikbaar</p>
            </a>
            <a
              v-if="language==='EN'"
              class="py-2 flex items-start cursor-pointer"
              target="_blank"
              href='https://faq.mytribunews.com/en/article/i-cannot-connect-via-facebook-anymore-10u6bhl/'
            >
              <img
                src="../../assets/img/facebook-logo2.svg"
                style="width:18px"
              />
              <p class="ml-2 underline" style="color:#9B9B9B; font-size:10px; padding-bottom:1px">Facebook connect is no longer available </p>
            </a>
              <a
              v-if="language==='DE'"
             class="py-2 flex items-start cursor-pointer"
              target="_blank"
              href='https://faq.mytribunews.com/de/article/ich-kann-mich-nicht-mehr-mit-facebook-einloggen-arrelj/'
            >
              <img
                src="../../assets/img/facebook-logo2.svg"
                style="width:18px"
              />
              <p class="ml-2 underline" style="color:#9B9B9B; font-size:10px; padding-bottom:1px">Facebook connect ist nicht mehr verfügbar</p>
            </a>
          </div>
        </div>
        <!--SIGNUP - FORGOT PASSWORD-->
        <div class="flex justify-between text-sm pt-3" style="font-size:12px;width:280px">
          <div @click="goToSignup()" class="cursor-pointer hover:text-gray-500 ">{{ $t("i_am_new") }}</div>
          <div dusk="forget-password" class="cursor-pointer hover:text-gray-500" @click="goToForgetPassword()">
          {{ $t("forget_password") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Auth, Tribes } from "../../api";
import { Providers } from 'universal-social-auth';
import { directive } from "vue3-click-away";

export default {
  name: "Login",
    directives: {
    ClickAway: directive,
  },
  beforeMount(){
    if(localStorage.token){
      this.$router.push({name: "Workspace"})
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramType = urlParams.get("type");
  if(paramType){
    this.type = paramType
  }
  },
 async mounted(){
    if(localStorage.code){
      console.log(localStorage.code)
      const code = localStorage.code
      const call = await Tribes.getTribeWithCode(code)
      this.tribe = call.tribe
    }
  },
  data() {
    return {
      showLanguages:false,
      email: null,
      password: null,
      type : null,
       dropdownLanguage: false,
       outsideLang: true,
        langs: ["FR", "NL", "EN", "DE"],
      window: {
        width: 0,
        height: 0,
      },
      showPassword:false,
      tribe:null
    };
  },
    created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch:{
    email(value,oldvalue){
      if(value !== oldvalue && this.loginError === true){
        this.$store.commit('setLoginError',false)
      }
    },
    password(value,oldvalue){
      if(value !== oldvalue && this.loginError === true){
        this.$store.commit('setLoginError',false)
      }
    }
  },
  computed:{
    loginError(){
      return this.$store.state.loginError
    },
    language() {
      return this.$store.state.language;
    },
    isMobile() {
      return this.window.width < 880 ? true : false;
    },
  },
  methods:{
    switchVisibility(){
        this.showPassword = !this.showPassword
        const passwordField = document.querySelector('#passwordfield')
        if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
        else passwordField.setAttribute('type', 'password')
    },
    async signIn() {
      await Auth.signIn(this.email, this.password);
    },
    goToSignup() {
      this.$router.push({name:"SignUp"});
    },
    goToForgetPassword() {
      this.$router.push({name:"ForgetPassword"});
    },
    useAuthProvider(provider) {
      this.$Oauth.authenticate(provider, Providers[provider]).then(async (response) => {
        if (response.code) {
          await Auth.useSocialLogin(provider, response.code, response.hash)
          await this.$router.push('/')
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    setLanguage(lang) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramLang = urlParams.get("lang");
      if (paramLang) {
        window.location.href = window.location.origin;
      }
      this.$store.commit("setLanguage", lang);
      this.$i18n.locale = lang;
      this.showLanguages=false
    },
    goToSignup() {
      this.$router.push({name:"SignUp"});
    },
    goToForgetPassword() {
      this.$router.push({name:"ForgetPassword"});
    },
    onClickAwayLanguage() {
      this.outsideLang = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  }
};
</script>
<style>
.background-auth{
  background-image: url("../../assets/img/new-login.jpg");
  background-size: cover;
}
.input-auth{
  font-size:12px;
}
.input-auth::placeholder{
  color:#B4B6B8;
  font-family: 'Inter', sans-serif;
}
</style>