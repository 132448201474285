<template>
  <div class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center" style="z-index:100" :class="showImportPage?'-mt-20 ':'-mt-10  items-center '">
    <div
      class="modal-container bg-white"
      :style="showImportPage?'max-height:400px; width:100%; margin:10px' :'height: 430px; min-width: 600px'"
    >
      <div :class="showImportPage?'': 'flex w-full h-full'" >
        <div
          v-if="!showTribes"
          @click="CloseModal()"
          class="absolute z-20 flex justify-center mt-72" :class="showImportPage?'w-full text-xl':'w-3/5 '"
        >
          <button
            class="bg-tribugray-semilight mt-3 rounded-full hover:bg-tribugray-medium px-3 py-2"
          >
            {{ $t("cancel") }}
          </button>
        </div>
        <dashboard
          class="w-full"
          :uppy="uppy"
          :props="uppyProps"
          @change="UploadedImage"
          @click="FileAdded"
          @dragenter.stop.prevent="
            FileAdded();
            dropevent = true;
          "
          @paste="
            FileAdded();
            pastevent = true;
          "
          
        />
        <div :class="showImportPage?'':'border-l'" :style="showImportPage?'':'width: 300px'" v-if="showTribes && currentTribe">
          <div
           :style="showImportPage?'max-height:300px':'width: 300px'"
            class="rounded-tr-lg rounded-br-lg bg-gradient-to-t from-white to-tribugreen-light h-full pt-3  dashboardContainer overflow-auto shadow-md scrollbar"
          >
            <div class="font-bold text-base ml-6">
              {{ $t("add_to_tribes") }}
            </div>
            <div class="bg-white mt-3 rounded-t-3xl pt-5 mb-20 min-h-full">
              <div
                class="border-2 border-tribugray-light rounded-xl px-3 mx-3 my-3 py-2"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <span
                      class="mr-1 text-xl"
                      :class="
                        currentTribe.is_subscribed
                          ? 'text-tribugreen-regular'
                          : 'text-red-500'
                      "
                      >•</span
                    >
                    <p v-if="currentTribe.is_subscribed" class="text-xs">
                      {{ $t("subscribed") }}
                    </p>
                    <p v-else class="text-xs">{{ $t("not_subscribed") }}</p>
                  </div>
                  <div class="flex items-center">
                    <label
                      :for="currentTribe.id"
                      class="flex items-center cursor-pointer"
                    >
                      <div class="relative">
                        <input
                          type="checkbox"
                          :id="currentTribe.id"
                          class="sr-only"
                          @click="checkboxToggle(currentTribe.id)"
                        />

                        <div
                          class="block w-10 h-6 rounded-full"
                          :class="
                            isCheck(currentTribe.id)
                              ? 'bg-tribugreen-regular'
                              : 'bg-tribugreen-medium'
                          "
                        ></div>
                        <div
                          class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
                          :style="
                            isCheck(currentTribe.id)
                              ? 'transform: translateX(100%)'
                              : ''
                          "
                        ></div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="font-semibold mb-6">
                  {{ currentTribe.name }}
                </div>
              </div>
              <div v-for="tribe in userInfos.tribes" :key="tribe">
                <div
                  v-if="tribe.id !== currentTribe.id"
                  class="border-2 border-tribugray-light rounded-xl px-3 mx-3 my-3 py-2"
                >
                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <span
                        class="mr-1 text-xl"
                        :class="
                          tribe.is_subscribed
                            ? 'text-tribugreen-regular'
                            : 'text-red-500'
                        "
                        >•</span
                      >
                      <p v-if="tribe.is_subscribed" class="text-xs">
                        {{ $t("subscribed") }}
                      </p>
                      <p v-else class="text-xs">{{ $t("not_subscribed") }}</p>
                    </div>
                    <div class="flex items-center">
                      <label
                        :for="tribe.id"
                        class="flex items-center cursor-pointer"
                      >
                        <div class="relative">
                          <input
                            type="checkbox"
                            :id="tribe.id"
                            class="sr-only"
                            @click="checkboxToggle(tribe.id)"
                          />

                          <div
                            class="block w-10 h-6 rounded-full"
                            :class="
                              isCheck(tribe.id)
                                ? 'bg-tribugreen-regular'
                                : 'bg-tribugreen-medium'
                            "
                          ></div>
                          <div
                            class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
                            :style="
                              isCheck(tribe.id)
                                ? 'transform: translateX(100%)'
                                : ''
                            "
                          ></div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="font-semibold mb-6">
                    {{ tribe.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { Dashboard } from "@uppy/vue";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Dutch from "@uppy/locales/lib/nl_NL";
import English from "@uppy/locales/lib/en_US";
import Uppy from "@uppy/core";
import { Images } from "../../api";
import store from "../../store";
import utils from "../../utils"

export default {
  name: "App",
  beforeMount() {
    if(this.currentTribe){
      this.tribesList.push(this.currentTribe.id);
    }
    this.uppyProps.locale = this.uppyLanguage;
    store.commit("setAlreadyCall", false);
  },
  data() {
    return {
      pastevent: false,
      dropevent: false,
      imagesList: null,
      tribesList: [],
      showTribes: false,
      blob: null,
      uppyProps: {
        theme: "light",
        proudlyDisplayPoweredByUppy: false,
        note: this.$t("max_images"),
        showProgressDetails: true,
        showProgressDetails: true,
        closeModalOnClickOutside: true,
        disableInformer: true,
      },
    };
  },
  components: {
    Dashboard,
    Loading,
  },

  computed: {
    stepGuide(){
      return this.$store.state.stepGuide;
    },
    showImportPage(){
      return this.$store.state.showImportPage
    },
    loading() {
      return this.$store.state.loading;
    },
    uppyLanguage() {
      if (this.language === "FR") {
        return French;
      }
      if (this.language === "NL") {
        return Dutch;
      }
      if (this.language === "EN") {
        return English;
      }
    },
    userInfos() {
      return this.$store.state.userInfos;
    },
    currentTribe() {
      return this.$store.state.currentTribe;
    },
    language() {
      return this.$store.state.language;
    },
    tradError(){
        if(this.language === 'FR'){
          return "Il faut au moins une tribu de sélectionnée, nous gardons par défault votre tribu actuelle"
        }
        if(this.language === 'NL'){
          return "Je hebt minimaal één geselecteerde stam nodig, we behouden standaard je huidige stam"
        }
        if(this.language === 'EN'){
          return "You need at least one selected tribe, we keep your current tribe by default"
        }
        if(this.language === 'DE'){
          return "Sie benötigen mindestens einen ausgewählten Stamm, wir behalten standardmäßig Ihren aktuellen Stamm bei"   
        }
  
       },
    uppy: () =>
      new Uppy({
        debug: false,
        autoProceed: false,
        restrictions: {
          maxNumberOfFiles: 40,
          minNumberOfFiles: 1,
          allowedFileTypes: [".jpeg",".jpg",".gif",".png", ".heic"],
        },
        onBeforeUpload: (files) => {
          store.commit("setShowUploadModal", false);
        },
      }),
  },
  methods: {
    CloseModal() {
      this.$store.commit("setShowUploadModal", false);
    },
    FileAdded() {
      this.uppy.on("file-added", (file) => {
        this.showTribes = true;
      });
      const files = this.uppy.getFiles();
      if (files.length === 0) {
        this.showTribes = false;
      }
      const fakeImages = this.$store.state.fakeImages
      store.commit("setFakeImages", fakeImages + files.length);
      if (this.dropevent || this.pastevent) {
        this.UploadedImage();
        this.dropevent = false;
        this.pastevent = false;
      }
    },
    checkboxToggle(id) {
      const index = this.tribesList.indexOf(id);
      if (index > -1) {
        this.tribesList.splice(index, 1);
      } else {
        this.tribesList.push(id);
      }
    },
    isCheck(id) {
      if (this.tribesList.includes(id)) {
        return true;
      }
    },
     async UploadedImage() {
      if (!this.$store.state.alreadyCall) {
        store.commit("setAlreadyCall", true);
        this.uppy.on("complete", async (result) => {
          if (this.tribesList.length === 0) {
            this.$notify({
              title: this.tradError,
            });
          }
          this.imagesList = result.successful;
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          const tribes = this.tribesList;
          for (const item of this.imagesList) {
           await Images.uploadImages(item, options, tribes);
          }
          utils.event('image_imported', {
            image_count: this.imagesList.length
          })

        });
      }
    },
  },
};
</script>
<style>
.uppy-Dashboard-AddFiles-title {
  margin-top:-50px!important
}
.dashboardContainer::-webkit-scrollbar {
  width: 6px;

}
.dashboardContainer::-webkit-scrollbar-thumb {
  background: #cae0dd!important;
  border-radius: 10px;
  min-height:60px!important;
}
.dashboardContainer::-webkit-scrollbar-thumb {
  background: #cae0dd;
  border-radius: 10px;
}

.uppy-Dashboard-files::-webkit-scrollbar {
  width: 6px;

}
.uppy-Dashboard-files::-webkit-scrollbar-thumb {
  background: #cae0dd!important;
  border-radius: 10px;
  min-height:60px!important;
}
.uppy-Dashboard-files:-webkit-scrollbar-thumb {
  background: #cae0dd;
  border-radius: 10px;
}

</style>
