import auth from "./api/auth"
import user from "./api/user"
import images from "./api/images"
import journal from "./api/journal"
import layout from "./api/layout"
import tribes from "./api/tribes"
import blocks from "./api/blocks"

export const Auth = auth;
export const User = user;
export const Images = images;
export const Journal = journal;
export const Layout = layout;
export const Tribes = tribes;
export const Blocks = blocks;
