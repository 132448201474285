import moment from "moment";
import { createStore } from "vuex";

const store = createStore({
  state: {
    language: "FR",
    paramsTribe: null,
    hasSignup: false,
    loginMethod: null,
    userInfos: {},
    members: [],
    showFilters: false,
    filter: "all",
    filterMember: null,
    noImageView: true,
    pageWidth: 318,
    pageHeight: 450,
    scaledPageWidth: 318,
    scaledPageHeight: 450,
    showUploadModal: false,
    currentTribe: null,
    selectedPage: "frontcover",
    journalType: null,
    showPagination: true,
    uploadedImages: [],
    fakeImages: 0,
    imagesPerMonth: [],
    screenSmallSize: false,
    currentJournal: null,
    displayedPage: {
      order: 1,
      blocks: {
        images: [
          {
            block_id: null,
            legend: null,
            media_id: null,
            metadata: {},
          },
        ],
      },
    },
    pages: null,
    layouts: null,
    layoutBlock: null,
    showDeleteModal: false,
    imageToDelete: null,
    selectedBlock: { id: null },
    selectedBlockOrder: null,
    selectedImage: null,
    pageToDelete: null,
    blockToDelete: null,
    showModalLegend: false,
    imageBlock: null,
    contentLegend: null,
    updateAtJournal: null,
    isTinyBlock: false,
    dragImage: null,
    lastUpdateAt: null,
    remountCropper: false,
    outsideUploadModal: true,
    outsideDeleteModal: true,
    outsideLayoutModal: true,
    outsideTextErrorModal: true,
    outsideAutofillModal:true,
    outsideChangeOrderErrorModal: true,
    outsideModalZoom:true,
    showLayoutModal: false,
    blockId: null,
    blockUpdatedAt: null,
    top: null,
    left: null,
    scale: null,
    hasBlockImage: false,
    errorMessage: null,
    authorisationToChangeLayout: false,
    alreadyCall: false,
    journalSize: null,
    modalErrorText: false,
    remountBlockText: false,
    modalErrorChangeOrder: true,
    cancelChangeLayout: false,
    reloadHeader: false,
    showModalInfosSelectLayout: false,
    showModalErrorPage: false,
    numberOfImages: 0,
    callisDone: true,
    paginationIsReduce: false,
    textToDelete:null,
    removeText:false,
    deleteModalTextArea:false,
    preload:false,
    reloadJournal:false,
    modalAutofill:false,
    loader:false,
    reloadSidebar:false,
    blockTextIsSelected:false,
    modalZoom:false,
    allImagesList:[],
    imageZoom:null,
    pageSize:'medium',
    mouseUp:false,
    changePage:false,
    showChangeType:false,
    type:'standard',
    chosenType:null,
    paramsJournal:null,
    showImportPage:false,
    blockOrder:null,
    switchBlockMode:false,
    dragImageToSwitch:null,
    legendIsAdded:false,
    remountPage:false,
    loginError:false,
    modaleInvitation:false,
    showWelcomeMessage:false,
    signupError:false,
    showModalEndMonth:true,
    stepGuide: 1,
    showGuide:false,

  },
  mutations: {
    setShowGuide(state,newState){
      state.showGuide = newState
    },
    setGuide(state,newState){
      state.stepGuide = newState
    },
    setShowModalEndMonth(state,newState){
      state.showModalEndMonth = newState
    },
    setSignupError(state,newState){
      state.signupError = newState
    },
    setShowWelcomeMessage(state,newState){
      state.showWelcomeMessage = newState
    },
    setModaleInvitation(state,newState){
      state.modaleInvitation = newState
    },
    setLoginError(state,newState){
      state.loginError = newState
    },
    setRemountPage(state,newState){
      state.remountPage = newState
    },
    setLegendIsAdded(state,newState){
      state.legendIsAdded = newState
    },
    setDragImageToSwitch(state,newState){
      state.dragImageToSwitch = newState
    },
    setSwitchBlockMode(state, newState){
      state.switchBlockMode = newState
      state.selectedBlock = { id: null }
    },
    setShowImportPage(state, newState){
      state.showImportPage = newState
    },
    setParamsJournal(state, newState){
      state.paramsJournal = newState
    },
    setChosenType(state, newState){
      state.chosenType = newState
    },
    setType(state, newState){
      state.type = newState
    },
    setShowChangeType(state, newState){
      state.showChangeType = newState
    },
    setChangePage(state, newState){
      state.changePage = newState
    },
    setMouseUp(state, newState){
      state.mouseUp = newState
    },
    setPageSize(state, newState){
      state.pageSize = newState
    },
    setImageZoom(state, newState){
      state.imageZoom = newState
    },
    setAllImagesList(state,newItem){
      state.allImagesList.push(newItem)
    },
    setOutsideModalZoom(state,newState){
      state.outsideModalZoom = newState
    },
    setModalZoom(state,newState){
      state.modalZoom = newState
    },
    setBlockTextIsSelected(state, newState){
        state.blockTextIsSelected = newState
    },
    setReloadSidebar(state, newState){
      state.reloadSidebar = newState
    },
    setLoader(state, newState){
      state.loader = newState
    },
    setOutsideAutofillModal(state, newState){
      state.outsideAutofillModal = newState
    },
    setModalAutofill(state, newState){
      state.modalAutofill = newState
    },
    setReloadJournal(state, newState){
      state.reloadJournal = newState
    },
    setPreload(state, newState){
      state.preload = newState
    },
    setRemoveText(state, newState){
      state.removeText = newState
    },
    setDeleteModalTextArea(state, newState){
      state.deleteModalTextArea = newState
    },
    setTextToDelete(state, newState){
      state.textToDelete = newState
    },
    setParamsTribe(state, newState){
      state.paramsTribe = newState
    },
    setPaginationIsReduce(state, newState) {
      state.paginationIsReduce = newState;
    },
    setCallisDone(state, newState) {
      state.callisDone = newState;
    },
    setNumberOfImages(state, newState) {
      state.numberOfImages = newState;
    },
    setModalErrorPage(state, newState) {
      state.showModalErrorPage = newState;
    },
    setShowModalInfosSelectLayout(state, newState) {
      state.showModalInfosSelectLayout = newState;
    },
    setReloadHeader(state, newState) {
      state.reloadHeader = newState;
    },
    setCancelChangeLayout(state, newState) {
      state.cancelChangeLayout = newState;
    },
    setRemountBlockText(state, newState) {
      state.remountBlockText = newState;
    },
    setModalErrorChangeOrder(state, newState) {
      state.modalErrorText = newState;
    },
    setModalErrorText(state, newState) {
      state.modalErrorText = newState;
    },
    setJournalSize(state, newState) {
      state.journalSize = newState;
    },
    setAlreadyCall(state, newState) {
      state.alreadyCall = newState;
    },
    setAuthorisationToChangeLayout(state, newState) {
      state.authorisationToChangeLayout = newState;
    },
    setScaledPageHeight(state, newState) {
      state.scaledPageHeight = newState;
    },
    setScaledPageWidth(state, newState) {
      state.scaledPageWidth = newState;
    },
    setErrorMessage(state, newState) {
      state.errorMessage = newState;
    },
    setHasBlockImage(state, newState) {
      state.hasBlockImage = newState;
    },
    setMetaData(state, newState) {
      if (newState) {
        state.top = newState.changetop;
        state.left = newState.changeleft;
        state.scale = newState.changescale;
      }
    },
    setBlockUpdatedAt(state, newState) {
      state.blockUpdatedAt = newState;
    },
    setBlockId(state, newState) {
      state.blockId = newState;
    },
    setOutsideChangeOrderErrorModal(state, newState) {
      state.outsideChangeOrderErrorModal = newState;
    },
    setOutsideTextErrorModal(state, newState) {
      state.outsideTextErrorModal = newState;
    },
    setOutsideLayoutModal(state, newState) {
      state.outsideLayoutModal = newState;
    },
    setOutsideDeleteModal(state, newState) {
      state.outsideDeleteModal = newState;
    },
    setOutsideUploadModal(state, newState) {
      state.outsideUploadModal = newState;
    },
    setRemountCropper(state, newState) {
      state.remountCropper = newState;
    },
    setLastUpdateAt(state, newState) {
      state.dragImage = newState;
    },
    setDragImage(state, newState) {
      state.dragImage = newState;
    },
    setIsTinyBlock(state, newState) {
      state.isTinyBlock = newState;
    },
    setUpdateAtJournal(state, newState) {
      state.updateAtJournal = newState;
    },
    setContentLegend(state, newState) {
      state.contentLegend = newState;
    },
    setLanguage(state, newState) {
      state.language = newState;
      localStorage.language = newState;
    },
    setUserinfos(state, newState) {
      state.userInfos = newState;
    },
    setCurrentJournal(state, newState) {
      state.currentJournal = newState;
    },
    setLayoutBlock(state, newState) {
      state.layoutBlock = newState;
    },
    setLayouts(state, newState) {
      state.layouts = newState;
    },
    setMembers(state, newState) {
      state.members = newState;
    },
    setShowLayoutModal(state, newState) {
      state.showLayoutModal = newState;
    },
    setShowFilters(state, newState) {
      state.showFilters = newState;
    },
    setFilter(state, newState) {
      state.filter = newState;
    },
    setFilterByMember(state, newState) {
      state.filterMember = newState;
    },
    setNoImageView(state, newState) {
      state.noImageView = newState;
    },
    setShowUploadModal(state, newState) {
      state.showUploadModal = newState;
    },
    setCurrentTribe(state, newState) {
      state.currentTribe = newState;
    },
    setSelectedPage(state, newState) {
      state.selectedPage = newState;
    },
    setDisplayedPage(state, newState) {
      state.displayedPage = newState;
    },
    setJournalType(state, newState) {
      state.journalType = newState;
    },
    setPages(state, newState) {
      state.pages = newState;
    },
    setShowPagination(state, newState) {
      state.showPagination = newState;
    
    },
    setArrayImage(state, newItem) {
      state.uploadedImages.push(newItem);
    },
    AddImage(state, newItem) {
      if (state.imagesPerMonth.length !== 0) {
        state.imagesPerMonth[0].images.unshift(newItem);
      } else {
        let obj = {};
        obj["date"] = [
          { month: moment().format("MMMM"), year: moment().format("YYYY") },
        ];
        obj["images"] = [newItem];
        state.imagesPerMonth.push(obj);
      }
      let newArray = state.uploadedImages
      newArray[0].data.unshift(newItem)
      state.uploadedImages = newArray
      state.numberOfImages = state.numberOfImages + 1;
    },
    RemoveImage(state, id) {
      state.imagesPerMonth.forEach((element) => {
        for (let i = 0; i < element.images.length; i++) {
          if (element.images[i].id === id) {
            element.images.splice(i, 1);
          }
        }
      });
      for(let i =0; i < state.uploadedImages.length; i++){
        for(let j =0; j < state.uploadedImages[i].data.length; j++){
          if(state.uploadedImages[i].data[j].id === id){
           state.uploadedImages[i].data.splice(j,1)
          }
        }
      }
    },
    setImageToDelete(state, newState) {
      state.imageToDelete = newState;
    },
    cleanArrayImage(state) {
      state.uploadedImages = [];
    },
    setFakeImages(state, newState) {
      state.fakeImages = newState;
    },
    setImagesPerMonth(state, newState) {
      state.imagesPerMonth = newState;
    },
    setDeleteModal(state, newState) {
      state.showDeleteModal = newState;
    },
    setPageToDelete(state, newState) {
      state.pageToDelete = newState;
    },
    setSelectedBlock(state, newState) {
      state.selectedBlock = newState;
    },
    setSelectedBlockOrder(state, newState) {
      state.selectedBlockOrder = newState;
    },
    setSelectedImage(state, newState) {
      state.selectedImage = newState;
    },
    setBlockToDelete(state, newState) {
      state.blockToDelete = newState;
    },
    setShowModalLegend(state, newState) {
      state.showModalLegend = newState;
    },
    setImageBlock(state, newState) {
      state.imageBlock = newState;
    },
    setBlockOrder(state, newState) {
      state.blockOrder = newState;
    },
  },
  actions: {},
});

export default store;
