<template>
  <modale-invitation v-if="modaleInvitation && !token" :isMobile="isMobile" ></modale-invitation>
  <mobile-screen v-if="isMobile && !isAnAuthentificationPage && !showImportPage" ></mobile-screen>
  <choose-album v-if="showChangeType && !isMobile"></choose-album>
  <router-view></router-view>

  <notifications
    v-if="!isMobile"
    position="bottom center"
    width="60%"
    classes="my-notification font-inter"
  />
    <notifications
    v-if="isMobile"
    position="bottom center"
    width="100%"
    classes="my-notification font-inter"
  />

</template>

<script>
import MobileScreen from "./components/MobileScreen.vue";
import { Layout, User } from "./api";
import utils from './utils';
import ChooseAlbum from "./components/modalChooseAlbum.vue";
import ModalDashboard from "./components/ModalDashboard.vue"
import ModaleInvitation from "./components/ModaleInvitation.vue"
//import { launch,registerDevice } from 'notificare-web/core';

export default {
  beforeMount() {
    if(localStorage.showImportPage && this.isMobile){
      this.$store.commit('setShowImportPage',true)
    }
     const currentUrl = window.location.href;
    if(currentUrl.includes("/logout")){
      utils.event('logout')
      localStorage.removeItem("token");
      setTimeout(this.$router.push({name:"Login"}),1000)
    }
   
    const signup = "signup";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramLang = urlParams.get("lang");
    if (paramLang) {
      localStorage.language = paramLang.toUpperCase();
    }
    if (!localStorage.language) {
      localStorage.language = "FR";
    }
    if (localStorage.language !== "FR") {
      this.$store.commit("setLanguage", localStorage.language);
      this.$i18n.locale = localStorage.language;
    }
    if(localStorage.token){
      this.token = localStorage.token
    }

  },
async mounted(){
  const user = await User.getUserInformations();
  const name = user.firstname + ' ' + user.lastname
  console.log("c'est deploy!")
  /*await launch();
  await registerDevice({
  userId: user.id,
  userName: name
  });*/
    if(localStorage.pageSize){
      this.$store.commit('setPageSize',localStorage.pageSize)
    }
    if(!localStorage.pageSize){
      if(this.window.height -100 <= 555){
        this.$store.commit('setPageSize','small')
      }
      if(this.window.height -100 <= 685 && this.window.height -100 > 555){
        this.$store.commit('setPageSize','medium')
      }
      if(this.window.height -100  <= 836 && this.window.height -100  > 685){
        this.$store.commit('setPageSize','big')
      }
      if(this.window.height -100 > 836){
        this.$store.commit('setPageSize','huge')
      }
    }
},
  watch: {
    async preload() {
      if (this.preload) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramLang = urlParams.get("lang");
        let paramPage = parseInt(urlParams.get("page"));
      
        await Layout.getAllLayoutsBlocks();

        let displayedPage = null;
        if (paramPage) {
         
          if(paramPage < 0 || paramPage > this.$store.state.currentJournal.pages.length){
            paramPage = 1
          }
          this.$store.commit("setSelectedPage", paramPage);
          displayedPage = this.$store.state.currentJournal.pages.find(
            (item) => item.order === paramPage
          );
        } else {
          if (this.$store.state.currentJournal.pages) {
            this.$store.commit("setSelectedPage", 'frontcover');
            displayedPage = this.$store.state.currentJournal.pages.find(
              (item) => item.order === 1
            );
          }
        }
        this.$store.commit("setDisplayedPage", displayedPage)
        await utils.journalEvent('newspaper_page_viewed')
        this.$store.commit(
          "setUpdateAtJournal",
          this.$store.state.currentJournal.updated_at
        );
      }
    },
    url() {
      const signup = "signup";
      if (this.url.includes(signup)) {
        this.signupPage = true;
      }
    },
    errorMessage() {
      if (this.errorMessage) {
        this.$notify({
          title: this.$t(this.errorMessage),
        });
        this.$store.commit("setErrorMessage", null);
      }
    },
  },
  data() {
    return {
      token:null,
      signupPage: false,
      forgotPage : false,
      resetPage:false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    showExpiredLink(){
      return this.$store.state.showExpiredLink
    },
    currentRouteName() {
        return this.$route.name;
    },
    modaleInvitation(){
      return this.$store.state.modaleInvitation
    },
    showImportPage(){
      return this.$store.state.showImportPage
    },

    showChangeType(){
      return this.$store.state.showChangeType
    },
    currentRouteName() {
        return this.$route.name;
    },
    isAnAuthentificationPage(){
      const routeName = ["Login","SignUp","ForgetPassword","ChangePassword"]
      return routeName.includes(this.currentRouteName)?true:false
    },
    preload() {
      return this.$store.state.preload;
    },
    errorMessage() {
      return this.$store.state.errorMessage;
    },
    url() {
      return window.location.href;
    },
    language() {
      return this.$store.state.language;
    },
    isMobile() {
      return this.window.width < 915 ? true : false;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  components: {
    MobileScreen,ChooseAlbum,ModalDashboard,ModaleInvitation
  },
  created() {

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
