<template>

  <div
    v-if="currentJournal.pages"
    :key="componentKey"
    class="cp-layout-block"
    :class="selectedBlock === blockKey ? 'selected-block' : ''"
    :style="computedStyle"
  >
    <block-image
      v-click-away="onClickAwayImage"
      :id="blockKey"
      v-if="journalSize && block.type === 'image'"
      :block="block"
      :isMiniature="isMiniature"
      :page="page"
      :outside="outsideImage"
      @click="setSelectedBlockImage(blockKey, block)"
      :class="
        pageObject.layout_id === 54 && pageObject.blocks.images.length === 0
          ? 'bg-white'
          : ''
      "
    ></block-image>
    <block-text
      v-click-away="onClickAwayBlockText"
      v-if="journalSize && block.type === 'text'"
      :block="block"
      :isMiniature="isMiniature"
      :page="page"
      @click="outsideBlockText = false; setSelectedBlockText()"
      :key="componentKeyText"
    ></block-text>
  </div>
</template>
<script>
import BlockImage from "./BlockImage.vue";
import BlockText from "./BlockText.vue";
import { directive } from "vue3-click-away";
export default {
  directives: {
    ClickAway: directive,
  },
  mounted() {

  },
  props: {
    block: Object,
    isMiniature: Boolean,
    page: Number,
  },
  data() {
    return {
      outsideImage: true,
      outsideBlockText: true,
      localStorageText: false,
      componentKey: 0,
      componentKeyText:0
    };
  },
  watch: {
    page(value,oldValue){
      if(value !== oldValue){
        console.log('hey')
      }
    },
    remountBlockText(value, oldValue){
      if(value !== oldValue && value === true && !this.isMiniature){
        this.componentKeyText = !this.componentKeyText;
         this.$store.commit("setRemountBlockText", false);
      }
    },
    outsideBlockText() {
      if (!this.isMiniature) {
        if (this.outsideBlockText && this.selectedBlock === this.blockKey && !this.showModalLegend) {
          this.$store.commit("setSelectedBlock", { id: null });
        }
      }
      else{
         if(!this.isMiniature){

        this.$store.commit("setSelectedBlock", this.blockKey);
         }
      }
    },
  },
  components: {
    BlockImage,
    BlockText,
  },

  computed: {
    remountBlockText() {
      return this.$store.state.remountBlockText;
    },
    showModalLegend() {
      return this.$store.state.showModalLegend;
    },
    journalSize() {
      return this.$store.state.journalSize;
    },
    remountCropper() {
      return this.$store.state.remountCropper;
    },
    textBlock() {
      if (this.pageObject.blocks.texts) {
        if (this.pageObject.blocks.texts.length > 0) {
          const textBlock = this.pageObject.blocks.texts.find(
              (item) => item.order === this.block.order
            );
          if (textBlock) {
            return textBlock;
          }
        }
      }
    },
    blockKey() {
      const pageId = this.pageObject.id;
      const blockId = this.block.id;
      const order = this.block.order;
      return `${pageId}-${blockId}-${order}`;
    },
    pageObject() {
      return this.currentJournal.pages.find((item) => item.order === this.page);
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    selectedBlock() {
      return this.$store.state.selectedBlock;
    },

    computedStyle() {
      if (this.isMiniature) {
        const { position, size } = this.block;

       const [maxHeight, maxWidth] = this.currentJournal.type === 'kids'? [51, 51]:[70, 51];


        const styles = {
          height: `${Math.round(this.calculateSize(size.height, maxHeight))}px`,
          left: `${Math.round(this.calculateSize(position.x, maxWidth))}px`,
          top: `${Math.round(this.calculateSize(position.y, maxHeight))}px`,
          width: `${Math.round(this.calculateSize(size.width, maxWidth))}px`,
        };
        return styles;
      } else {
        const { position, size } = this.block;
        const [maxHeight, maxWidth] = [
          this.journalSize.height,
          this.journalSize.width,
        ];
        const styles = {
          height: `${Math.round(this.calculateSize(size.height, maxHeight))}px`,
          left: `${Math.round(this.calculateSize(position.x, maxWidth))}px`,
          top: `${Math.round(this.calculateSize(position.y, maxHeight))}px`,
          width: `${Math.round(this.calculateSize(size.width, maxWidth))}px`,
        };
        return styles;
      }
    },
  },

  methods: {

    onClickAwayBlockText() { 
      this.outsideBlockText = true;
    },
    calculateSize(value, max) {
      return (max * (value * 100)) / 100;
    },
    onClickAwayImage() {
      this.outsideImage = true;
    },
    setSelectedBlockImage() {
      this.outsideImage = false;
      if (!this.isMiniature) {
        this.$store.commit("setSelectedBlock", this.blockKey);
         
        this.$store.commit("setSelectedBlockOrder", this.block.order);
      }
    },
    setSelectedBlockText() {
      this.outsideBlock = false;
      if (!this.isMiniature) {
        this.$store.commit("setSelectedBlock", this.blockKey);
        this.$store.commit("setSelectedBlockOrder", this.block.order);
      }
    },
  },
};
</script>
