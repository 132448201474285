<template>
  <div class="w-full flex  h-screen font-inter" :class="isMobile?'bg-tribugreen-light':'background-auth'">
    <div v-if="!isMobile" class="w-1/2"></div>
    <div :class="isMobile?'w-full mx-3':'w-1/2 mx-10'" class="bg-tribugreen-light rounded-xl my-10 h-full" style="max-width:500px; max-height:500px;">
      <div class="flex flex-col w-full items-center">
        <div class="w-full flex justify-center border-b border-tribugreen-regular">
          <img src="../assets/logo-black.svg" class="w-12 my-5"/>
        </div>
        <img style="width:100px" class="mt-3 mb-5" src="../assets/icons/congratulation.svg"/>
        <p>{{$t("invitation_text1")}}</p>
        <p><b>{{tribe}}</b></p> 
        <p class="mt-5 text-center text-sm">{{$t("invitation_text2")}}</p>
        <div @click="goToSignup" style="width:150px" class="hover:bg-tribugreen-medium cursor-pointer text-center rounded-full bg-tribugreen-regular text-xs text-white py-2 mt-10">{{$t('signup')}}</div>
        <div @click="goToSignIn" style="width:150px" class="hover:bg-tribugreen-medium cursor-pointer text-center rounded-full bg-tribugreen-regular text-xs text-white py-2 mt-2">{{$t('login')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tribes } from "../api"

export default {
  props:{
    isMobile:Boolean
  },
data(){
    return {
        tribe:'-'
    }
},
async mounted(){
    const code = localStorage.code
    const call = await Tribes.getTribeWithCode(code)
    this.tribe = call.tribe
},
  methods: {
    goToSignup(){
        this.$store.commit("setModaleInvitation",false)
        this.$router.push('/signup')
    },
    goToSignIn(){
       this.$store.commit("setModaleInvitation",false)
        this.$router.push('/login')
        
    }


  },
};
</script>
