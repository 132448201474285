<template>
  <div
    class="flex flex-col mt-4 w-full"
    ref="infoBox"
    v-if="pages"
    :style="showPagination ? 'height:70%' : 'height:82%'"
    :key="componentKey"
  >
    <div class="flex">
      <div class="flex items-center" :style="isSafari ? 'height:70vh' : ''">
        <div
          @click="goToPrevPage"
          class="absolute"
          :class="showUploadModal ? 'invisible' : 'journal-arrow-button ml-2'"
          :style="[isFirstPage ? 'visibility:hidden' : '', isSafari ? '' : '']"
        >
          <i class="fas fa-arrow-left"></i>
        </div>
      </div>
      <div class="w-full flex justify-center ml-20">
        <div
          v-click-away="onClickAwayInfosSideBar"
          v-if="showModalInfosSelectLayout"
          class="fixed z-50 text-tenpix bg-tribugreen-regular text-white w-72 shadow rounded-xs px-4 py-4 cursor-default flex items-center mt-16"
          style="width: 170px; margin-left: -35%"
        >
          <p>{{ $t("impossible_change_layout") }}</p>
          <div
            class="ml-2 cursor-pointer"
            @click="setShowModalInfosSelectLayout()"
          >
            <img
              class="w-7 h-7 opacity-60"
              src="../../assets/icons/cross.svg"
            />
          </div>
        </div>
        <div
          class="journal-scrollbar h-full"
          :class="pageSize === 'small' || pageSize === 'medium'?'overflow-y-auto':'overflow-y-auto'"
          :style="heightContainer"
        >
          <div class="bg-white" :style="journalSize">
            <page
              :isMiniature="false"
              :page="selectedPage"
              class="bg-white shadow-sm"
              :style="journalSize"
              :key="componentPageKey"
            ></page>
          </div>
        </div>
        <div class="pl-3">
           <!--BUTTON OPTIONS -->
          <div
            @click="
              showSubmenu = !showSubmenu;
              outside = true;
            "
            class="bg-white cursor-pointer active:bg-tribugray-light shadow h-6 w-6 flex justify-center items-center rounded-full hover:text-white hover:bg-tribugreen-medium"
          >
            <img class="h-4 w-4" src="../../assets/icons/dots.svg" />
          </div>

          <!--BUTTON SWITCH BLOCK OFF-->
          <div
            v-if="!switchBlockOn"
            @click="switchBlockOn=true"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            class="bg-white cursor-pointer mt-2 active:bg-tribugray-light shadow h-6 w-6 flex justify-center items-center rounded-full hover:text-white hover:bg-tribugreen-medium"
          >
            <img class="h-4 w-4" src="../../assets/icons/blocks-arrows-green.svg" />
          </div>
            <!--BUTTON SWITCH BLOCK ON-->
          <div
           v-if="switchBlockOn"
           @click="switchBlockOn=false"
            @mouseover="hover2 = true"
            @mouseleave="hover2 = false"
            class="cursor-pointer mt-2 bg-tribugreen-regular shadow h-6 w-6 flex justify-center items-center rounded-full hover:text-white hover:bg-tribugreen-medium"
          >
            <img class="h-4 w-4" src="../../assets/icons/blocks-arrows.svg" />
          </div>
          <!-- BULLE INFOS -->
          <div v-if="hover && !switchBlockOn" class="absolute mt-1 p-5 w-52 bg-white cursor-pointer shadow  flex justify-center items-center " style="z-index:100">
            <p class="text-xs">{{$t("switch_block_info")}}</p>
          </div>
          <div v-if="hover2 && switchBlockOn" class="absolute mt-1 p-5 w-52 bg-white cursor-pointer shadow  flex justify-center items-center" style="z-index:100">
            <p class="text-xs">{{$t("switch_block_mode")}}</p>
          </div>
          <div :class="showCopiedMessage?'opacity-100 transition-opacity duration-500':'opacity-0 transition-opacity duration-500'"  class=" absolute bg-tribugreen-regular mt-3 text-white rounded text-xs px-3 py-1">{{$t('copied_page')}}</div>


          <div
            v-click-away="onClickAwayShowSubMenu"
            v-if="showSubmenu && outside"
            class="bg-white shadow py-2 mt-1 rounded absolute z-40"
            style="width: 260px"
          >
            <div class="flex justify-between text-xs cursor-pointer px-3 pt-2">
              <p>{{ $t("change_size_page") }}</p>
            </div>
            <div class="flex text-xs  px-3 border-b border-gray-100 pb-3" :class="pageCantBeCopied?'mt-2':'my-2'">
              <div
                @click="changePageSize('small')"
                class="cursor-pointer"
                :class="
                  pageSize === 'small'
                    ? 'bg-tribugreen-regular text-white px-2 py-1 rounded'
                    : 'bg-gray-100 text-gray-500 px-2 py-1 rounded hover:bg-gray-200'
                "
              >
                {{ $t("small_page") }}
              </div>
              <div
                @click="changePageSize('medium')"
                class="cursor-pointer ml-1"
                :class="
                  pageSize === 'medium'
                    ? 'bg-tribugreen-regular text-white px-2 py-1 rounded'
                    : 'bg-gray-100 text-gray-500 px-2 py-1 rounded hover:bg-gray-200'
                "
              >
                {{ $t("medium_page") }}
              </div>
              <div
                @click="changePageSize('big')"
                class="cursor-pointer mx-1"
                :class="
                  pageSize === 'big'
                    ? 'bg-tribugreen-regular text-white px-2 py-1 rounded'
                    : 'bg-gray-100 text-gray-500 px-2 py-1 rounded hover:bg-gray-200'
                "
              >
                {{ $t("big_page") }}
              </div>
              <div
               v-if="currentJournal.type !== 'kids'"
                @click="changePageSize('huge')"
                class="cursor-pointer"
                :class="
                  pageSize === 'huge'
                    ? 'bg-tribugreen-regular text-white px-2 py-1 rounded'
                    : 'bg-gray-100 text-gray-500 px-2 py-1 rounded hover:bg-gray-200'
                "
              >
                {{$t("bigger_page")}}
              </div>
            </div>
            <div v-if="pageCantBeCopied" class="flex bg-gray-100 text-gray-300 justify-between text-xs  cursor-pointer pt-2 pb-2 px-3 border-b border-gray-100">
              <p>{{$t('copy_page')}} </p>
              <img style="width:15px; margin-right:1px" class="opacity-20" src="../../assets/icons/copy.svg"/>

            </div>
            <div v-else @click="copyPageId()" class="flex justify-between text-xs mt-2 cursor-pointer pt-2 pb-2 px-3 border-b border-gray-100">
              <p>{{$t('copy_page')}} </p>
              <img style="width:15px; margin-right:1px" src="../../assets/icons/copy.svg"/>

            </div>
            <div v-if="copiedPage && !pageCantBeCopied" @click="pastePage()" class="flex justify-between text-xs mt-2 cursor-pointer pt-2 pb-2 px-3 border-b border-gray-100">
              <p>{{$t('paste_page')}}</p>
               <img style="width:15px; margin-right:1px" src="../../assets/icons/clipboard.svg"/>
            </div>
             <div v-else class="flex bg-gray-100 text-gray-300 justify-between text-xs  cursor-pointer pt-2 pb-2 px-3 border-b border-gray-100">
              <p>{{$t('paste_page')}}</p>
               <img style="width:15px; margin-right:1px" class="opacity-20" src="../../assets/icons/clipboard.svg"/>
            </div>
            <div
              v-if="showDeleteButton"
              @click="openDeleteModal()"
              class="flex justify-between text-xs mt-2 cursor-pointer pt-2 pb-2 px-3"
            >
              <p>{{ $t("empty_page") }}</p>
              <i
                class="far fa-trash-alt text-tribugray-semidark"
                style="font-size: 13px; padding-right: 3px"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div
        class="right-0 flex items-center h-full"
        style='margin-right:40px'
        :style="isSafari ? 'height:70vh' : ''"
      >
        <div
          @click="goToNextPage"
          class="absolute"
          :class="showUploadModal ? 'invisible' : 'journal-arrow-button mr-2'"
          :style="isLastPage ? 'visibility:hidden' : ''"
        >
          <i class="fas fa-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Page from "./Page.vue";
import { Blocks, Journal } from "../../api";
import { directive } from "vue3-click-away";
export default {
  components: { Page },
  name: "Journal",
  directives: {
    ClickAway: directive,
  },
  mounted() {
     let defaultPageSizeBackend = null
    if(this.currentJournal.type !== "digital"){
      defaultPageSizeBackend = this.allLayout.find(item => item.type === this.currentJournal.type);
    }
    if(this.currentJournal.type === "digital"){
      defaultPageSizeBackend = this.allLayout.find(item => item.type === "standard");
    }
    let widthPage = null;
    let heightPage = null;
     if (this.pageSize === "small" && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 4.5;
      heightPage = defaultPageSizeBackend.sizes.height / 4.5;
     }
     if (this.pageSize === "medium"  && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 3;
      heightPage = defaultPageSizeBackend.sizes.height / 3;
     }
     if (this.pageSize === "big"  && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 2.4;
      heightPage = defaultPageSizeBackend.sizes.height / 2.4;
     }
     if (this.pageSize === "huge"  && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 1.97;
      heightPage = defaultPageSizeBackend.sizes.height / 1.97;
     }
    if (this.pageSize === "small" && this.currentJournal.type ==="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 3;
      heightPage = defaultPageSizeBackend.sizes.height / 3;
     }
     if (this.pageSize === "medium"  && this.currentJournal.type ==="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 2;
      heightPage = defaultPageSizeBackend.sizes.height / 2;
     }
     if (this.pageSize === "big"  && this.currentJournal.type ==="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 1.3;
      heightPage = defaultPageSizeBackend.sizes.height / 1.3;
     }
    this.clientHeight = this.$refs.infoBox.clientHeight;
    let journalSizeProps = null;
    let journalSize = null;
   
   journalSizeProps = {
        width: widthPage,
        height: heightPage,
      };
     journalSize = {
        width: `${widthPage}px`,
        height: `${heightPage}px`,
      };
   
    this.journalSize = journalSize;
    this.$store.commit("setJournalSize", journalSizeProps);
    this.copiedPage = localStorage.pageId?true:false
  },
  data() {
    return {
      hover:false,
      hover2:false,
      journalSize: null,
      showSubmenu: false,
      displayedPage: 1,
      clientHeight: 300,
      outside: true,
      window: {
        width: 0,
        height: 0,
      },
      switchBlockOn:false,
      componentKey: 0,
      componentPageKey:0,
      copiedPage:false,
      showCopiedMessage:false
    };
  },
  watch: {
    showCopiedMessage(value, oldValue){
      if(value !== oldValue && value == true){
        setTimeout(() => this.showCopiedMessage = false, 2000)
      }
    },
    remountPage(value, oldValue){
      if(value === true){
        this.$store.commit('setSelectedBlock',{ id : null})
        this.componentPageKey = !this.componentPageKey
        this.$store.commit('setRemountPage',false)
      }
    },
    switchBlockMode(value, oldValue) {
      if(value !== oldValue){
        this.switchBlockOn = value
        this.hover = false
        this.hover2 = false
      }
    },
      switchBlockOn(value, oldValue) {
      if(value !== oldValue){
        this.$store.commit('setSwitchBlockMode',value)
      }
    },
    height(value, oldValue) {
      if (value !== oldValue && oldValue !== 0) {
        this.clientHeight = this.$refs.infoBox.clientHeight;
      }
    },

    pageSize(value, oldValue) {
      if (value !== oldValue) {
        localStorage.pageSize = value;
     let defaultPageSizeBackend = null
    if(this.currentJournal.type !== "digital"){
      defaultPageSizeBackend = this.allLayout.find(item => item.type === this.currentJournal.type);
    }
    if(this.currentJournal.type === "digital"){
      defaultPageSizeBackend = this.allLayout.find(item => item.type === "standard");
    }    let widthPage = null;
    let heightPage = null;
   if (this.pageSize === "small" && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 4.5;
      heightPage = defaultPageSizeBackend.sizes.height / 4.5;
     }
     if (this.pageSize === "medium"  && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 3;
      heightPage = defaultPageSizeBackend.sizes.height / 3;
     }
     if (this.pageSize === "big"  && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 2.4;
      heightPage = defaultPageSizeBackend.sizes.height / 2.4;
     }
     if (this.pageSize === "huge"  && this.currentJournal.type !=="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 1.97;
      heightPage = defaultPageSizeBackend.sizes.height / 1.97;
     }
    if (this.pageSize === "small" && this.currentJournal.type ==="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 3;
      heightPage = defaultPageSizeBackend.sizes.height / 3;
     }
     if (this.pageSize === "medium"  && this.currentJournal.type ==="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 2;
      heightPage = defaultPageSizeBackend.sizes.height / 2;
     }
     if (this.pageSize === "big"  && this.currentJournal.type ==="kids") {
      widthPage = defaultPageSizeBackend.sizes.width / 1.3;
      heightPage = defaultPageSizeBackend.sizes.height / 1.3;
     }
    this.clientHeight = this.$refs.infoBox.clientHeight;
    let journalSizeProps = null;
    let journalSize = null;
   
   journalSizeProps = {
        width: widthPage,
        height: heightPage,
      };
     journalSize = {
        width: `${widthPage}px`,
        height: `${heightPage}px`,
      };
   
    this.journalSize = journalSize;
    this.$store.commit("setJournalSize", journalSizeProps);

      }
      this.componentKey = !this.componentKey
    },
  },
  computed: {
    remountPage(){
      return this.$store.state.remountPage
    },
    switchBlockMode(){
      return this.$store.state.switchBlockMode
    },
    allLayout(){
      return this.$store.state.layouts;
    },
    heightContainer() {
      if(this.showPagination){
      const height = this.window.height - 230
      const style ={
        height:`${height}px`,
        
      }
      return style;
      }
      else{
      const height = this.window.height - 150
      const style ={
        height:`${height}px`,
        
      }
      return style;
      }
    },
    hideTrash() {
      const premiumPages = [
        2,
        3,
        this.currentJournal.pages.length - 1,
        this.currentJournal.pages.length,
      ];
      const standardPages = [this.currentJournal.pages.length];

      if (this.currentJournal.type === "premium") {
        return premiumPages.includes(this.selectedPage);
      } else {
        return standardPages.includes(this.selectedPage);
      }
    },
  pageCantBeCopied() {
    const isFirstPage = this.selectedPage === 1;
    const isLastPage = this.selectedPage === this.pages.length;
    
    if (this.currentJournal.type === "premium") {
      return isFirstPage || this.isSpecialPremiumPage() || isLastPage;
    } else if (this.currentJournal.type === "standard") {
      return isFirstPage || isLastPage;
    } else if (this.currentJournal.type === "kids") {
      return isFirstPage || this.isSpecialKidsPage() || isLastPage;
    }

    return false;
  },
    showModalInfosSelectLayout() {
      return this.$store.state.showModalInfosSelectLayout;
    },
    height() {
      return this.window.height;
    },
    isFirstPage() {
      const isFirstPage = this.selectedPage === 1 ? true : false;
      return isFirstPage;
    },
    isLastPage() {
      const isLastPage = this.selectedPage === this.pages.length ? true : false;
      return isLastPage;
    },

    selectedPage() {
      let selectedPage = this.$store.state.selectedPage;
      if (selectedPage === "frontcover") {
        selectedPage = 1;
      }
      if (selectedPage === "backcover") {
        let nbrOfPages = this.currentJournal.pages.length;
        selectedPage = nbrOfPages;
      }
      return selectedPage;
    },
    setSelectedBlock() {
      this.$store.commit("setSelectedBlock", { id: null });
     
    },
    journalType() {
      return this.$store.state.journalType;
    },
    showPagination() {
      return this.$store.state.showPagination;
    },
    user() {
      return this.$store.state.userInfos;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    showUploadModal() {
      return this.$store.state.showUploadModal;
    },
    pages() {
      return this.$store.state.pages;
    },
    page() {
      return this.pages.find((item) => item.order === this.selectedPage);
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    pageSize() {
      return this.$store.state.pageSize;
    },
    showFillPageButton() {
      const pageStandard = [1, this.pages.length];
      const pagePremium = [1, 2, 3, this.pages.length - 1, this.pages.length];
      if (this.currentJournal.type === "premium") {
        return pagePremium.includes(this.page.order) ? false : true;
      } else {
        return pageStandard.includes(this.page.order) ? false : true;
      }
    },
    showDeleteButton() {
      const pageStandard = [this.pages.length];
      const pagePremium = [2, 3, this.pages.length - 1, this.pages.length];
      if (this.currentJournal.type === "premium") {
        return pagePremium.includes(this.page.order) ? false : true;
      } else {
        return pageStandard.includes(this.page.order) ? false : true;
      }
    },
  },
  methods: {
      isSpecialPremiumPage() {
        return [1, 2, 3, this.pages.length - 1, this.pages.length].includes(this.selectedPage);
      },
      isSpecialKidsPage() {
        return [1, 2, this.pages.length - 1, this.pages.length].includes(this.selectedPage);
      },
    copyPageId(){
      localStorage.pageId = this.page.id;
      if(this.currentJournal.type ==='kids'){
        localStorage.FromKids = true
      }      
      if(this.currentJournal.type !=='kids'){
        localStorage.FromNotKids = true
      }
      this.copiedPage = true;
      this.showSubmenu = false;
      this.showCopiedMessage=true

    },
    async pastePage(){
 
        if(localStorage.FromKids && this.currentJournal.type !== 'kids'){
            this.$notify({
              title: this.$t('error_kids_format'),
        });
              localStorage.removeItem('pageId') 
              localStorage.removeItem('FromKids')
              this.copiedPage = false;
              this.showSubmenu = false;
        }
        else if(localStorage.FromNotKids && this.currentJournal.type === 'kids'){
            this.$notify({
              title: this.$t('error_kids_format'),
        });
              localStorage.removeItem('pageId') 
              localStorage.removeItem('FromNotKids')
              this.copiedPage = false;
              this.showSubmenu = false;
        }else{
          try{
            await Journal.copyPage(this.currentJournal.id,this.page.id);
            this.copiedPage = false;
            this.showSubmenu = false;
          }catch(e){
            console.log(e)
          }
      }
    },
    onClickAwayShowSubMenu() {
      this.outside = false;
      this.showSubmenu = false;
    },
    async fillpage() {
      await Journal.fillPage(this.currentJournal.id, this.page.id);
    },
    changePageSize(size) {
      this.$store.commit("setPageSize", size);
      this.$store.commit("setRemountCropper",true)
    },
    onClickAwayInfosSideBar() {
      this.$store.commit("setShowModalInfosSelectLayout", false);
    },
    setShowModalInfosSelectLayout() {
      this.$store.commit("setShowModalInfosSelectLayout", false);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openDeleteModal() {
      this.$store.commit("setPageToDelete", this.page.id);
      this.$store.commit("setDeleteModal", true);
      this.$store.commit("setOutsideDeleteModal", false);
    },
    async goToNextPage() {
       this.$store.commit("setChangePage", true);
      if (this.selectedPage === "frontcover") {
        this.$store.commit("setSelectedPage", 1);
        this.$router.push({ name: "Workspace", query: { page: 2 } });
      } else if (this.selectedPage === "backcover") {
        this.$store.commit(
          "setSelectedPage",
          this.currentJournal.pages.length - 1
        );
        this.$router.push({
          name: "Workspace",
          query: { page: this.currentJournal.pages },
        });
      } else {
        this.$store.commit("setSelectedPage", this.selectedPage + 1);
        this.$router.push({
          name: "Workspace",
          query: { page: this.selectedPage },
        });
      }
      this.$store.commit("setSelectedBlock", { id: null });
    },
    async goToPrevPage() {
      this.$store.commit("setChangePage", true);
      if (this.selectedPage === "frontcover") {
        this.$store.commit("setSelectedPage", 1);
        this.$router.push({ name: "Workspace", query: { page: 1 } });
      } else if (this.selectedPage === "backcover") {
        this.$store.commit(
          "setSelectedPage",
          this.currentJournal.pages.length - 1
        );
        this.$router.push({
          name: "Workspace",
          query: { page: this.currentJournal.pages.length },
        });
      } else if (this.selectedPage === 2) {
        this.$store.commit("setSelectedPage", 1);
        this.$router.push({ name: "Workspace", query: { page: 1 } });
      } else {
        this.$store.commit("setSelectedPage", this.selectedPage - 1);
        this.$router.push({
          name: "Workspace",
          query: { page: this.selectedPage },
        });
      }
       
     if (typeof this.$store.state.selectedBlock === "string") {
        for (let i = 0; i < this.currentJournal.pages.length; i++) {
          if (this.currentJournal.pages[i].order === this.page.order - 1) {
            if (this.$store.state.hasBlockImage) {
              const journalId = this.currentJournal.id;
              const blockId = this.$store.state.blockId;
              const updatedAt = this.$store.state.blockUpdatedAt;
              const res = await Blocks.editImage(
                journalId,
                blockId,
                this.$store.state.top,
                this.$store.state.left,
                this.$store.state.scale,
                updatedAt,
                "pagination drag"
              );

              let currentJournal = this.currentJournal;
              for (let i = 0; i < currentJournal.pages.length; i++) {
                for (
                  let j = 0;
                  j < currentJournal.pages[i].blocks.images.length;
                  j++
                ) {
                  if (
                    currentJournal.pages[i].blocks.images[j].block_id ===
                    blockId
                  ) {
                    currentJournal.pages[i].blocks.images[j] = res;
                    this.$store.commit("setCurrentJournal", currentJournal);
                    this.$store.commit("setRemountCropper", true);
                  }
                }
              }

              this.$store.commit("setHasBlockImage", false);
              this.$store.commit("setBlockId", null);
              this.$store.commit("setBlockUpdatedAt", null);
              this.$store.commit("setMetaData", null);
            }
          }
        }
      }
      this.$store.commit("setSelectedBlock", { id: null });

    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style>
* {
  box-sizing: border-box;
}

.outerContainer {
  display: flex;
  margin-bottom: 2em;
  width: 100%;
}

.canvasContainer canvas {
  background: white;
  width: 38%;
}

.canvasContainer {
  display: flex;
  justify-content: center;
  width: 48%;
  flex-grow: 1;
}
.canvasContainerWithoutPagination canvas {
  background: white;
  width: 44%;
}
.canvasContainerWithoutPagination {
  display: flex;
  justify-content: center;
  width: 50%;
  flex-grow: 1;
}

@media (max-width: 1190px) {
  .canvasContainer canvas {
    width: 63%;
  }
  .outerContainer {
    display: flex;
    margin-bottom: 1em;
    width: 100%;
  }
}

.journal-scrollbar::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -3px 0px 6px -2px rgba(0, 0, 0, 0.35);
  box-shadow: -3px 0px 6px -2px rgba(0, 0, 0, 0.35);
}
.journal-scrollbar {
  scrollbar-color: #f7f6f6 #fff !important;
  -moz-appearance: none !important;
  overflow-x: hidden;
}
.journal-scrollbar::-webkit-scrollbar-thumb {
  background: #f1f1f1 !important;
  border-radius: 10px;
}
</style>