<template>
  <div class="mt-20" style="height:75vh">
    <p class="font-semibold ml-5 mt-4">{{ $t("sort_by") }}</p>
    <div
      @click="
        selectedFilter = 'all';
        setShowFiltersState();
      "
      class="filter-buttons mt-2 text-sm"
      :class="selectedFilter === 'all' ? 'bg-tribugray-light' : ''"
    >
      <img
        src="../../assets/icons/back-arrow.svg"
        class="mr-3"
        style="width: 14px"
      />
      <p>{{ $t("back_to_all_photos") }}</p>
    </div>
    <div class="font-semibold ml-5 mt-3">{{ $t("filters_title") }}</div>
    <div class="ml-2">
      <div
        @click="
          selectedFilter = 'unusedPhotos';
          setFilterNotUsed();
        "
        class="filter-buttons mt-1 text-sm"
        :class="selectedFilter === 'unusedPhotos' ? 'bg-tribugray-light' : ''"
      >
        <img
          src="../../assets/icons/photo.svg"
          class="mr-3"
          style="width: 18px"
        />
        <p>{{ $t("unused_photos") }}</p>
      </div>

      <div
        @click="selectedFilter = 'user'"
        class="filter-buttons mt-1 text-sm"
        :class="selectedFilter === 'user' ? 'bg-tribugray-light' : ''"
      >
        <img
          src="../../assets/icons/member.svg"
          class="mr-3"
          style="width: 18px"
        />
        <p class="flex items-center">{{ $t("add_by_user") }} :</p>
      </div>
      <div class="flex flex-wrap overflow-auto pb-20 scrollbarFiltre" style="height:55vh">
        <div
          v-for="member in members"
          :key="member"
          @click="setFilterByMember(member.id)"
          class="members ml-5 mr-1 h-8"  
        >
          <div
            v-if="member.picture"
            style="height: 21px; width: 21px; font-size: 10px"
          >
          <img
            :src="member.picture"
            class="rounded-full "
            style="height: 21px; width: 21px;"
          />
          </div>
          <div
            v-if="!member.picture && member.firstname && member.lastname"
            class="initials-picture"
            style="height: 21px; width: 21px; font-size: 10px"
          >
            {{ getInitials(member.id) }}
          </div>
          <div
            v-if="!member.picture && !member.firstname && !member.lastname"
            style="height: 21px; width: 21px; font-size: 10px"
          >
          <img
            src="../../assets/icons/profil.svg"
            class="rounded-full border bg-tribugreen-regular"
            style="height: 21px; width: 21px; padding:2px"
          />
          </div>
          <p class="ml-3">{{ member.firstname }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FiltersImages",
  data() {
    return {
      selectedFilter: null,
      selectedMember: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.userInfos;
    },

    members() {
      return this.$store.state.members;
    },
  },
  methods: {
    setShowFiltersState() {
      this.$store.commit("setFilter", "all");
      this.$store.commit("setShowFilters", false);
    },
    getCreator(id) {
      const members = this.$store.state.members;
      const creator = members.find((item) => item.id === id);
      if(creator){
        return creator;
      }else{
        return {
          email:'',
          firstname:'',
          id:'',
          lastname:'',
          picture:''
        }
      }
      
    },
    getInitials(id) {
      const creator = this.getCreator(id);
      const initials = creator.firstname.charAt(0) + creator.lastname.charAt(0);
      return initials.toUpperCase();
    },
    getCurrentTribe(id) {
      const tribes = this.user.tribes;

      let currentTribe = tribes.find(
        (item) => item.id == this.user.current_tribe
      );
      if (id) {
        currentTribe = tribes.find((item) => item.id == id);
      }
      if(currentTribe.members){
        this.$store.commit("setMembers", currentTribe.members);
      }
    },
    setFilterByMember(id) {
      this.$store.commit("setFilter", "member");
      this.$store.commit("setFilterByMember", id);
      this.$store.commit("setShowFilters", false);
    },
    setFilterNotUsed() {
      this.$store.commit("setFilter", "onlyUnused");
      this.$store.commit("setShowFilters", false);
    },
  },
};
</script>
<style>

/* CUSTOM SCROLLBAR */
.scrollbarFiltre::-webkit-scrollbar{
  width: 7px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0,0,0,0.23); 
box-shadow: -1px 0px 3px 2px rgba(0,0,0,0.4);
}
.scrollbarFiltre{
   scrollbar-color: #c5c5c5 #FFF!important;
     -moz-appearance: none !important;
   
}
.scrollbarFiltre::-webkit-scrollbar-thumb{
  background: #e6e6e6!important;
  border-radius: 10px;

}
</style>