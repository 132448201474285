<template>
<div>
    <div
      v-if="
        !isMiniature && localStorageText && journalSize
      "
      class="bg-white absolute rounded-lg shadow-md border border-gray-200 p-4 mt-10"
      style="width: 250px; z-index:1000; font-size: 12px; margin-left:-260px"
    >
      <p class="text-center">{{ $t("here_is_the_text") }}</p>
      <p class="text-center mt-2">{{ $t("what_do_you_want_to_do") }}</p>
      <div class="mt-3 flex flex-col justify-center">
        <button
          @click="keepOtherText"
          class="text-center bg-tribugreen-regular rounded-full px-1 py-2 text-white hover:bg-tribugreen-medium"
        >
          {{ $t("keep_the_text") }}
        </button>
        <button
          @click="keepMyText"
          class="text-center border border-tribugreen-regular rounded-full px-1 py-1 text-tribugreen-regular mt-2 hover:bg-tribugreen-light hover:border-white"
        >
          {{ $t("insert_my_text") }}
        </button>
      </div>
    </div>
  <div
    v-if="page && journalSize && pageComp"
    :key="componentKey"
    class="bg-white relative page-transition"
    :style="[imageFullPage?'':{ backgroundImage: `url(${pageComp.background})` },isMiniature && currentJournal.type !=='kids'?'height:74px':'',isMiniature && currentJournal.type ==='kids'?'height:54px':'', !isMiniature && currentJournal.type ==='kids'?computedStyle:'']"
    style="background-size: contain"
  >
    <div
      v-if="isMiniature"
      class="absolute z-30 bg-white opacity-0"
      :style="currentJournal.type === 'kids'?'width: 51px; height: 51px': 'width: 51px; height: 70px'"
    ></div>
    <div v-if="page === 1" class="w-full flex flex-col items-center" :style="computedStyle" >
      <div
        :class="isMiniature ? 'tribe_name_small' : 'tribe_name'"
        :style="[isMiniature ? 'font-size:3px' : '', pageSize==='small' && !isMiniature?'font-size:12px; margin-top:70px':'', pageSize==='medium' && !isMiniature?'font-size:18px; margin-top:108px':'', pageSize==='big' && !isMiniature?'font-size:24px; margin-top:130px':'', pageSize==='huge' && !isMiniature?'font-size:28px; margin-top:170px':'']"
        v-if="currentTribe && journalType !== 'premium' && journalType !== 'kids'"
      >
        {{ currentTribe.name }}
      </div>
      <div
        :class="isMiniature ? 'tribe_name_small' : 'tribe_name'"
        :style="[isMiniature ? 'font-size:3px' : '', pageSize==='small' && !isMiniature?'font-size:12px; margin-top:55px':'', pageSize==='medium' && !isMiniature?'font-size:20px; margin-top:80px':'', pageSize==='big' && !isMiniature?'font-size:24px; margin-top:100px':'',pageSize==='huge' && !isMiniature?'font-size:30px; margin-top:120px':'']"
        v-if="currentTribe && journalType === 'premium'"
      >
        {{ currentTribe.name }}
      </div>
      <div
        class="font-quicksand"
        :style="[currentTribe.name.length < 19 && isMiniature ? 'font-size:3px; margin-top:4px' : '', currentTribe.name.length < 19 && pageSize==='small' && !isMiniature?'font-size:22px; margin-top:25px':'', currentTribe.name.length < 19 && pageSize==='medium' && !isMiniature?'font-size:35px; margin-top:35px':'', currentTribe.name.length < 19 && pageSize==='big' && !isMiniature?'font-size:40px; margin-top:40px':'',currentTribe.name.length < 19 && pageSize==='huge' && !isMiniature?'font-size:50px; margin-top:50px':'', currentTribe.name.length > 18 && !isMiniature && pageSize==='small'?'font-size:17px; width:90% ;word-break: break-all;text-align:center; margin-top:20px; margin-bottom:3px':'', currentTribe.name.length > 18 && !isMiniature && pageSize==='medium'?'font-size:25px; width:90% ;word-break: break-all;text-align:center; margin-top:25px; margin-bottom:3px':'', currentTribe.name.length > 18 && !isMiniature && pageSize==='big'?'font-size:30px; width:90% ;word-break: break-all;text-align:center; margin-top:30px; margin-bottom:3px':'',, currentTribe.name.length > 18 && isMiniature?'font-size:3px; width:90% ;word-break: break-all;text-align:center; margin-top:1px;':'']"
        v-if="currentTribe && journalType === 'kids'"
      >
       {{ currentTribe.name }}
      </div>
      <div
        v-if="journalType === 'premium'"
        :class="isMiniature ? 'premium_date_small' : 'premium_date'"
        :style="[ pageSize==='small' && !isMiniature?'font-size:6px; margin-top:3px':'', pageSize==='medium' && !isMiniature?'font-size:10px; margin-top:4px':'', pageSize==='big' && !isMiniature?'font-size:11px; margin-top:7px':'', pageSize==='huge' && !isMiniature?'font-size:14px; margin-top:7px':'']"

      >
        <p>{{ $t(journalDateMonth) }} {{ journalDateYear }}</p>
      </div>
      <div
        v-if="journalType !== 'premium' && journalType !== 'kids'"
        :class="isMiniature ? 'standard_date_small' : 'standard_date'"
        :style="[isMiniature ? 'font-size:1px' : '', pageSize==='small' && !isMiniature?'font-size:6px;':'',pageSize==='medium' && !isMiniature?'font-size:9px;':'',pageSize==='big' && !isMiniature?'font-size:12px;':'',pageSize==='huge' && !isMiniature?'font-size:14px;':'']"
      >
        <p>{{ $t(journalDateMonth) }} {{ journalDateYear }}</p>
      </div>
      <div
        v-if="journalType === 'kids'"
        class="font-quicksand text-tribugreen-kids font-bold"
        :style="[isMiniature ? 'font-size:1px' : '', pageSize==='small' && !isMiniature?'font-size:10px; margin-top:-5px':'',pageSize==='medium' && !isMiniature?'font-size:16px; margin-top:-10px':'',pageSize==='big' && !isMiniature?'font-size:18px;margin-top:-10px':'',pageSize==='huge' && !isMiniature?'font-size:24px;margin-top:-15px':'']"
      >
        <p>{{ $t(journalDateMonth) }} {{ journalDateYear }}</p>
      </div>
    </div>
    <div
      v-if="page === 3 && journalType === 'premium'"
      class="w-full flex flex-col items-center"
           :style="computedStyle"
    >
      <p
        :class="isMiniature ? 'tribe_name_small mt-6' : 'tribe_name'"
        :style="[isMiniature ? 'font-size:2px' : 'margin-top:37%',pageSize==='small' && !isMiniature?'font-size:15px;margin-top:110px':'',pageSize==='medium' && !isMiniature?'font-size:20px;margin-top:170px':'',pageSize==='big' && !isMiniature?'font-size:22px;margin-top:210px':'',pageSize==='huge' && !isMiniature?'font-size:28px;margin-top:260px':'']"
      >
        {{ currentTribe.name }}
      </p>
      <p :class="isMiniature ? 'premium_date_small' : 'premium_date'"
      :style="[isMiniature ? 'font-size:1px' : 'margin-top:37%',pageSize==='small' && !isMiniature?'font-size:6px;margin-top:3px':'',pageSize==='medium' && !isMiniature?'font-size:9px;margin-top:5px':'',pageSize==='big' && !isMiniature?'font-size:10px;margin-top:5px':'',pageSize==='huge' && !isMiniature?'font-size:12px;margin-top:7px':'']"
      >
        {{ $t("our_story") }}
      </p>
    </div>
    <div
      v-if="journalType !== 'premium'"
          
    >
      <layout-block
        v-for="layoutBlockPerPage in layoutBlocksPerPage"
        :key="layoutBlockPerPage.id"
        :block="layoutBlockPerPage"
        :page="page"
        :isMiniature="isMiniature"
      ></layout-block>
    </div>
      <div
      v-if="journalType === 'premium' && page !==3 " :style="page ===1?'':computedStyle"
          
    >
      <layout-block
        v-for="layoutBlockPerPage in layoutBlocksPerPage"
        :key="layoutBlockPerPage.id"
        :block="layoutBlockPerPage"
        :page="page"
        :isMiniature="isMiniature"
      ></layout-block>
    </div>  
    <div
      v-if="
        displayPageNumber &&
        journalType === 'premium' &&
        !imageFullPage
      "
      class="page_number justify-center"
      :class="isMiniature ? 'mb-1' : 'mb-3'"
      
    >
      <p :style="[isMiniature ? 'font-size:2px' : '',pageSize==='small' && !isMiniature?'font-size:6px; margin-left:6px':'',pageSize==='medium' && !isMiniature?'font-size:9px;margin-bottom:5px;margin-left:6px':'',pageSize==='big' && !isMiniature?'font-size:10px;margin-bottom:5px; margin-left:6px':'',pageSize==='huge' && !isMiniature?'font-size:12px;margin-bottom:8px; margin-left:6px':'']">{{ page }}</p>
    </div>
  <div v-if="displayPageNumber && journalType !== 'premium' && journalType !== 'kids'" :style="computedStyle">
      <p
        v-if="evenNumber"
        :style="
          [isMiniature
            ? 'font-size:2px'
            : '',pageSize === 'small' && !isMiniature?'font-size:6px; margin-bottom:10px':'',pageSize === 'medium' && !isMiniature?'font-size:10px; margin-bottom:10px;margin-left:35px':'',pageSize === 'big' && !isMiniature?'font-size:12px; margin-bottom:15px; margin-left:40px':'',pageSize === 'huge' && !isMiniature?'font-size:12px; margin-bottom:15px; margin-left:40px':''
        ]"
        class="page_number text-tribugreen-regular"
        :class="isMiniature ? ' ml-1 ' : ' ml-6'"
      >
        {{ page - 1 }}
      </p>
      <p
        v-else
        :style="[isMiniature? 'font-size:2px': '',pageSize === 'small' && !isMiniature?'font-size:6px; margin-bottom:10px; margin-left:5px':'',pageSize === 'medium' && !isMiniature?'font-size:10px; margin-bottom:10px;':'',pageSize === 'big' && !isMiniature?'font-size:12px; margin-bottom:15px;margin-right:5px ':'',pageSize === 'huge' && !isMiniature?'font-size:12px; margin-bottom:15px;margin-right:5px ':'']"
        
        class="page_number text-tribugreen-regular justify-end"
        :class="[isMiniature ? 'pr-1' : 'mb-2 pr-6']"
      >
        {{ page - 1 }}
      </p>
    </div>
    <div
      v-if="page === pages.length && journalType === 'premium'"
      class="absolute bottom-0 w-full flex justify-center"
    >
      <img
        :class="[isMiniature ? 'w-1 h-1 mb-1' : '',pageSize === 'small' && !isMiniature?'w-3 h-3 mb-6':'',pageSize === 'medium' && !isMiniature?'w-6 h-6 mb-10':'',pageSize === 'big' && !isMiniature?'w-8 h-8 mb-12':'',pageSize === 'huge' && !isMiniature?'w-8 h-8 mb-12':'']"
        src="../../assets/img/qr-code.png"
      />

    </div>
    <div
      v-if="
        page === pages.length &&
        journalType !== 'premium' &&
        journalType !== 'digital' &&
        journalType !== 'kids' &&
        !isMiniature
      "
      class="absolute bg-white right-0 mt-16 pt-3 pl-3 border border-gray-300 border-dashed"
      style="width: 40%; height: 13%; margin-right: 5%; font-size: 1vh"
      
    >
      <p>{{ $t("recipient_address") }}</p>
    </div>
    <div
      class="absolute bottom-0 w-full justify-center"
      v-if="journalType !== 'premium' && page === 1"
      
    >

    </div>
    <div
      class="w-full flex justify-center"
      v-if="journalType !== 'premium' && page === pages.length && journalType !== 'kids'"
      :style="computedStyle"
    >
      <img
        :class="[isMiniature ? 'w-1 h-1 mb-1' : '',!isMiniature && pageSize === 'small'?'w-6 h-6 mt-5':'',!isMiniature && pageSize === 'medium'?'w-8 h-8 mt-7':'',!isMiniature && pageSize === 'big'?'w-8 h-8 mt-9':'', !isMiniature && pageSize === 'huge'?'w-12 h-12 mt-9':'']"
        src="../../assets/img/qr-code.png"
      />
    </div>


    <div
      v-if="journalType !== 'kids' && journalType !== 'premium' && page === pages.length"
      class="absolute bottom-0 w-full justify-center"
      
    >
    
      <p
        class="font-poppins text-tribugreen-regular text-center"
        :style="
          [isMiniature
            ? 'font-size:0.05rem; margin-bottom:0.02rem'
            : '',
            !isMiniature && pageSize === 'small'?'font-size:2px; margin-bottom:11px; margin-left:5px':'',
            !isMiniature && pageSize === 'medium'?'font-size:5px; margin-bottom:10px; margin-left:5px':'',
            !isMiniature && pageSize === 'big'?'font-size:6px; margin-bottom:13px; margin-left:5px':'',
            !isMiniature && pageSize === 'huge'?'font-size:7px; margin-bottom:15px; margin-left:6px':'',
        ]"
      >
        {{ $t("for_each_printed") }}<br />
        {{ $t("newspaper_a_tree") }}<br />
        {{ $t("is_planted") }}
      </p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import LayoutBlock from "../Blocks/LayoutBlock.vue";
import { Blocks } from "../../api";
export default {
  name: "Page",
  props: {
    isMiniature: Boolean,
    page: Number,
  },

  components: {
    LayoutBlock,
  },
  
  mounted() {
    if (this.displayedPage) {
      if (this.displayedPage.blocks.texts.length === 0) {
        localStorage.removeItem("textcontent");
      }

    }
    if (localStorage.textcontent) {
      this.localStorageText = true;
    }
  },
  data() {
    return {
      componentKey: 0,
      localStorageText: false,
    };
  },
  watch: {
    displayedPage(value, oldValue) {
      if (value !== oldValue) {
          this.$store.commit("setSelectedBlock", { id: null });

        if (!this.isMiniature) {
          this.componentKey = !this.componentKey;
        }
      }
    },

  },
  methods: {
    setSelectedBlock() {
      this.$store.commit("setSelectedBlock", { id: null });
    },
    keepOtherText() {
      localStorage.removeItem("textcontent");
      this.localStorageText = false;
    },
    async keepMyText() {
        const journalId = this.currentJournal.id;
        const blockId = this.textBlock.block_id;
        const updatedAt = this.textBlock.updated_at;
        const res = await Blocks.updateTextBlock(
          journalId,
          blockId,
          localStorage.textcontent,
          updatedAt
        );

      let currentJournal = this.currentJournal;
      for (let i = 0; i < currentJournal.pages.length; i++) {
        for (let j = 0; j < currentJournal.pages[i].blocks.texts.length; j++) {
          if (currentJournal.pages[i].blocks.texts[j].block_id === blockId) {
            currentJournal.pages[i].blocks.texts[j] = res;
            this.$store.commit("setCurrentJournal", currentJournal);
          }
        }
      }
      localStorage.removeItem("textcontent");
      localStorage.removeItem("blockOrder");

      this.localStorageText = false;
      this.componentKey = !this.componentKey;
    },
  },
  computed: {
    remountBlockText(){
      return this.$store.state.remountBlockText
    },
    computedStyle(){
      if(!this.isMiniature){
      return{
        width: `${this.journalSize.width}px`,
        height: `${this.journalSize.height}px`
      }
      }

    },
    pageObject() {
      return this.currentJournal.pages.find((item) => item.order === this.page);
    },
    textBlock() {
      if (this.pageObject.blocks.texts) {
        if (this.pageObject.blocks.texts.length > 0) {
          const textBlock = this.pageObject.blocks.texts[0]
           
          if (textBlock) {
            return textBlock;
          }
        }
      }
    },
    selectedBlock(){
      return this.$store.state.selectedBlock
    },
    imageFullPage(){
      if(this.selectedPage !== 'frontcover'){
        if(this.displayedPage.layout_id===223){
          return true
        }
        else{
          return false
        }
      }else{
        return false
      }
    },
    selectedPage(){
      return this.$store.state.selectedPage
    },
    journalSize() {
      return this.$store.state.journalSize;
    },
    lang() {
      return this.$store.state.language;
    },
    displayedPage() {
      return this.$store.state.displayedPage;
    },
    pageComp() {
      return this.pages.find((item) => item.order === this.page);
    },
    layoutBlocksPerPage() {
      const arrayBlock = [];
      for (let i = 0; i < this.allLayoutBlock.length; i++) {
        if (this.layout) {
          if (this.allLayoutBlock[i].layout_id == this.layout.id) {
            arrayBlock.push(this.allLayoutBlock[i]);
          }
        }
      }
      return arrayBlock;
    },
    layouts() {
      return this.$store.state.layouts;
    },
    pageSize() {
      return this.$store.state.pageSize;
    },
    layout() {
      const page = this.pages.find((item) => item.order === this.page);
      let layout = null;
      this.layouts.forEach((item) => {
        if (item.id === page.layout_id) {
          layout = item;
        }
      });
      return layout;
    },
    allLayoutBlock() {
      return this.$store.state.layoutBlock;
    },
    evenNumber() {
      return this.page % 2 === 0;
    },
    user() {
      return this.$store.state.userInfos;
    },
    journalType() {
      return this.$store.state.journalType;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    pages() {
      return this.$store.state.pages;
    },

    currentTribe() {
      return this.$store.state.currentTribe;
    },
    journalDateMonth() {
      return moment(this.currentJournal.created_at).format("MMMM");
    },
    journalDateYear() {
      return moment(this.currentJournal.created_at).format("YYYY");
    },
    displayPageNumber() {
      if (this.journalType === 'kids') {
        return false;
      }

      return this.journalType === "premium"
        ? ![1, 2, this.pages.length - 1, this.pages.length].includes(this.page)
        : ![1, this.pages.length].includes(this.page);
    },
  },
};
</script>
<style>
.page-transition {
  animation-name: page-opacity;
  animation-duration: 1s;
}
@keyframes page-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>