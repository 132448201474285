<template>
  <div class="w-full flex  h-screen font-inter" style="position:absolute; z-index:10000" :class="isMobile?'bg-tribugreen-light':'background-auth'">
    <div v-if="!isMobile" class="w-1/2"></div>
    <div :class="isMobile?'w-full mx-3':'w-1/2 mx-10'" class="bg-tribugreen-light rounded-xl my-10 h-full" style="max-width:500px; max-height:500px;">
      <div class="flex flex-col w-full items-center">
        <div class="w-full flex justify-center border-b border-tribugreen-regular">
          <img src="../assets/logo-black.svg" class="w-12 my-5"/>
        </div>
        <img style="width:130px" class="mt-3 mb-5" src="../assets/icons/validate.svg"/>
        <p>{{$t("welcome_tribe_text1")}}</p>
        <p><b>{{currentTribeName}}</b></p> 
        <p style="width:320px" class="mt-5 text-center text-sm">{{$t("welcome_tribe_text2")}}</p>
        <div @click="closeModale" style="width:150px" class="hover:bg-tribugreen-medium cursor-pointer text-center rounded-full bg-tribugreen-regular text-xs text-white py-2 mt-10">{{$t('welcome_tribe_btn1')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";
import { User } from "../api";
export default {
  directives: {
    ClickAway: directive
  },
  props:{
    isMobile:Boolean
  },
  data(){
    return{
      link:null
    }
  },
computed:{
    currentTribe() {
      return this.$store.state.currentTribe;
    },
    currentTribeName() {
      if(this.user.tribes){
        const tribe = this.user.tribes.find(tribu => tribu.id === this.user.current_tribe)
        return tribe.name;
      }
    },
    user() {
      return this.$store.state.userInfos;
    }
    
},
async mounted(){
const menu = await User.getMenu();
await User.getUserInformations();
const menuMember = menu.find(item => item.key ==='list_members')
this.link = menuMember.link
localStorage.removeItem("joinTribe");
},
  methods: {
    onClickAway() {
        this.closeModale()

    },
    closeModale(){
        this.$store.commit('setShowWelcomeMessage',false)
        localStorage.guide = true
    },
    goToMember(){
        window.location.href=this.link
    
    },


  },
};
</script>
