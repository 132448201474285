<template>
<div class="w-screen h-screen fixed bg-tribugreen-light font-inter flex justify-center" style="z-index:2000">
    <div v-if="!isMobile" class="relative" style="max-width:600px;padding-top:3%">
       <div class="absolute flex justify-center w-full" style="z-index:2001; margin-top:25px">
            <img  style="width:150px;" src="../assets/icons/broken-link.svg"/>
        </div>
        <div class="bg-white px-10 pb-10 rounded-xl mx-5 text-center" style="padding-top:100px;max-whidth:1200px; margin-top:100px">
            <div class="flex items-center justify-center"><b>{{$t('broken_link_1')}}</b><img class="w-7 ml-2" src="../assets/icons/emoji.png"/></div>
            <p class="mt-2">{{$t('broken_link_2')}}</p>
            <p>{{$t('broken_link_3')}}</p>
            <p class="mt-2 mb-8">{{$t('broken_link_4')}}</p>
            <p>{{$t('broken_link_5')}}</p>
            <p>{{$t('broken_link_6')}}</p>
            <p class="px-3">{{$t('broken_link_7')}}</p>
            <p class="mb-10">{{$t('broken_link_8')}}</p>
            <a v-if="language ==='FR'" href="https://faq.mytribunews.com/fr/article/comment-rejoindre-trouver-une-tribu-9gkksk/" class="cursor-pointer hover:bg-tribugreen-semidark bg-tribugreen-regular text-white rounded-full py-2 px-5">{{$t('more_info')}}</a>
            <a v-if="language ==='NL'" href="https://faq.mytribunews.com/nl/article/hoe-kan-ik-me-bij-een-tribu-aansluiten-1kwh1ol/" class="cursor-pointer hover:bg-tribugreen-semidark bg-tribugreen-regular text-white rounded-full py-2 px-5">{{$t('more_info')}}</a>
            <a v-if="language ==='EN'|| language ==='DE'" href="https://faq.mytribunews.com/en/article/how-to-join-find-a-tribu-family-b39ebd/" class="cursor-pointer hover:bg-tribugreen-semidark bg-tribugreen-regular text-white rounded-full py-2 px-5">{{$t('more_info')}}</a>
        </div>
    </div>
    <div v-if="isMobile" classs="relative">
       <div class="absolute flex justify-center w-full" style="z-index:2001; margin-top:25px">
            <img  style="width:150px;" src="../assets/icons/broken-link.svg"/>
        </div>
        <div class="bg-white px-10 pb-10 rounded-xl mx-5 text-center" style="padding-top:100px;max-whidth:1200px; margin-top:100px">
            <div class="flex items-center justify-center"><b>{{$t('broken_link_1')}}</b><img class="w-7 ml-2" src="../assets/icons/emoji.png"/></div>
            <p class="mt-2">{{$t('broken_link_2')}}</p>
            <p class="">{{$t('broken_link_3')}}</p>
            <p class="mt-2 mb-10">{{$t('broken_link_4')}}</p>
            <p class="">{{$t('broken_link_5')}}</p>
            <p class="">{{$t('broken_link_6')}}</p>
            <p class="">{{$t('broken_link_7')}}</p>
            <p class="mt-2 mb-10">{{$t('broken_link_8')}}</p>
            <a v-if="language ==='FR'" href="https://faq.mytribunews.com/fr/article/comment-rejoindre-trouver-une-tribu-9gkksk/" class="cursor-pointer hover:bg-tribugreen-semidark bg-tribugreen-regular text-white rounded-full py-2 px-5">{{$t('more_info')}}</a>
            <a v-if="language ==='NL'" href="https://faq.mytribunews.com/nl/article/hoe-kan-ik-me-bij-een-tribu-aansluiten-1kwh1ol/" class="cursor-pointer hover:bg-tribugreen-semidark bg-tribugreen-regular text-white rounded-full py-2 px-5">{{$t('more_info')}}</a>
            <a v-if="language ==='EN'|| language ==='DE'" href="https://faq.mytribunews.com/en/article/how-to-join-find-a-tribu-family-b39ebd/" class="cursor-pointer hover:bg-tribugreen-semidark bg-tribugreen-regular text-white rounded-full py-2 px-5">{{$t('more_info')}}</a>
        </div>
    </div>
</div>
</template>
<script>
export default{
    data() {
        return {
        window: {
            width: 0,
            height: 0,
        },
        };
    },
    computed:{
        isMobile() {
        return this.window.width < 1250 ? true : false;
        },
        language(){
            return this.$store.state.language
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }

}
</script>