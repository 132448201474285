import moment from 'moment/min/moment-with-locales';
import store from './store';
import heic2any from "heic2any";

export default {
    changeLastSave() {
        store.commit("setUpdateAtJournal", moment())
        store.commit("setReloadHeader", true);
    },
    async getBase64(fileData) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onloadend = async () => {
                try {
                    const base64 = await reader.result;
                    resolve(base64);
                } catch (err) {
                    reject(err);
                }
            };
        });
    },
    async convertToBlob(base64) {
        const blob = await (await fetch(base64)).blob()
        const convertImage = await heic2any({blob, toType: "image/jpeg", quality: 0.8})
        return convertImage;
    },
    event(action, eventParams = {}) {
        window.dataLayer?.push( {
            event: action,
            login_status: 'Logged In',
            language: store.state.language,
            user_id: store.state.userInfos.id,
            tribe_id: store.state.userInfos.current_tribe,
            //tribe_is_first: true, // TODO: get is the first user tribe
            ...eventParams,
        })
    },
    async journalEvent(action, eventParams = {}) {
        const currentJournal = store.state.currentJournal
        let selectedPage = store.state.selectedPage;
        if (selectedPage === 'frontcover') {
            selectedPage = 1;
        }
        if (selectedPage === 'backcover') {
            selectedPage = currentJournal.pages.length
        }
        let displayedPage = currentJournal.pages.find(item => item.order === selectedPage);
        let layout = store.state.layouts.find(el => el.id === displayedPage.layout_id);
        let layoutTextsCount = null;
        let layoutImagesCount = null;
        if(layout){
            layoutTextsCount = layout.texts_count
            layoutImagesCount = layout.images_count
        }
       
        let pageCategory = "unknown";
        if (layoutImagesCount && layoutTextsCount) {
            pageCategory = `${layoutImagesCount} Images and ${layoutTextsCount} Texts`;
        } else if (layoutImagesCount) {
            pageCategory = `${layoutImagesCount} Images`;
        } else if (layoutTextsCount) {
            pageCategory = `${layoutTextsCount} Texts`;
        }

        this.event(action, {
            newspaper_id: currentJournal.id,
            newspaper_period: moment(currentJournal.created_at).format('YYYY-MM'),
            newspaper_page_number: displayedPage.order, // TODO: use the true page order and not DB
            page_layout_category: pageCategory,
            page_layout_id: displayedPage.layout_id,
            page_image_count: displayedPage.blocks.images.length,
            page_text_count: displayedPage.blocks.texts.length,
            page_legend_count: displayedPage.blocks.images.filter(el => el.legend).length,
            ...eventParams,
        })
    }
}
