import APICONF from "../api-config";
import store from "../store";
import axios from "axios";
import imageCompression from "browser-image-compression";
import utils from "../utils";
export default {
  async getAllImages(param) {
    const root = import.meta.env.VITE_API_ROOT;
    const headers = {
      Authorization: "Bearer " + localStorage.impersonate_token,
      "Content-Type": "application/json",
      "X-WEB": "true",
    };
    try {
      if (localStorage.impersonate_token) {
        const res = param
          ? await axios.get(root + "/images?" + param, { headers })
          : await axios.get(root + "/images", { headers });
        const images = res.data;
        store.commit("setNoImageView", false);
        if (images.data.length === 0) {
          store.commit("setNoImageView", true);
        }
        return images;
      } else {
        const res = param
          ? await APICONF.get("/images?" + param)
          : await APICONF.get("/images");
        const images = res.data;
        store.commit("setNoImageView", false);
        if (images.data.length === 0) {
          store.commit("setNoImageView", true);
        }
        return images;
      }
    } catch (error) {
      if(error.response){
        if(error.response.status !== 401){
          store.commit("setErrorMessage", "error_get_all_images");
         }
        }
      
      
    }
  },
  async deleteImage(id) {
    try {
      const root = import.meta.env.VITE_API_ROOT;
      const headers = {
        Authorization: "Bearer " + localStorage.impersonate_token,
        "Content-Type": "application/json",
        "X-WEB": "true",
      };

      const res = localStorage.impersonate_token
        ? await axios.delete(`${root}/images?ids[]=${id}`, { headers })
        : await APICONF.delete(`/images?ids[]=${id}`);
      return res.data;
    } catch (e) {
      store.commit("setErrorMessage", "error_delete_image");
      setTimeout(() => document.location.reload(), 5000);
    }
  },
  async uploadImages(item, options, tribes) {
    let compressionFaild = false
    const token = localStorage.token;
    const headers = {
      "X-WEB": "true",
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
    };
    console.log(item)
    const uploadRoot = import.meta.env.VITE_API_UPLOAD;
    let formData = new FormData();

    if (item.size > 2000000) {
      try{
      item.data = await imageCompression(item.data, options);
    }catch(e){
      compressionFaild = true
    }
    }

    const heicExtension = "heic";

    if (item.extension.toUpperCase() === heicExtension.toUpperCase()) {
      let fileData = item.data;

      try {
        const base64 = await utils.getBase64(fileData);
        const blob = await utils.convertToBlob(base64);
        item.data = blob;
      } catch (e) {}
    }
    formData.append("file", item.data);
    store.commit("setShowUploadModal", false);
    for (let i = 0; i < tribes.length; i++) {
      formData.append(`tribeIds["${i}"]`, tribes[i]);
    }
    if(compressionFaild){
      if (item.size > 2000000) {
        try{
        item.data = await imageCompression(item.data, options);
      }catch(e){
      }
      }
    }
    try {

      const response = await axios.post(`${uploadRoot}`, formData, { headers });
      let newItem = {
        created_at: response.data.created,
        creator_id: response.data.creatorId,
        formats: {
          thumbnail: {
            width: response.data.imageSrcSet[0].width,
            height: response.data.imageSrcSet[0].height,
            path: response.data.imageSrcSet[0].src,
          },
          medium: {
            width: response.data.imageSrcSet[1].width,
            height: response.data.imageSrcSet[1].height,
            path: response.data.imageSrcSet[1].src,
          },
          original: {
            width: response.data.imageSrcSet[2].width,
            height: response.data.imageSrcSet[2].height,
            path: response.data.imageSrcSet[2].src,
          },
        },
        id: response.data.id,
        is_used_previously: false,
        legend: response.data.legend,
      };
      store.commit("AddImage", newItem);


      utils.changeLastSave();
    } catch (e) {
      store.commit("setErrorMessage", "error_upload");
      store.commit("setNumberOfImages", 0);
      store.commit("setFakeImages", 0);
      setTimeout(() => document.location.reload(), 5000);
    }
  },
};