import APICONF from "../api-config";
import Journal from "./journal";
import store from "../store";
import utils from "../utils";
import axios from 'axios';

export default {
  async insertNewImageBlock(body) {
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      const res =localStorage.impersonate_token? await axios.post(root+"/blocks/image", body,{headers}): await APICONF.post("/blocks/image", body);
      store.commit("setSelectedBlockOrder", null);
      utils.changeLastSave();
      return res.data;
    } catch (e) {
      if(e.response.status){
      if(e.response.status === 422){
      store.commit("setModalErrorPage", true);
      }else{
        store.commit("setErrorMessage", "error_insert_image_block");
        setTimeout(() =>document.location.reload(),5000)
      }
    }
    }
  },
  async editImage(journalId, blockId, positionY, positionX, scale, updatedAt) {
    let x = positionX < 0 ? 0 : positionX;
    let y = positionY < 0 ? 0 : positionY;
    x = positionX > 1 ? 1 : positionX;
    y = positionY > 1 ? 1 : positionY;
    let newScale = scale;
    if (!newScale) {
      newScale = 1;
    }
    const body = {
      metadata: {
        x: x,
        y: y,
        scale: newScale,
      },
      updated_at: updatedAt,
    };
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      const res = localStorage.impersonate_token? await axios.put(
        root+"/journals/" + journalId + "/blocks/" + blockId,
        body, {headers}
      ): await APICONF.put(
        "/journals/" + journalId + "/blocks/" + blockId,
        body
      );
      store.commit("setLastUpdateAt", res.update_at);
      utils.changeLastSave();
      store.commit('setRemountCropper',true)

      return res.data;
    } catch (e) {
      if(e.response.status !== 404){
        store.commit("setErrorMessage", "error_edit_image_block");
        setTimeout(() =>document.location.reload(),5000)
      }

    }
  },
  async clearPage(journalId, pageId) {
    await utils.journalEvent('content_removed')
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      localStorage.impersonate_token? await axios.delete(root+"/journals/" + journalId + "/pages/" + pageId, {headers}): await APICONF.delete("/journals/" + journalId + "/pages/" + pageId);

      //to do opti get current journal
      const currentJournal = localStorage.impersonate_token? await Journal.getSpecificJournal(store.state.userInfos.current_tribe,journalId): await Journal.getCurrentJournal()   
      let displayedPage = currentJournal.pages.find(
        (item) => item.order === store.state.selectedPage
      );
      store.commit("setDisplayedPage", displayedPage);
      store.commit("setCurrentJournal", currentJournal);
      utils.changeLastSave();
    } catch (e) {
      console.log(e)
      store.commit("setErrorMessage", "error_clear_page")
      setTimeout(() =>document.location.reload(),5000)
    }
  },
  async deleteBlock(journalId, blockId) {
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      localStorage.impersonate_token? await axios.delete(root+"/journals/" + journalId + "/blocks/" + blockId, {headers}):await APICONF.delete("/journals/" + journalId + "/blocks/" + blockId);
      utils.changeLastSave();
    
    } catch (e) {

      if(e.response.status === 404){
      }else{
        store.commit("setErrorMessage", "error_delete_image");
        setTimeout(() =>document.location.reload(),5000)
      }
    }
  },
  async editLegend(
    journalId,
    blockId,
    legend,
    positionX,
    positionY,
    scale,
    updatedAt
  ) {
    const body = {
      legend: legend,
      x: positionX,
      y: positionY,
      scale: scale,
      updated_at: updatedAt,
    };
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      const res = localStorage.impersonate_token? await axios.put(
        root +"/journals/" + journalId + "/blocks/" + blockId,
        body, {headers}): await APICONF.put(
        "/journals/" + journalId + "/blocks/" + blockId,
        body
      );
      utils.changeLastSave();
      store.commit('setRemountCropper',true)
      return res.data;
    } catch (e) {
      store.commit("setErrorMessage", "error_legend");
      setTimeout(() =>document.location.reload(),5000)
    }
  },
  async insertTextBlock(body, blockOrder) {
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      const res = localStorage.impersonate_token? await axios.post(root+"/blocks/text", body, {headers}) : await APICONF.post("/blocks/text", body);
      store.commit("setRemountBlockText",true)
      store.commit("setRemountPage",true)

      utils.changeLastSave();
      return res.data;
    } catch (error) {
      if(error.response){
      if(error.response.status === 422){
        localStorage.setItem('textcontent', body.content);
        localStorage.setItem('blockOrder',blockOrder)
        store.commit("setModalErrorText", true);
       }
      }

    }
  },
  async updateTextBlock(journalId, blockId, content, updatedAt,blockOrder) {
    const body = {
      content: content,
      updated_at: updatedAt,
    };
    const root= import.meta.env.VITE_API_ROOT
    const headers= {
     'Authorization' : 'Bearer ' + localStorage.impersonate_token,
     'Content-Type' : 'application/json',
      'X-WEB' : 'true' 
    }
    try {
      const res = localStorage.impersonate_token? await axios.put(
        root+"/journals/" + journalId + "/blocks/" + blockId,
        body, {headers}
      ):await APICONF.put(
        "/journals/" + journalId + "/blocks/" + blockId,
        body
      );
      utils.changeLastSave();
      let currentJournal = store.state.currentJournal;
      for (let i = 0; i < currentJournal.pages.length; i++) {
        for (let j = 0; j < currentJournal.pages[i].blocks.texts.length; j++) {
          if (currentJournal.pages[i].blocks.texts[j].block_id === blockId) {
            currentJournal.pages[i].blocks.texts[j] = res.data;
            store.commit("setCurrentJournal", currentJournal);
          }
        }
      }
      store.commit("setRemountBlockText",true)
      store.commit("setRemountPage",true)
    
      return res.data;
    } catch (error) {
      if(error.response){
      if(error.response.status === 422){
       localStorage.setItem('textcontent', content);
       localStorage.setItem('blockOrder',blockOrder)
       store.commit("setModalErrorText", true);
      }
    } 
    }
  },
  async updateBlockOrder(journalId,blockId,page, currentOrder, newOrder){
    try{
      const headers= {
        'Authorization' : 'Bearer ' + localStorage.impersonate_token,
        'Content-Type' : 'application/json',
         'X-WEB' : 'true' 
       }  
       const body = {
        current_order:currentOrder,
        new_order: newOrder,
      };   
      const res =localStorage.impersonate_token? await axios.put(root+"/journals/"+journalId+"/blocks/"+blockId+"/order", body, {headers}):await APICONF.put("/journals/"+journalId+"/blocks/"+blockId+"/order",body);
      store.commit("setSwitchBlockMode",false)
      const currentJournal = store.state.currentJournal
      for(let i = 0; currentJournal.pages.length; i ++){
        if(currentJournal.pages[i].id === page){
          currentJournal.pages[i] = res.data
        }
      }
     
      store.commit("setCurrentJournal", currentJournal);
      store.commit("setSelectedBlock",{ id: null })



    }catch(e){
     // store.commit("setErrorMessage", "member_change_image");
    }
  },
};
