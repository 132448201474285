import { createApp } from 'vue'
import { createI18n} from "vue-i18n/index";
import translations from "./translations.json";


const messages = {
  FR: {},
  NL: {},
  EN: {},
  DE: {}
};

Object.keys(translations).forEach(message => {
  Object.keys(messages).forEach(language => {
    messages[language][message] = translations[message][language];
  });
});

const i18n = createI18n({
    locale:'FR',
    messages,
  
})

export default i18n;

