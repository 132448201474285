<template>
  <div
    class="
      absolute
      w-full
      bg-black bg-opacity-50
      h-screen
      flex
      justify-center
      items-center
      -mt-10
      z-40
    "
  >
    <div class="bg-white rounded-xl flex flex-col items-center px-3 py-3 w-80">
      <div
        class="text-xs text-tribugray-medium w-full border-b border-tribugray-light text-center pb-2"
      >
        {{ $t("legend") }}
      </div>
      <textarea autofocus
        class="border w-full resize-none text-xs px-2 py-4 h-28 border-none outline-none"
        :placeholder="$t('add_legend')"
        v-model="legendContent"
      ></textarea>
      <div class="text-tenpix w-full flex justify-end" :class="legendContent.length > maxCharacter - 5 && legendContent.length !== maxCharacter?'text-yellow-400':''">
        <p :class="legendContent.length === maxCharacter?'font-bold	text-red-400':''">{{ legendContent.length }}/{{ maxCharacter }}</p>
      </div>
      <div
        class="flex justify-between w-full border-t border-tribugray-light mt-3 pt-3"
      >
        <button class="text-xs" @click="closeModale">{{ $t("cancel") }}</button>
        <button
          class="text-xs bg-tribugreen-light text-tribugreen-regular px-2 py-1 rounded-xl"
          @click="addCaption"
        >
          <p v-if="deleteLegend">{{ $t("delete") }}</p>
          <p v-if="editLegend && !deleteLegend">{{ $t("edit") }}</p>
          <p v-if="!deleteLegend && !editLegend">{{ $t("add") }}</p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Blocks } from "../../api"
export default {
  mounted(){
    if(this.computedContentLegend){
      this.legendContent = this.computedContentLegend
      this.editLegend = true
    }
    if(this.$store.state.isTinyBlock){
      this.maxCharacter = 20
    }
    this.top = this.$store.state.top;
    this.left = this.$store.state.left;
    this.scale = this.$store.state.scale;
  },
  data() {
    return {
      legendContent: "",
      maxCharacter: 100,
      top:null,
      left:null,
      scale:null,
      deleteLegend: false,
      editLegend:false
    };
  },
  watch: {
    
    legendContent(value, oldValue) {
      if (value.length > this.maxCharacter) {
        this.legendContent = oldValue;
      }
    if(value.length === 0 && oldValue.length !==0){
      this.deleteLegend = true
    }
    if(value.length !==0){
      this.deleteLegend = false
    }
    },
  },
  computed: {
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    computedContentLegend() {
      return this.$store.state.contentLegend;
    },
    blockOrder(){
      return this.$store.state.blockOrder;
    },
    pageObject() {
      for (let i = 0; i < this.currentJournal.pages.length; i++) {
        if (this.currentJournal.pages[i].order == this.$store.state.displayedPage.order)
          return this.currentJournal.pages[i];
      }
    },
    imageBlock() {
      if (this.pageObject.blocks.images) {
        if (this.pageObject.blocks.images.length > 0) {
          if (this.blockOrder) {
            return this.pageObject.blocks.images.find(
              (item) => item.order === this.blockOrder
            );
          }
        }
      }
    },
  },
  methods: {
    async addCaption() {
      const journalId = this.currentJournal.id;
      const blockId = this.imageBlock.block_id;
      const updatedAt = this.imageBlock.updated_at;
      const res = await Blocks.editLegend(
        journalId,
        blockId,
        this.legendContent,
        this.left,
        this.top,
        this.scale,
        updatedAt
      );
      let currentJournal = this.currentJournal;
      for(let i = 0; i < currentJournal.pages.length; i ++){
        for(let j=0; j <  currentJournal.pages[i].blocks.images.length;j++){
          if(currentJournal.pages[i].blocks.images[j].block_id === blockId){
            currentJournal.pages[i].blocks.images[j] = res
          }
        }
      }
      this.$store.commit("setCurrentJournal", currentJournal)
      
      this.$store.commit("setIsTinyBlock", false)
      this.$store.commit("setContentLegend", null);
      this.$store.commit("setImageBlock", null);
      this.legendContent = "";
      this.$store.commit("setMetaData", null);
      this.$store.commit("setShowModalLegend", false);
      this.$store.commit("setRemountCropper", true);
    },
    closeModale() {
      this.$store.commit("setImageBlock", null);
      this.legendContent = "";
      this.$store.commit("setShowModalLegend", false);
    },
  },
};
</script>