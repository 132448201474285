<template>
  <div
    class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center items-center -mt-10"
    style="z-index: 1000"
  >
    <div
      v-click-away="onClickAway"
      v-if="pageIsEmpty && !isAnImage && showMessage"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6"
    >
      <p>
        <b>{{ $t("empty_page_1") }}</b>
        <b>{{ $t("empty_page_2") }}</b>
      </p>
      <button
        class="bg-tribugreen-light text-tribugreen-regular w-full py-3 rounded-full mt-5 cursor-pointer"
        @click="setDeleteModal"
      >
        {{ $t("close") }}
      </button>
    </div>
    <div
      v-click-away="onClickAway"
      v-if="
        (!pageIsEmpty && isAnImage) ||
        (!pageIsEmpty && pageToDelete) ||
        (!pageIsEmpty && textToDelete)
      "
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6"
    >
      <p>
        <b v-if="isAnImage">{{ $t("delete_image_1") }}</b>
        <b v-if="pageToDelete">{{ $t("clear_page_1") }}</b>
        <b v-if="textToDelete">{{ $t("delete_text_1") }}</b>
      </p>
      <p>
        <b v-if="isAnImage">{{ $t("delete_image_2") }}</b>
        <b v-if="pageToDelete">{{ $t("clear_page_2") }}</b>
        <b v-if="textToDelete">{{ $t("delete_text_2") }}</b>
      </p>
      <p v-if="isAnImage" class="text-center mt-4" style="max-width:400px">
       {{ $t("delete_image_3") }}
      </p>
      <button
        @click="deleteItem()"
        class="bg-tribugreen-light text-tribugreen-regular w-full py-3 rounded-full mt-5 cursor-pointer"
      >
        <p v-if="isAnImage || textToDelete">{{ $t("delete") }}</p>
        <p v-if="pageToDelete">{{ $t("continue") }}</p>
      </button>
      <button class="mt-3 cursor-pointer" @click="setDeleteModal">
        {{ $t("cancel") }}
      </button>
    </div>
  </div>
</template>
<script>
import { Images, Blocks } from "../api";
import { directive } from "vue3-click-away";

export default {
  directives: {
    ClickAway: directive,
  },
  data() {
    return {
      showMessage: true,
    };
  },
  computed: {
    pageIsEmpty() {
      if (this.pageToDelete) {
        const page = this.currentJournal.pages.find(
          (item) => item.id === this.pageToDelete
        );

        return page.blocks.images.length > 0 || page.blocks.texts.length > 0
          ? false
          : true;
      }
    },
    pageToDelete() {
      return this.$store.state.pageToDelete;
    },
    imageToDelete() {
      return this.$store.state.imageToDelete;
    },
    textToDelete() {
      return this.$store.state.textToDelete;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    blockToDelete() {
      return this.$store.state.blockToDelete;
    },
    isAnImage() {
      return this.blockToDelete || this.imageToDelete ? true : false;
    },
  },
  methods: {
    onClickAway() {
      this.$store.commit("setOutsideDeleteModal", true);
      this.$store.commit("setImageToDelete", null);
      this.$store.commit("setPageToDelete", null);
      this.$store.commit("setBlockToDelete", null);
      this.$store.commit("setDeleteModal", false);
    },
    setDeleteModal() {
      this.$store.commit("setImageToDelete", null);
      this.$store.commit("setPageToDelete", null);
      this.$store.commit("setBlockToDelete", null);
      this.$store.commit("setDeleteModal", false);
      this.$store.commit("setOutsideDeleteModal", true);
    },
    async deleteItem() {
      if (this.imageToDelete) {
        this.$store.commit("RemoveImage", this.imageToDelete);
        this.$store.commit("setDeleteModal", false);
        this.$store.commit("setOutsideDeleteModal", true);
        await Images.deleteImage(this.imageToDelete);
        this.$store.commit("setImageToDelete", null);
      }
      if (this.textToDelete) {
        await Blocks.deleteBlock(this.currentJournal.id, this.textToDelete);
        let currentJournal = this.currentJournal;
        currentJournal.pages.forEach((element) => {
          for (let i = 0; i < element.blocks.texts.length; i++) {
            if (element.blocks.texts[i].block_id === this.textToDelete) {
              element.blocks.texts.splice(i, 1);
            }
          }
        });
        this.$store.commit("setCurrentJournal", this.currentJournal);
        this.$store.commit("setSelectedBlock", { id: null });
        this.$store.commit("setSelectedBlockOrder", null);
        this.$store.commit("setTextToDelete", null);
        this.$store.commit("setDeleteModal", false);
        this.$store.commit("setOutsideDeleteModal", true);
        this.$store.commit("setRemountBlockText",true)
      }
      if (this.blockToDelete) {
        await Blocks.deleteBlock(this.currentJournal.id, this.blockToDelete);
        this.$store.commit("setBlockToDelete", null);
        this.$store.commit("setDeleteModal", false);
        this.$store.commit("setOutsideDeleteModal", true);
      }

      if (this.pageToDelete) {
        this.showMessage = false;

        await Blocks.clearPage(this.currentJournal.id, this.pageToDelete);

        this.$store.commit("setPageToDelete", null);
        this.$store.commit("setDeleteModal", false);
        this.$store.commit("setOutsideDeleteModal", true);
      }
    },
  },
};
</script>
