<template>
  <div class="observer"></div>
</template>
<script>
export default {
  name: "observer",
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    const options = {
      rootMargin: "200px",
      threshold: 1.0,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    },options);
    this.observer.observe(this.$el);
  },
};
</script>
