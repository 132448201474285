import { createWebHistory, createRouter } from "vue-router";
import Workspace from "../components/Workspace.vue";
import Login from "../components/Login/Login.vue";
import SignUp from "../components/Login/SignUp.vue";
import ChangePassword from "../components/Login/ChangePassword.vue";
import ForgetPassword from "../components/Login/ForgetPassword.vue";
import Onboarding from "../components/Onboarding.vue"
import store from "../store";
import ExpiredScreen from "../components/ExpiredInvitationScreen.vue"
import axios from "axios";

import User from "../api/user";
import Journal from "../api/journal";
import Layout from "../api/layout";
import utils from "../utils";

const routes = [
  {
    path: "/",
    name: "Workspace",
    component: Workspace,
    beforeEnter: async (to, from, next) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramsharcover = urlParams.get("hardcover");
      const parmaslang = urlParams.get("lang");
      if(paramsharcover){
          localStorage.hardcover = true
      }
      if(parmaslang){
        localStorage.language = parmaslang
    }
      if(!localStorage.token){
        if(localStorage.code){
          next("/signup")
        }else{
          next("/login");
        }
       
      }else{
      if (localStorage.impersonate_token) {
        localStorage.removeItem("impersonate_token");
      }
      let isTokenExpired = function (token) {
        try {
          const expiry = JSON.parse(atob(token.split(".")[1])).exp;
          return Math.floor(new Date().getTime() / 1000) >= expiry;
        } catch (e) {
          setTimeout(
            store.commit("setErrorMessage", "error_connexion"),
            3000
          );
          localStorage.removeItem("token");
          next("/");
        }
      };
      if (localStorage.token) {
        const isExpired = isTokenExpired(localStorage.token);
        if (isExpired) {
          localStorage.removeItem("token");
          next("/");
        } else {
          if(localStorage.code){
            const headers = {
              'Authorization' : 'Bearer ' + localStorage.token,
              'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(import.meta.env.VITE_API_INVITE +"/"+localStorage.code, {headers})
            localStorage.removeItem("code");
            store.commit('setShowWelcomeMessage',true)
          }
          const user = await User.getUserInformations();
          await Layout.getAllLayouts();
          const res = await Journal.getCurrentJournal();
          store.commit("setCurrentJournal",res)
          if (store.state.loginMethod) {
            const tribe = store.state.userInfos.tribes.find(el => el.id === user.current_tribe)
            const eventName = store.state.hasSignup ? "sign_up" : "login";
            utils.event(eventName, {
              method: store.state.loginMethod,
              tribe_size: tribe.members.length,
            })
            store.state.hasSignup = false;
            store.state.loginMethod = null;
          }
        }
      }
      if(localStorage.code){
        const headers = {
          'Authorization' : 'Bearer ' + localStorage.token,
          'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.get(import.meta.env.f +"/"+localStorage.code, {headers})
        localStorage.removeItem("code");
        store.commit('setShowWelcomeMessage',true)
      }
      store.commit("setPreload",true)
      next();
    }
    },
  },
  {
    path: "/tribes/:tribeId/journals/:journalId",
    name: "WorkspaceAdmin",
    component: Workspace,
    beforeEnter: async (to, from, next) => {
      store.commit("setParamsTribe", to.params.tribeId);
      store.commit("setParamsJournal", to.params.journalId);
      if (to.params.tribeId) {

        let isTokenExpired = function (token) {
          try {
            const expiry = JSON.parse(atob(token.split(".")[1])).exp;
            return Math.floor(new Date().getTime() / 1000) >= expiry;
          } catch (e) {
            setTimeout(
              store.commit("setErrorMessage", "error_connexion"),
              3000
            );
            localStorage.removeItem("token");
            next("/");
          }
        };
        if (localStorage.token) {
          const isExpired = isTokenExpired(localStorage.token);
          if (isExpired) {
            localStorage.removeItem("token");
            next("/");
          } else {
        
            await User.getUserInformations(to.params.tribeId);
            await Layout.getAllLayouts();
            if (to.params.tribeId) {
             const res = await Journal.getSpecificJournal(
                to.params.tribeId,
                to.params.journalId
              );

              store.commit("setCurrentJournal",res)
            } else {
              const res = await Journal.getCurrentJournal();
              store.commit("setCurrentJournal",res)
            }

          }
        }
        store.commit("setPreload",true)
        next();
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (localStorage.impersonate_token) {
        localStorage.removeItem("impersonate_token");
      }
      next();
    },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    beforeEnter: async (to, from, next) => {
      if(localStorage.code){
        store.commit('setModaleInvitation',true)
      }
      next();
    }
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Onboarding,
    beforeEnter: async (to, from, next) => {
      if(!localStorage.token){
        next("/login")
      }
      next();
    }
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/invite/:code",
    name: "Invitation",
    component: Workspace,

    beforeEnter: async (to, from, next) => {
      const code =  to.params.code;
      const headers= {
        'Authorization' : 'Bearer ' + localStorage.token,
        'X-Requested-With': 'XMLHttpRequest'
      }
    try{
        const res = (await axios.get(import.meta.env.VITE_API_INVITE +"/"+code, {headers}))
        utils.event('tribe_joined', {tribe_id: res.data.id, tribe_size: res.data.tribe_size})
        store.commit('setShowWelcomeMessage',true)
        localStorage.code = code
        next("/")
      }catch(e){
        if(e.response.data.error ==="You must be logged in to accept an invitation."){
          localStorage.code = code
          next("/")
        }
        if(e.response.data.error ="Invitation not found"){
          next("/expired")

        }
        if(e.response.data.error ="Invitation expired"){
          next("/expired")
        }
      }

    },
  },
  {
    path: "/expired",
    name: "Expired",
    component: ExpiredScreen,
  },
  {
    path: "/import",
    name: "Import",
    component: Workspace,

    beforeEnter: async (to, from, next) => {
      localStorage.showImportPage = true
      store.commit('setShowImportPage',true)
      next("/")
    },
  },
  {
    path: "/clean",
    name: "Clean",
    beforeEnter: async (to, from, next) => {
      if(localStorage.token){
        localStorage.removeItem("token");
      }
      if(localStorage.code){
        localStorage.removeItem("code");
      }
      if(localStorage.showImportPage){
        localStorage.removeItem("showImportPage");
      }
      if(localStorage.guide){
        localStorage.removeItem("guide");
      }
      next("/")
    },
  },
  {
    path: "/logout",
    name: "Logout",

  },

];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
