<template>
  <div
    v-if="currentJournal.pages"
    :key="componentKey"
    :class="[
      selectedBlock === blockKey ? 'items-center' : '',
      !textBlock
        ? 'flex justify-center h-full'
        : 'flex justify-center items-center h-full',
      !isMiniature && !textBlock && selectedBlock !== blockKey
        ? 'blocktext-background'
        : '',
      isMiniature && !textBlock && selectedBlock !== blockKey
        ? 'blocktext-background mini'
        : '',
    ]"
    :style="!textBlock ? 'outline: 1px solid #E6E6E6;' : ''"
    class="text-image"
    @drop.stop.prevent
  >
  <div
      v-if="callIsSend"
      class="absolute w-full h-full flex justify-center items-center"
      style="z-index:300"
    >
    <!-- spinner html-css -->
    
      <div class="lds-spinner lds-spinner2" :style="isMiniature?'transform: scale(0.1);':'transform: scale(0.4);'"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div
      class="flex flex-col items-center justify-center"
      @click="setSelectedBlockText"
    >
      <QuillEditor
        id="newQuillEditor"
        v-if="!textBlock && !isMiniature"
        :toolbar="`#my-toolbar-${blockKey}`"
        class="z-30 overflow-hidden absolute overflow-y-auto scrollbarBlockText"
        v-model:content="textContent"
        spellcheck="false"
        contentType="html"
        style="color:#2F2F2F"
        :style="computedStyle"
        :class="selectedBlock === blockKey ? '' : 'opacity-0'"
        @paste="handlePaste" 
      >
        <template #toolbar>
          <div
            :id="`my-toolbar-${blockKey}`"
            class="flex"
            :style="selectedBlock === blockKey ? '' : 'visibility:hidden'"
            :class="blockIsAtTheTop?'toolbar-at-bottom':'toolbar-at-top'"
          >
            <div class="flex items-center cursor-pointer">
              <select v-model="textSize" class="ql-size">
                <option value="small">{{ $t("small") }}</option>
                <option value="" selected>{{ $t("medium") }}</option>
                <option value="large">{{ $t("large") }}</option>
                <option value="huge">{{ $t("very_large") }}</option>
              </select>
            </div>
            <div
              class="flex items-center"
              style="border-left: 1px solid #e6e6e6"
            >
              <button class="ql-bold cursor-pointer ml-2"></button>
              <button class="ql-italic cursor-pointer"></button>
              <button class="ql-underline cursor-pointer"></button>
            </div>
            <div
              class="flex items-center div-align"
              style="border-left: 1px solid #e6e6e6"
            >
              <select class="ql-align cursor-pointer" style="display:block!important">
                <option ></option>
                <option selected value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </div>
            <div
              @click="setBlockText();clickOnSave=true"
              class="cursor-pointer px-2 flex items-center hover:bg-white hover:rounded"
              style="border-left: 1px solid #e6e6e6"
            >
              <img class="w-3" src="../../assets/icons/save.svg" />
            </div>
            <div
              style="border-left: 1px solid #e6e6e6"
              @click="deleteText()"
              class="cursor-pointer px-2 flex items-center hover:bg-white hover:rounded"
            >
              <img class="w-3" src="../../assets/icons/trash-black.svg" />
            </div>
          </div>
        </template>
      </QuillEditor>
      <QuillEditor
        v-if="textBlock  && !isMiniature"
        :toolbar="`#my-toolbar-${blockKey}`"
        class="z-30 overflow-hidden absolute overflow-y-auto scrollbarBlockText"
        v-model:content="updateTextContent"
        spellcheck="false"
        contentType="html"
        style="color:#2F2F2F"
        :style="computedStyle"
        :class="selectedBlock === blockKey ? '' : 'opacity-0'"
        @paste="handlePaste" 
        
      >
    <template #toolbar>
          <div
            :id="`my-toolbar-${blockKey}`"
            class="flex"
            :style="selectedBlock === blockKey ? '' : 'visibility:hidden'"
            :key="componentKeyText"
            :class="blockIsAtTheTop?'toolbar-at-bottom':'toolbar-at-top'"
          >
            <div class="flex items-center cursor-pointer">
              <select v-model="textSize" class="ql-size">
                <option value="small">{{ $t("small") }}</option>
                <option value="" selected>{{ $t("medium") }}</option>
                <option value="large">{{ $t("large") }}</option>
                <option value="huge">{{ $t("very_large") }}</option>
              </select>
            </div>
            <div
              class="flex items-center"
              style="border-left: 1px solid #e6e6e6"
            >
              <button class="ql-bold cursor-pointer ml-2"></button>
              <button class="ql-italic cursor-pointer"></button>
              <button class="ql-underline cursor-pointer"></button>
            </div>
              
              <select class="ql-align cursor-pointer" style="padding-top:2px; display:block!important">
                <option selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            <div
              @click="setBlockText();clickOnSave=true"
              class="cursor-pointer px-2 flex items-center hover:bg-white hover:rounded"
              style="border-left: 1px solid #e6e6e6"
            >
              <img class="w-3" src="../../assets/icons/save.svg" />
            </div>
            <div
              style="border-left: 1px solid #e6e6e6"
              @click="deleteText()"
              class="cursor-pointer px-2 flex items-center hover:bg-white hover:rounded"
            >
              <img class="w-3" src="../../assets/icons/trash-black.svg" />
            </div>
          </div>
        </template>
      </QuillEditor>

      <img
        v-if="textBlock && selectedBlock !== blockKey"
        :src="textBlock.renderSrcSet.src"
        class="absolute z-10"
      />
    </div>
  </div>
</template>

<script>
import { Blocks, Journal } from "../../api";
import { QuillEditor } from "@vueup/vue-quill";
import FocusQuill from "quill-focus";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { directive } from "vue3-click-away";
export default {
  directives: {
    ClickAway: directive,
  },
  beforeMount() {
    if (this.textBlock) {
      if (!this.isMiniature) {
        this.mapToVueQuill();
      }
    }
  },
  mounted(){
     this.overrideCssSize()   
     this.isMounted =  true 
     const align = document.querySelector(".ql-align")
     if(align){
      if(align.style.display === 'none'){
        align.style.display = "block"
      }
     }

  },
  props: {
    journalsize: Object,
    isMiniature: Boolean,
    block: Object,
    page: Number,
  },
  data() {
    return {
      callIsSend:false,
      componentKey: 0,
      emptyBlockKey: 0,
      textContent: null,
      modules: {
        name: "Focus",
        module: FocusQuill,
      },
      isDeleted: false,
      selectedSize: this.$t("medium"),
      miniatureWidth: 51,
      emptyBlock: false,
      updateTextContent: null,
      content: false,
      outsideBlock: true,
      toolkey: 0,
      textSize:'',
      isMounted:false,
      componentKeyText:0,
      clickOnSave:false,
    };
  },
  components: {
    QuillEditor,
  },
  watch: {
    textSize(value, oldValue){ 
      if(value !== oldValue){ 
        this.overrideCssSize()
      }
    },

    remountBlockText() {
      if (this.remountBlockText && !this.isMiniature) {
        this.componentKeyText = !this.componentKeyText;
      }
    },
    textContent(value, oldvalue) {
      if (value !== null) {
        this.content = true;
      }
      if (this.textContent) {
        if (this.textContent.includes("<img")) {
          this.textContent = null;
        }
      }
    },
    updateTextContent() {
      if (this.updateTextContent) {
        if (this.updateTextContent.includes("<img")) {
          this.updateTextContent = null;
        }
      }
    },

    selectedBlock(value, oldvalue) {
      if (!this.isMiniature) {
       if (value !== oldvalue && value === this.blockKey) {
          this.overrideCssSize()
           const quillEditorElement = document.querySelector('.ql-editor p')
          if(quillEditorElement.innerHTML === '<br>'){
            quillEditorElement.classList.add("ql-align-center");
          }
        }
        if (value !== oldvalue && oldvalue === this.blockKey) {
          this.setBlockText();
        }
        if (value === this.blockKey) {
          const span = document.querySelectorAll('.div-align > span')

         if(span.length > 1){
            span[0].remove()
            span[1].style.display = 'block'
          }
        }
      }
    },
    textBlock() {
      if (this.textBlock) {
        this.mapToVueQuill();
      }
    },
    removeText() {
      if (this.removeText) {
        this.textContent = null;
        this.$store.commit("setSelectedBlock", { id: null });
        this.$store.commit("setSelectedBlockOrder", null);
        this.emptyBlockKey = !this.emptyBlockKey;
      }
    },
  },
  computed: {
    blockIsAtTheTop(){
      let diff;
      switch (this.pageSize) {
        case 'small':
          diff = 11;
          break;
        case 'medium':
          diff = 16;
          break;
        case 'big':
          diff = 23;
          break;
        case 'huge':
          diff = 26;
          break;
        default:
          diff = 0;
      }
      return (this.journalSize.height/2) < (this.positionBlock.top + diff)? false : true
    },

    positionBlock(){
      const { position } = this.block;
      const [maxHeight, maxWidth] = [
        this.journalSize.height,
        this.journalSize.width,
      ];
      const styles = {
        top: this.calculateSize(position.y, maxHeight),
        left: this.calculateSize(position.x, maxWidth)
      };
      return styles;
    },
    blockQuillExist(){
      return document.querySelector("#newQuillEditor")?true:false

    },
    miniatureHeight(){
      if(this.currentJournal.type === 'kids'){
        return 51
      }else{
        return 70
      }
     } ,
    pageSize() {
      return this.$store.state.pageSize;
    },
    removeText() {
      return this.$store.state.removeText;
    },
    miniature() {
      return this.isMiniature;
    },
    remountBlockText() {
      return this.$store.state.remountBlockText;
    },
    journalSize() {
      return this.$store.state.journalSize;
    },
    blockKey() {
      const pageId = this.pageObject.id;
      const blockId = this.block.id;
      const order = this.block.order;
      return `${pageId}-${blockId}-${order}`;
    },
    pageObject() {
      for (let i = 0; i < this.currentJournal.pages.length; i++) {
        if (this.currentJournal.pages[i].order == this.page)
          return this.currentJournal.pages[i];
      }
    },
    textBlock() {
      if (this.pageObject.blocks.texts) {
        if (this.pageObject.blocks.texts.length > 0) {
          const textBlock = this.pageObject.blocks.texts.find(
            (item) => item.order === this.block.order
          );
          if (textBlock) {
            return textBlock;
          }
        }
      }
    },
    selectedBlock() {
      return this.$store.state.selectedBlock;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    computedStyle() {
      if (this.miniature) {
        const { position, size } = this.block;
        const [maxHeight, maxWidth] = this.currentJournal.type !== kids ?[74, 52] : [52, 52];
        const styles = {
          height: `${this.calculateSize(size.height, maxHeight)}px`,
          left: `${this.calculateSize(position.x, maxWidth)}px`,
          top: `${this.calculateSize(position.y, maxHeight)}px`,
          width: `${this.calculateSize(size.width, maxWidth)}px`,
        };
        return styles;
      } else {
        const { size } = this.block;
        const [maxHeight, maxWidth] = [
          this.journalSize.height,
          this.journalSize.width,
        ];
        const styles = {
          maxHeight: `${this.calculateSize(size.height, maxHeight)}px`,
          width: `${this.calculateSize(size.width, maxWidth)}px`,
          border: "none",
        };
        return styles;
      }
    },
    blockTextIsSelected() {
      return this.$store.state.blockTextIsSelected;
    },
  },
  methods: {
   handlePaste(event) {
      // Annuler le comportement par défaut pour éviter que le HTML ne soit collé
      event.preventDefault();
      // Obtenir le texte brut du presse-papiers
      const pastedText = (event.clipboardData || window.clipboardData).getData('text/plain');
      // Supprimer les balises HTML et styles du texte brut
      const sanitizedText = this.sanitizeHtml(pastedText);
      // Coller le texte brut sans balises dans l'élément contenteditable
      document.execCommand('insertText', false, sanitizedText);
    },
    sanitizeHtml(html) {
      // Créer un élément temporaire pour traiter le HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Extraire le texte brut de l'élément temporaire
      const plainText = tempDiv.innerText || tempDiv.textContent;

      // Supprimer l'élément temporaire
      tempDiv.remove();

      return plainText;
    },
    onDrop(e) {
      console.log(e);
    },
    onClickAway() {
      this.$store.commit("setSelectedBlock", { id: null });
      this.$store.commit("setSelectedBlockOrder", this.block.order);
    },
    setSelectedBlockText() {
      if (!this.isMiniature) {
        this.$store.commit("setBlockTextIsSelected", true);
        this.$store.commit("setSelectedBlock", this.blockKey);
        this.$store.commit("setSelectedBlockOrder", this.block.order);
      }
    },
    calculateSize(value, max) {
      return (max * (value * 100)) / 100;
    },

    mapToVueQuill() {
      let content = this.textBlock.content;
      if(content.includes('class=" ')){
          content = content.replaceAll('class=" ', 'class="');
      }
      if (content.includes("<div")) {
        content = content.replaceAll("<div", "<p");
      }
      if (content.includes("</div>")) {
        content = content.replaceAll("</div>", "</p>");
      }
      if (content.includes('style="text-align: center;"')) {
        content = content.replaceAll(
          'style="text-align: center;"',
          'class="ql-align-center"'
        );
      }
      if (content.includes('style="text-align: center"')) {
        content = content.replaceAll(
          'style="text-align: center"',
          'class="ql-align-center"'
        );
      }
      if (content.includes('style="text-align: right"')) {
        content = content.replaceAll(
          'style="text-align: right"',
          'class="ql-align-right"'
        );
      }
      if (content.includes('style="text-align: justify"')) {
        content = content.replaceAll(
          'style="text-align: justify"',
          'class="ql-align-justify"'
        );
      }
      if (content.includes("</font>")) {
        content = content.replaceAll("</font>", "</span>");
      }
      if (content.includes("<b>")) {
        content = content.replaceAll("<b>", "<strong>");
      }
      if (content.includes('<b><font size="1">')) {
        content = content.replaceAll(
          '<b><font size="1">',
          '<strong class="ql-size-small">'
        );
        this.textSize = 'small'
      }
      if (content.includes('<b><font size="5">')) {
        content = content.replaceAll(
          '<b><font size="5">',
          '<strong class="ql-size-large">'
        );
        this.textSize = 'large'
      }
      if (content.includes('<b><font size="7">')) {
        content = content.replaceAll(
          '<b><font size="7">',
          '<strong class="ql-size-huge">'
        );
        this.textSize = 'huge'
      }
      if (content.includes('<u><font size="1">')) {
        content = content.replaceAll(
          '<u><font size="1">',
          '<u class="ql-size-small">'
        );
        this.textSize = 'small'
      }
      if (content.includes('<u><font size="5">')) {
        content = content.replaceAll(
          '<u><font size="5">',
          '<u class="ql-size-large">'
        );
        this.textSize = 'large'
      }
      if (content.includes('<u><font size="7">')) {
        content = content.replaceAll(
          '<u><font size="7">',
          '<u class="ql-size-huge">'
        );
        this.textSize = 'huge'
      }
      if (content.includes("<i>")) {
        content = content.replaceAll("<i>", "<em>");
      }
      if (content.includes('<i><font size="1">')) {
        content = content.replaceAll(
          '<i><font size="1">',
          '<em class="ql-size-small">'
        );
        this.textSize = 'small'
      }
      if (content.includes('<i><font size="5">')) {
        content = content.replaceAll(
          '<i><font size="5">',
          '<em class="ql-size-large">'
        );
        this.textSize = 'large'
      }
      if (content.includes('<i><font size="7">')) {
        content = content.replaceAll(
          '<i><font size="7">',
          '<em class="ql-size-huge">'
        );
        this.textSize = 'huge'
      }
      if (content.includes('<font size="1">')) {
        content = content.replaceAll(
          '<font size="1">',
          '<span class="ql-size-small">'
        );
        this.textSize = 'small'
      }
      if (content.includes('<font size="5">')) {
        content = content.replaceAll(
          '<font size="5">',
          '<span class="ql-size-large">'
        );
        this.textSize = 'large'
      }
      if (content.includes('<font size="7">')) {
        content = content.replaceAll(
          '<font size="7">',
          '<span class="ql-size-huge">'
        );
        this.textSize = 'huge'
      }
      if (content.includes("</font></b>")) {
        content = content.replaceAll("</font></b>", "</strong>");
      }
      if (content.includes("</font></u>")) {
        content = content.replaceAll("</font></u>", "</u>");
      }
      if (content.includes("</font></i>")) {
        content = content.replaceAll("</font></i>", "</i>");
      }
      if (content.includes("</b>")) {
        content = content.replaceAll("</b>", "</strong>");
      }
      if (content.includes("</i>")) {
        content = content.replaceAll("</i>", "</em>");
      }
      this.updateTextContent = content;
    },
    async setBlockText() {
      this.callIsSend = true
      this.$store.commit("setRemoveText", false);
      let content = this.textBlock ? this.updateTextContent : this.textContent;

      if (content) {
        if (content.includes("smallScreenSmallTypoKids")) {
          content = content.replaceAll("smallScreenSmallTypoKids", "");
        }
        if (content.includes("smallScreenRegularTypoKids")) {
          content = content.replaceAll("smallScreenRegularTypoKids", "");
        }
        if (content.includes("smallScreenBigTypoKids")) {
          content = content.replaceAll("smallScreenBigTypoKids", "");
        }
        if (content.includes("smallScreenHugeTypoKids")) {
          content = content.replaceAll("smallScreenHugeTypoKids", "");
        }    
        if (content.includes("mediumScreenSmallTypoKids")) {
          content = content.replaceAll("mediumScreenSmallTypoKids", "");
        }
        if (content.includes("mediumScreenRegularTypoKids")) {
          content = content.replaceAll("mediumScreenRegularTypoKids", "");
        }
        if (content.includes("mediumScreenBigTypoKids")) {
          content = content.replaceAll("mediumScreenBigTypoKids", "");
        }
        if (content.includes("mediumScreenHugeTypoKids")) {
          content = content.replaceAll("mediumScreenHugeTypoKids", "");
        }  
        if (content.includes("bigScreenSmallTypoKids")) {
          content = content.replaceAll("bigScreenSmallTypoKids", "");
        }  
        if (content.includes("bigScreenRegularTypoKids")) {
          content = content.replaceAll("bigScreenRegularTypoKids", "");
        }  
        if (content.includes("bigScreenBigTypoKids")) {
          content = content.replaceAll("bigScreenBigTypoKids", "");
        }   
        if (content.includes("bigScreenHugeTypoKids")) {
          content = content.replaceAll("bigScreenHugeTypoKids", "");
        }  
        if (content.includes("smallScreenSmallTypo")) {
          content = content.replaceAll("smallScreenSmallTypo", "");
        }
        if (content.includes("smallScreenRegularTypo")) {
          content = content.replaceAll("smallScreenRegularTypo", "");
        }
        if (content.includes("smallScreenBigTypo")) {
          content = content.replaceAll("smallScreenBigTypo", "");
        }
        if (content.includes("smallScreenHugeTypo")) {
          content = content.replaceAll("smallScreenHugeTypo", "");
        }    
        if (content.includes("mediumScreenSmallTypo")) {
          content = content.replaceAll("mediumScreenSmallTypo", "");
        }
        if (content.includes("mediumScreenRegularTypo")) {
          content = content.replaceAll("mediumScreenRegularTypo", "");
        }
        if (content.includes("mediumScreenBigTypo")) {
          content = content.replaceAll("mediumScreenBigTypo", "");
        }
        if (content.includes("mediumScreenHugeTypo")) {
          content = content.replaceAll("mediumScreenHugeTypo", "");
        }  
        if (content.includes("bigScreenSmallTypo")) {
          content = content.replaceAll("bigScreenSmallTypo", "");
        }  
        if (content.includes("bigScreenRegularTypo")) {
          content = content.replaceAll("bigScreenRegularTypo", "");
        }  
        if (content.includes("bigScreenBigTypo")) {
          content = content.replaceAll("bigScreenBigTypo", "");
        }   
        if (content.includes("bigScreenHugeTypo")) {
          content = content.replaceAll("bigScreenHugeTypo", "");
        }   


  
        if(content.includes('class=" ql-size-small')){
          content = content.replaceAll('class=" ql-size-small', 'class="ql-size-small');
        }
        if(content.includes('class=" ql-size-large')){

          content = content.replaceAll('class=" ql-size-large', 'class="ql-size-large');
        }
        if(content.includes('class=" ql-size-huge')){
          content = content.replaceAll('class=" ql-size-huge', 'class="ql-size-huge');
        }   
        if(content.includes('class="ql-size-small ')){
          content = content.replaceAll('class="ql-size-small ', 'class="ql-size-small');
        }
        if(content.includes('class="ql-size-large ')){

          content = content.replaceAll('class="ql-size-large ', 'class="ql-size-large');
        }
        if(content.includes('class="ql-size-huge ')){
          content = content.replaceAll('class="ql-size-huge ', 'class="ql-size-huge');
        }
        if (content.includes("<p")) {
          content = content.replaceAll("<p", "<div");
        }
        if (content.includes("</p>")) {
          content = content.replaceAll("</p>", "</div>");
        }
        if (content.includes("</span>")) {
          content = content.replaceAll("</span>", "</font>");
        }

        if (content.includes('<span class="ql-cursor"></span>')) {
          content = content.replaceAll('<span class="ql-cursor"></span>', "");
        }
        if (content.includes('<span class="ql-size-small">')) {
          content = content.replaceAll(
            '<span class="ql-size-small">',
            '<font size="1">'
          );
        }
        if (content.includes('<span class="ql-size-large">')) {
          content = content.replaceAll(
            '<span class="ql-size-large">',
            '<font size="5">'
          );
        }
        if (content.includes('<span class="ql-size-huge">')) {
          content = content.replaceAll(
            '<span class="ql-size-huge">',
            '<font size="7">'
          );
        }
        if (
          content.includes('<strong class="ql-size-small">') &&
          content.includes("</strong>")
        ) {
          content = content.replaceAll("</strong>", "</font></b>");
        }
        if (content.includes('<strong class="ql-size-small">')) {
          content = content.replaceAll(
            '<strong class="ql-size-small">',
            '<b><font size="1">'
          );
        }
        if (
          content.includes('<strong class="ql-size-large">') &&
          content.includes("</strong>")
        ) {
          content = content.replaceAll("</strong>", "</font></b>");
        }
        if (content.includes('<strong class="ql-size-large">')) {
          content = content.replaceAll(
            '<strong class="ql-size-large">',
            '<b><font size="5">'
          );
        }
        if (
          content.includes('<strong class="ql-size-huge">') &&
          content.includes("</strong>")
        ) {
          content = content.replaceAll("</strong>", "</font></b>");
        }
        if (content.includes('<strong class="ql-size-huge">')) {
          content = content.replaceAll(
            '<strong class="ql-size-huge">',
            '<b><font size="7">'
          );
        }
        if (content.includes("<strong>")) {
          content = content.replaceAll("<strong>", "<b>");
        }
        if (content.includes("<em>")) {
          content = content.replaceAll("<em>", "<i>");
        }
        if (
          content.includes('<em class="ql-size-small">') &&
          content.includes("</em>")
        ) {
          content = content.replaceAll("</em>", "</font></i>");
        }
        if (content.includes('<em class="ql-size-small">')) {
          content = content.replaceAll(
            '<em class="ql-size-small">',
            '<i><font size="1">'
          );
        }
        if (
          content.includes('<em class="ql-size-large">') &&
          content.includes("</em>")
        ) {
          content = content.replaceAll("</em>", "</font></i>");
        }
        if (content.includes('<em class="ql-size-large">')) {
          content = content.replaceAll(
            '<em class="ql-size-large">',
            '<i><font size="5">'
          );
        }
        if (
          content.includes('<em class="ql-size-huge">') &&
          content.includes("</em>")
        ) {
          content = content.replaceAll("</em>", "</font></i>");
        }
        if (content.includes('<em class="ql-size-huge">')) {
          content = content.replaceAll(
            '<em class="ql-size-huge">',
            '<i><font size="7">'
          );
        }
        if (
          content.includes('<u class="ql-size-small">') &&
          content.includes("</u>")
        ) {
          content = content.replaceAll("</u>", "</font></u>");
        }
        if (content.includes('<u class="ql-size-small">')) {
          content = content.replaceAll(
            '<u class="ql-size-small">',
            '<u><font size="1">'
          );
        }
        if (
          content.includes('<u class="ql-size-large">') &&
          content.includes("</u>")
        ) {
          content = content.replaceAll("</u>", "</font></u>");
        }
        if (content.includes('<u class="ql-size-large">')) {
          content = content.replaceAll(
            '<u class="ql-size-large">',
            '<u><font size="5">'
          );
        }
        if (
          content.includes('<u class="ql-size-huge">') &&
          content.includes("</u>")
        ) {
          content = content.replaceAll("</u>", "</font></u>");
        }
        if (content.includes('<u class="ql-size-huge">')) {
          content = content.replaceAll(
            '<u class="ql-size-huge">',
            '<u><font size="7">'
          );
        }
        if (content.includes('class="ql-align-center"')) {
          content = content.replaceAll(
            'class="ql-align-center"',
            'style="text-align: center"'
          );
        }

        if (content.includes('class="ql-align-right"')) {
          content = content.replaceAll(
            'class="ql-align-right"',
            'style="text-align: right"'
          );
        }
        if (content.includes('class="ql-align-justify"')) {
          content = content.replaceAll(
            'class="ql-align-justify"',
            'style="text-align: justify"'
          );
        }
        if (content.includes("</strong>")) {
          content = content.replaceAll("</strong>", "</b>");
        }
        if (content.includes("</em>")) {
          content = content.replaceAll("</em>", "</i>");
        }
        
        if (!this.textBlock && !this.removeText && !this.clickOnSave) {
          const body = {
            content: content,
            page_id: this.pageObject.id,
            order: this.block.order,
          };
          
          const res = await Blocks.insertTextBlock(body,this.block.order);
          let currentJournal = this.currentJournal;
          currentJournal.pages[this.page - 1].blocks.texts.push(res);
          this.$store.commit("setCurrentJournal", currentJournal);
          this.$store.commit("setSelectedBlock", { id: null });
          this.$store.commit("setSelectedBlockOrder", null);
          this.componentKey = !this.componentKey;
          this.toolkey = !this.toolkey;
        }
        if (this.textBlock) {
          const journalId = this.currentJournal.id;
          const blockId = this.textBlock.block_id;
          const updatedAt = this.textBlock.updated_at;
          if (
            this.updateTextContent === '<p class="ql-align-right"><br></p>' ||
            this.updateTextContent === '<p class="ql-align-justify"><br></p>' ||
            this.updateTextContent === '<p class="ql-align-center"><br></p>' ||
            this.updateTextContent === "<p><br></p>"
          ) {
            await Blocks.deleteBlock(journalId, blockId);
            const currentJournal = this.currentJournal;
            const orginalArray =
              currentJournal.pages[this.page - 1].blocks.texts;
            let newArray = [];

            for (let i = 0; i < orginalArray.length; i++) {
              if (orginalArray[i].order !== this.block.order) {
                newArray.push(orginalArray[i]);
              }
            }
            currentJournal.pages[this.page - 1].blocks.texts = newArray;
            this.$store.commit("setCurrentJournal", currentJournal);
            this.updateTextContent = null;
            this.textContent = null;
            this.componentKey = !this.componentKey;
          } else {
            const res = await Blocks.updateTextBlock(
              journalId,
              blockId,
              content,
              updatedAt,
              this.block.order
            );
            this.$store.commit("setSelectedBlock", { id: null });
            this.$store.commit("setSelectedBlockOrder", null);
            this.componentKey = !this.componentKey;
            this.toolkey = !this.toolkey;
          }
        }
      } else {
        if (this.textContent || this.updateTextContent) {
          if (this.textBlock) {
            const journalId = this.currentJournal.id;
            const blockId = this.textBlock.block_id;
            this.$store.commit("setSelectedBlock", { id: null });
            this.$store.commit("setSelectedBlockOrder", null);
            await Blocks.deleteBlock(journalId, blockId);
            await Journal.getCurrentJournal();
            this.componentKey = !this.componentKey;
          }
        }
      }
    },
    async deleteText() {
      if (this.textBlock) {
        const journalId = this.currentJournal.id;
        const blockId = this.textBlock.block_id;
        this.$store.commit("setTextToDelete", blockId);
        this.$store.commit("setDeleteModal", true);
        this.$store.commit("setOutsideDeleteModal", false);
      } else {
        //if textContent is not null but the block doesn't exist, we can't use ModaleDelete(need block-id)
        this.$store.commit("setDeleteModalTextArea", true);
      }
    },
  overrideCssSize(){
      if(!this.isMiniature){
        if(document.getElementsByClassName('ql-editor').length > 0){
      if(this.pageSize === 'small'){
        let qlEditor = document.getElementsByClassName('ql-editor')
        if(qlEditor[0]){
            if(qlEditor[0].classList.contains("smallScreenSmallTypoKids")){
             qlEditor[0].classList.remove("smallScreenSmallTypoKids");
           }
           if(qlEditor[0].classList.contains("smallScreenBigTypoKids")){
             qlEditor[0].classList.remove("smallScreenBigTypoKids");
           }
          if(qlEditor[0].classList.contains("smallScreenHugeTypoKids")){
             qlEditor[0].classList.remove("smallScreenHugeTypoKids");
           }
           if(qlEditor[0].classList.contains("smallScreenSmallTypo")){
             qlEditor[0].classList.remove("smallScreenSmallTypo");
           }
           if(qlEditor[0].classList.contains("smallScreenBigTypo")){
             qlEditor[0].classList.remove("smallScreenBigTypo");
           }
          if(qlEditor[0].classList.contains("smallScreenHugeTypo")){
             qlEditor[0].classList.remove("smallScreenHugeTypo");
           }

        if(this.currentJournal.type !=='kids'){
          qlEditor[0].classList.add("smallScreenRegularTypo")   

        }
        if(this.currentJournal.type ==='kids'){
          qlEditor[0].classList.add("smallScreenRegularTypoKids")   

        }
        }
       if(this.textSize === 'small' && this.currentJournal.type !=='kids'){
          let qlSizeSmall = document.getElementsByClassName('ql-size-small')
          if(qlSizeSmall[0]){
          if(qlSizeSmall[0].classList.contains("smallScreenRegularTypo")){
             qlSizeSmall[0].classList.remove("smallScreenRegularTypo");
           }
           if(qlSizeSmall[0].classList.contains("smallScreenBigTypo")){
             qlSizeSmall[0].classList.remove("smallScreenBigTypo");
           }
          if(qlSizeSmall[0].classList.contains("smallScreenHugeTypo")){
             qlSizeSmall[0].classList.remove("smallScreenHugeTypo");
           }
          qlSizeSmall[0].classList.add("smallScreenSmallTypo")

       }
       }
        if(this.textSize === 'small' && this.currentJournal.type ==='kids'){
          let qlSizeSmall = document.getElementsByClassName('ql-size-small')
            if(qlSizeSmall[0]){
            if(qlSizeSmall[0].classList.contains("smallScreenRegularTypoKids")){
             qlSizeSmall[0].classList.remove("smallScreenRegularTypoKids");
           }
           if(qlSizeSmall[0].classList.contains("smallScreenBigTypoKids")){
             qlSizeSmall[0].classList.remove("smallScreenBigTypoKids");
           }
          if(qlSizeSmall[0].classList.contains("smallScreenHugeTypoKids")){
             qlSizeSmall[0].classList.remove("smallScreenHugeTypoKids");
           }
          qlSizeSmall[0].classList.add("smallScreenSmallTypoKids")

          }
        }
        if(this.textSize === 'large'  && this.currentJournal.type !=='kids'){
          let qlSizeLarge = document.getElementsByClassName('ql-size-large')
          if(qlSizeLarge[0]){
          if(qlSizeLarge[0].classList.contains("smallScreenSmallTypo")){
             qlSizeLarge[0].classList.remove("smallScreenSmallTypo");
           }
          if(qlSizeLarge[0].classList.contains("smallScreenRegularTypo")){
             qlSizeLarge[0].classList.remove("smallScreenRegularTypo");
           }
          if(qlSizeLarge[0].classList.contains("smallScreenHugeTypo")){
             qlSizeLarge[0].classList.remove("smallScreenHugeTypo");
           }
            qlSizeLarge[0].classList.add("smallScreenBigTypo")
            }
        }
        if(this.textSize === 'large'  && this.currentJournal.type ==='kids'){
          let qlSizeLarge = document.getElementsByClassName('ql-size-large')
          if(qlSizeLarge[0]){
          if(qlSizeLarge[0].classList.contains("smallScreenSmallTypoKids")){
             qlSizeLarge[0].classList.remove("smallScreenSmallTypoKids");
           }
          if(qlSizeLarge[0].classList.contains("smallScreenRegularTypoKids")){
             qlSizeLarge[0].classList.remove("smallScreenRegularTypoKids");
           }
          if(qlSizeLarge[0].classList.contains("smallScreenHugeTypoKids")){
             qlSizeLarge[0].classList.remove("smallScreenHugeTypoKids");
           }
          qlSizeLarge[0].classList.add("smallScreenBigTypoKids")
          }
        }
        
        if(this.textSize === 'huge'&& this.currentJournal.type !=='kids'){
          let qlSizeHuge = document.getElementsByClassName('ql-size-huge')
          if(qlSizeHuge[0]){
          if(qlSizeHuge[0].classList.contains("smallScreenSmallTypo")){
             qlSizeHuge[0].classList.remove("smallScreenSmallTypo");
           }
          if(qlSizeHuge[0].classList.contains("smallScreenRegularTypo")){
             qlSizeHuge[0].classList.remove("smallScreenRegularTypo");
           }
          if(qlSizeHuge[0].classList.contains("smallScreenBigTypo")){
             qlSizeHuge[0].classList.remove("smallScreenBigTypo");
           }
           qlSizeHuge[0].classList.add("smallScreenHugeTypo")
           }
        } if(this.textSize === 'huge'&& this.currentJournal.type ==='kids'){
           let qlSizeHuge = document.getElementsByClassName('ql-size-huge')
           if(qlSizeHuge[0]){
          if(qlSizeHuge[0].classList.contains("smallScreenSmallTypoKids")){
             qlSizeHuge[0].classList.remove("smallScreenSmallTypoKids");
           }
          if(qlSizeHuge[0].classList.contains("smallScreenRegularTypoKids")){
             qlSizeHuge[0].classList.remove("smallScreenRegularTypoKids");
           }
          if(qlSizeHuge[0].classList.contains("smallScreenBigTypoKids")){
             qlSizeHuge[0].classList.remove("smallScreenBigTypoKids");
           }
          qlSizeHuge[0].classList.add("smallScreenHugeTypoKids")
          }
        }
      }
   if(this.pageSize === 'medium'){
        let qlEditor = document.getElementsByClassName('ql-editor')
        if(qlEditor[0]){
          if(qlEditor[0].classList.contains("mediumScreenSmallTypoKids")){
             qlEditor[0].classList.remove("mediumScreenSmallTypoKids");
           }
           if(qlEditor[0].classList.contains("mediumScreenBigTypoKids")){
             qlEditor[0].classList.remove("mediumScreenBigTypoKids");
           }
          if(qlEditor[0].classList.contains("mediumScreenHugeTypoKids")){
             qlEditor[0].classList.remove("mediumScreenHugeTypoKids");
           }
          if(qlEditor[0].classList.contains("mediumScreenSmallTypo")){
             qlEditor[0].classList.remove("mediumScreenSmallTypo");
           }
           if(qlEditor[0].classList.contains("mediumScreenBigTypo")){
             qlEditor[0].classList.remove("mediumScreenBigTypo");
           }
          if(qlEditor[0].classList.contains("mediumScreenHugeTypo")){
             qlEditor[0].classList.remove("mediumScreenHugeTypo");
           }  

           if(this.currentJournal.type !=='kids'){
             qlEditor[0].classList.add("mediumScreenRegularTypo")    

           }
          if(this.currentJournal.type ==='kids'){
             qlEditor[0].classList.add("mediumScreenRegularTypoKids")    

           }
           }
        if(this.textSize === 'small'&& this.currentJournal.type !=='kids'){
        let qlSizeSmall = document.getElementsByClassName('ql-size-small')
        if(qlSizeSmall[0]){
          if(qlSizeSmall[0].classList.contains("mediumScreenRegularTypo")){
             qlSizeSmall[0].classList.remove("mediumScreenRegularTypo");
           }
           if(qlSizeSmall[0].classList.contains("mediumScreenBigTypo")){
             qlSizeSmall[0].classList.remove("mediumScreenBigTypo");
           }
          if(qlSizeSmall[0].classList.contains("mediumScreenHugeTypo")){
             qlSizeSmall[0].classList.remove("mediumScreenHugeTypo");
           }
            qlSizeSmall[0].classList.add("mediumScreenSmallTypo")
           } 
           }
           if(this.textSize === 'small'&& this.currentJournal.type ==='kids'){
             let qlSizeSmall = document.getElementsByClassName('ql-size-small')
             if(qlSizeSmall[0]){
          if(qlSizeSmall[0].classList.contains("mediumScreenRegularTypoKids")){
             qlSizeSmall[0].classList.remove("mediumScreenRegularTypoKids");
           }
           if(qlSizeSmall[0].classList.contains("mediumScreenBigTypoKids")){
             qlSizeSmall[0].classList.remove("mediumScreenBigTypoKids");
           }
          if(qlSizeSmall[0].classList.contains("mediumScreenHugeTypoKids")){
             qlSizeSmall[0].classList.remove("mediumScreenHugeTypoKids");
           }
        qlSizeSmall[0].classList.add("mediumScreenSmallTypoKids")
        }
        }
        if(this.textSize === 'large'&& this.currentJournal.type !=='kids'){
        let qlSizeLarge = document.getElementsByClassName('ql-size-large')
        if(qlSizeLarge[0]){
          if(qlSizeLarge[0].classList.contains("mediumScreenSmallTypo")){
             qlSizeLarge[0].classList.remove("mediumScreenSmallTypo");
           }
          if(qlSizeLarge[0].classList.contains("mediumScreenRegularTypo")){
             qlSizeLarge[0].classList.remove("mediumScreenRegularTypo");
           }

          if(qlSizeLarge[0].classList.contains("mediumScreenHugeTypo")){
             qlSizeLarge[0].classList.remove("mediumScreenHugeTypo");
           }
            qlSizeLarge[0].classList.add("mediumScreenBigTypo")
        }
        }
        if(this.textSize === 'large'&& this.currentJournal.type ==='kids'){
          
            let qlSizeLarge = document.getElementsByClassName('ql-size-large')
            if(qlSizeLarge[0]){
          if(qlSizeLarge[0].classList.contains("mediumScreenSmallTypoKids")){
             qlSizeLarge[0].classList.remove("mediumScreenSmallTypoKids");
           }
          if(qlSizeLarge[0].classList.contains("mediumScreenRegularTypoKids")){
             qlSizeLarge[0].classList.remove("mediumScreenRegularTypoKids");
           }

          if(qlSizeLarge[0].classList.contains("mediumScreenHugeTypoKids")){
             qlSizeLarge[0].classList.remove("mediumScreenHugeTypoKids");
           }
        qlSizeLarge[0].classList.add("mediumScreenBigTypoKids")
        }
        }
        if(this.textSize === 'huge'&& this.currentJournal.type !=='kids'){
        let qlSizeHuge = document.getElementsByClassName('ql-size-huge')
        if(qlSizeHuge[0]){
          if(qlSizeHuge[0].classList.contains("mediumScreenSmallTypo")){
             qlSizeHuge[0].classList.remove("mediumScreenSmallTypo");
           }
          if(qlSizeHuge[0].classList.contains("mediumScreenRegularTypo")){
             qlSizeHuge[0].classList.remove("mediumScreenRegularTypo");
           }

          if(qlSizeHuge[0].classList.contains("mediumScreenBigTypo")){
             qlSizeHuge[0].classList.remove("mediumScreenBigTypo");
           }
           qlSizeHuge[0].classList.add("mediumScreenHugeTypo")
        } 
        }
        if(this.textSize === 'huge'&& this.currentJournal.type ==='kids'){
          let qlSizeHuge = document.getElementsByClassName('ql-size-huge')
          if(qlSizeHuge[0]){
          if(qlSizeHuge[0].classList.contains("mediumScreenSmallTypoKids")){
             qlSizeHuge[0].classList.remove("mediumScreenSmallTypoKids");
           }
          if(qlSizeHuge[0].classList.contains("mediumScreenRegularTypoKids")){
             qlSizeHuge[0].classList.remove("mediumScreenRegularTypoKids");
           }

          if(qlSizeHuge[0].classList.contains("mediumScreenBigTypoKids")){
             qlSizeHuge[0].classList.remove("mediumScreenBigTypoKids");
           }
        qlSizeHuge[0].classList.add("mediumScreenHugeTypoKids")
        }
        }
      }
      if(this.pageSize === 'big'){
        let qlEditor = document.getElementsByClassName('ql-editor')
        if(qlEditor[0]){
          if(qlEditor[0].classList.contains("bigScreenSmallTypoKids")){
             qlEditor[0].classList.remove("bigScreenSmallTypoKids");
           }
          if(qlEditor[0].classList.contains("bigScreenBigTypoKids")){
             qlEditor[0].classList.remove("bigScreenBigTypoKids");
           }

          if(qlEditor[0].classList.contains("bigScreenHugeTypoKids")){
             qlEditor[0].classList.remove("bigScreenHugeTypoKids");
           }
          if(qlEditor[0].classList.contains("bigScreenSmallTypo")){
             qlEditor[0].classList.remove("bigScreenSmallTypo");
           }
          if(qlEditor[0].classList.contains("bigScreenBigTypo")){
             qlEditor[0].classList.remove("bigScreenBigTypo");
           }

          if(qlEditor[0].classList.contains("bigScreenHugeTypo")){
             qlEditor[0].classList.remove("bigScreenHugeTypo");
           }

           if(this.currentJournal.type !== 'kids'){
             qlEditor[0].classList.add("bigScreenRegularTypo")    

           }
            if(this.currentJournal.type === 'kids'){
             qlEditor[0].classList.add("bigScreenRegularTypoKids")    

           }
        }
        if(this.textSize === 'small' && this.currentJournal.type !== 'kids'){  
        let qlSizeSmall = document.getElementsByClassName('ql-size-small')
        if(qlSizeSmall[0]){
          if(qlSizeSmall[0].classList.contains("bigScreenRegularTypo")){
             qlSizeSmall[0].classList.remove("bigScreenRegularTypo");
           }
          if(qlSizeSmall[0].classList.contains("bigScreenBigTypo")){
             qlSizeSmall[0].classList.remove("bigScreenBigTypo");
           }

          if(qlSizeSmall[0].classList.contains("bigScreenHugeTypo")){
             qlSizeSmall[0].classList.remove("bigScreenHugeTypo");
           }
            qlSizeSmall[0].classList.add("bigScreenSmallTypo")
        }  if(this.textSize === 'small' && this.currentJournal.type === 'kids'){
          let qlSizeSmall = document.getElementsByClassName('ql-size-small')  
          if(qlSizeSmall[0].classList.contains("bigScreenRegularTypoKids")){
             qlSizeSmall[0].classList.remove("bigScreenRegularTypoKids");
           }
          if(qlSizeSmall[0].classList.contains("bigScreenBigTypoKids")){
             qlSizeSmall[0].classList.remove("bigScreenBigTypoKids");
           }

          if(qlSizeSmall[0].classList.contains("bigScreenHugeTypoKids")){
             qlSizeSmall[0].classList.remove("bigScreenHugeTypoKids");
           }
        qlSizeSmall[0].classList.add("bigScreenSmallTypoKids")
        }
        }
        if(this.textSize === 'large' && this.currentJournal.type !== 'kids'){
        let qlSizeLarge = document.getElementsByClassName('ql-size-large')
        if(qlSizeLarge[0]){
          if(qlSizeLarge[0].classList.contains("bigScreenSmallTypo")){
             qlSizeLarge[0].classList.remove("bigScreenSmallTypo");
           }
          if(qlSizeLarge[0].classList.contains("bigScreenRegularTypo")){
             qlSizeLarge[0].classList.remove("bigScreenRegularTypo");
           }
          if(qlSizeLarge[0].classList.contains("bigScreenHugeTypo")){
             qlSizeLarge[0].classList.remove("bigScreenHugeTypo");
           }
           qlSizeLarge[0].classList.add("bigScreenBigTypo")
          }
        }
        if(this.textSize === 'large' && this.currentJournal.type === 'kids'){
          let qlSizeLarge = document.getElementsByClassName('ql-size-large')
          if(qlSizeLarge[0]){
          if(qlSizeLarge[0].classList.contains("bigScreenSmallTypoKids")){
             qlSizeLarge[0].classList.remove("bigScreenSmallTypoKids");
           }
          if(qlSizeLarge[0].classList.contains("bigScreenRegularTypoKids")){
             qlSizeLarge[0].classList.remove("bigScreenRegularTypoKids");
           }
          if(qlSizeLarge[0].classList.contains("bigScreenHugeTypoKids")){
             qlSizeLarge[0].classList.remove("bigScreenHugeTypoKids");
           }
        qlSizeLarge[0].classList.add("bigScreenBigTypoKids")
        }
        }
         if(this.textSize === 'huge' && this.currentJournal.type !== 'kids'){
        let qlSizeHuge = document.getElementsByClassName('ql-size-huge')
        if(qlSizeHuge[0]){
          if(qlSizeHuge[0].classList.contains("bigScreenSmallTypo")){
             qlSizeHuge[0].classList.remove("bigScreenSmallTypo");
           }
          if(qlSizeHuge[0].classList.contains("bigScreenRegularTypo")){
             qlSizeHuge[0].classList.remove("bigScreenRegularTypo");
           }
          if(qlSizeHuge[0].classList.contains("bigScreenBigTypo")){
             qlSizeHuge[0].classList.remove("bigScreenBigTypo");
           }
            qlSizeHuge[0].classList.add("bigScreenHugeTypo")
         } 
         }
         if(this.textSize === 'huge' && this.currentJournal.type === 'kids'){
           let qlSizeHuge = document.getElementsByClassName('ql-size-huge')
           if(qlSizeHuge[0]){
          if(qlSizeHuge[0].classList.contains("bigScreenSmallTypoKids")){
             qlSizeHuge[0].classList.remove("bigScreenSmallTypoKids");
           }
          if(qlSizeHuge[0].classList.contains("bigScreenRegularTypoKids")){
             qlSizeHuge[0].classList.remove("bigScreenRegularTypoKids");
           }
          if(qlSizeHuge[0].classList.contains("bigScreenBigTypoKids")){
             qlSizeHuge[0].classList.remove("bigScreenBigTypoKids");
           }
        qlSizeHuge[0].classList.add("bigScreenHugeTypoKids")
         }
         }
      }
    }
    }
  }
  },
};
</script>
<style>
.ql-toolbar {
  border:1px solid #e6e6e6!important;
  padding: 0 !important;
  background-color: #f5f4f1;
  border-radius: 30px;
  min-width:235px!important;
  position: absolute!important;

  z-index:150!important;

  padding:0 2px!important;
  height:25px!important;
  display: flex!important;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}
.toolbar-at-top{
  top:0!important;
  margin-top: -28px;

}
.toolbar-at-bottom{
  bottom:0!important;
  margin-bottom: -32px;
}
.blocktext-background {
  background-image: url("../../assets/icons/text.svg");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
}

.mini {
  background-size: 6px !important;
}
.text-image {
  image-rendering: -webkit-optimize-contrast;
}
/* CUSTOM SCROLLBAR BLOCKTEXT*/

.scrollbarBlockText::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.4);
}
.scrollbarBlockText::-webkit-scrollbar-thumb {
  background: #f7f6f6 !important;
  border-radius: 10px;
  min-height: 60px !important;
}
.smallScreenSmallTypo{
  font-size: 5px!important;
}
.smallScreenSmallTypoKids{
  font-size: 7px!important;
}
.smallScreenRegularTypo{
 font-size: 8px!important;
}
.smallScreenRegularTypoKids{
 font-size: 11px!important;
}
.smallScreenBigTypo{
 font-size: 12px!important;
}
.smallScreenBigTypoKids{
 font-size: 17px!important;
}
.smallScreenHugeTypo{
  font-size:17px!important;
}
.smallScreenHugeTypoKids{
  font-size:23px!important;
}
.mediumScreenSmallTypo{
  font-size: 7px!important;
}
.mediumScreenSmallTypoKids{
  font-size: 10px!important;
}
.mediumScreenRegularTypo{
   font-size: 11px!important;
}
.mediumScreenRegularTypoKids{
   font-size: 16px!important;
}
.mediumScreenBigTypo{
  font-size: 17px!important;
}
.mediumScreenBigTypoKids{
  font-size: 24px!important;
}
.mediumScreenHugeTypo{
  font-size: 26px!important;
}
.mediumScreenHugeTypoKids{
  font-size: 35px!important;
}
.bigScreenSmallTypo{
  font-size: 9px!important;
}
.bigScreenSmallTypoKids{
  font-size: 12px!important;
}
.bigScreenRegularTypo{
  font-size: 14px!important;
}
.bigScreenRegularTypoKids{
  font-size: 19px!important;
}
.bigScreenBigTypo{
   font-size: 21px!important;
}
.bigScreenBigTypoKids{
   font-size: 32px!important;
}
.bigScreenHugeTypo{
   font-size: 31px!important;
}
.bigScreenHugeTypoKids{
   font-size: 42px!important;
}
.lds-spinner2 div:after {
  background: #15AF97;
}
</style>