<template>
  <div class="mb-14" @click="setSelectedBlock"  :style="maxWidth">
    <div class="flex justify-center">
      <button
        @click="setShowPagination"
        class="
          bg-gray-50
          px-4
          mt-1
          rounded-full
          z-30
          py-1
          shadow
          text-xs
          absolute
        "
      >
        {{ $t("open") }}
      </button>
    </div>
    <div class="flex justify-center">
      <div
        class="
          bg-tribugreen-light
          shadow
          mx-10
          rounded-full
          flex
          overflow-auto
          pb-2
          pt-2
          text-center
          mt-5
        "
        style="font-size: 10px; max-width: 770px"
      >
        <div
          @click="setSelectedPage('frontcover')"
          class="ml-3 mr-5 cursor-pointer"
          :class="selectedPage === 1 ? 'selected-pagination-page-close' : ''"
        >
          <p>C</p>
        </div>
        <div v-if="journalType === 'premium'" class="flex">
          <div
            @click="setSelectedPage(2)"
            class="pagination-page-close"
            :class="selectedPage === 2 ? 'selected-pagination-page-close' : ''"
          >
            <p>2</p>
          </div>

          <div
            @click="setSelectedPage(3)"
            class="pagination-page-close"
            :class="selectedPage === 3 ? 'selected-pagination-page-close' : ''"
          >
            <p>3</p>
          </div>
        </div>
        <div v-if="journalType === 'premium'" class="flex">
          <div v-for="index in editablePages" :key="index">
            <div
              @click="setSelectedPage(index + 3)"
              class="pagination-page-close"
              :class="
                selectedPage === index + 3
                  ? 'selected-pagination-page-close'
                  : ''
              "
            >
              <p>{{ index + 3 }}</p>
            </div>
          </div>
        </div>
        <div v-else class="flex">
          <div v-for="index in editablePages" :key="index">
            <div
              @click="setSelectedPage(index + 1)"
              class="pagination-page-close"
              :class="
                selectedPage === index + 1
                  ? 'selected-pagination-page-close'
                  : ''
              "
            >
              <p>{{ index }}</p>
            </div>
          </div>
        </div>
        <div
          @click="setSelectedPage('backcover')"
          class="mr-3 ml-5 cursor-pointer"
          :class="
            selectedPage === 'backcover'
              ? 'selected-pagination-page-close '
              : ''
          "
        >
          <p>C</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Blocks } from "../../api";
export default {
  name: "PaginationClose",
  mounted() {
    this.setEditablePages();
  },
  data() {
    return {
      editablePages: null,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    maxWidth(){
      const screenSize = this.screenWidthSize - 225 - 280
      const style = {
          maxWidth: `${screenSize}px`,
        };
      return style
    },
    screenWidthSize(){
      return this.window.width;
    },
    nbrOfPage() {
      return this.currentJournal.pages.length;
    },
    pages() {
      return this.$store.state.pages;
    },
    selectedPage() {
      return this.$store.state.selectedPage;
    },
    journalType() {
      return this.$store.state.journalType;
    },
    showPagination() {
      return this.$store.state.showPagination;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    setSelectedBlock() {
      this.$store.commit("setSelectedBlock", { id: null });
    },
    async setSelectedPage(page) {
      if(this.$store.state.blockTextIsSelected){
        this.$store.commit('setBlockTextIsSelected',false)
      }
      let pageNumber = page;
      if (pageNumber === "frontcover") {
        pageNumber = 1;
      }
      if (pageNumber === "backcover") {
        pageNumber = this.pages.length;
      }
      this.$router.push({ name: "Workspace", query: { page: pageNumber } });
      this.$store.commit("setSelectedPage", pageNumber);
      
      if (typeof this.$store.state.selectedBlock === "string") {
        for (let i = 0; i < this.currentJournal.pages.length; i++) {
          if (this.currentJournal.pages[i].order === page - 1) {
            if (this.$store.state.hasBlockImage) {
              const journalId = this.currentJournal.id;
              const blockId = this.$store.state.blockId;
              const updatedAt = this.$store.state.blockUpdatedAt;
              const res = await Blocks.editImage(
                journalId,
                blockId,
                this.$store.state.top,
                this.$store.state.left,
                this.$store.state.scale,
                updatedAt,
                "pagination drag"
              );

              let currentJournal = this.currentJournal;
              for (let i = 0; i < currentJournal.pages.length; i++) {
                for (
                  let j = 0;
                  j < currentJournal.pages[i].blocks.images.length;
                  j++
                ) {
                  if (
                    currentJournal.pages[i].blocks.images[j].block_id ===
                    blockId
                  ) {
                    currentJournal.pages[i].blocks.images[j] = res;
                    this.$store.commit("setCurrentJournal", currentJournal);
                    this.$store.commit("setRemountCropper", true);
                  }
                }
              }

              this.$store.commit("setHasBlockImage", false);
              this.$store.commit("setBlockId", null);
              this.$store.commit("setBlockUpdatedAt", null);
              this.$store.commit("setMetaData", null);
            }
          }
        }
      }
      this.$store.commit("setChangePage", true);
    },
    setShowPagination() {
      this.$store.commit("setShowPagination", !this.showPagination);
      this.$store.commit("setPaginationIsReduce", false);
    },
    setEditablePages() {
      let pagesArray = this.pages;
      let newPagesArray = [];

      if (this.journalType === "premium") {
        newPagesArray.push(
          pagesArray.filter(
            (item) =>
              ![1, 2, 3, pagesArray.length, pagesArray.length - 1].includes(
                item.order
              )
          )
        );
      } else {
        newPagesArray.push(
          pagesArray.filter(
            (item) => ![1, pagesArray.length].includes(item.order)
          )
        );
      }
      this.editablePages = newPagesArray[0].length;
    },
  },
};
</script>
