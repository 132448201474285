<template>
  <div
  v-if="currentJournal.pages"
    :class="
      !imageBlock
        ? 'flex justify-center items-center h-full'
        : 'overflow-hidden'
    "
    :style="[!imageBlock ? 'outline: 1px solid #E6E6E6;' : '',selectedBlock === blockKey?'outline:1px solid transparent':'']"
    @drag.stop.prevent
    @dragstart.stop.prevent
    @dragover.stop.prevent
    @dragenter.stop.prevent
    @dragend.stop.prevent
    @dragleave.stop.prevent
    @drop.stop.prevent="onDrop"
    @sendMetaData="metadata = $event"
   
  >
    <cropper-component
      :key="componentKey"
      :isMiniature="isMiniature"
      :block="block"
      :page="page"
    ></cropper-component>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { Blocks } from "../../api";
import CropperComponent from "./CropperComponent.vue";
import utils from '../../utils';

export default {
  props: {
    isMiniature: Boolean,
    block: Object,
    page: Number,
    outside: Boolean,
  },

  mounted() {
    this.outsideData = this.outside;
    if (this.selectedBlock === this.blockKey && this.$store.state.metadata) {
      this.top = this.$store.state.metadata.top;
      this.left = this.$store.state.metadata.left;
      this.scale = this.$store.state.metadata.scale;
    }
  },
  data() {
    return {
      componentKey: 0,
      top: null,
      left: null,
      scale: null,
      hover: false,
      showToolImage: false,
      outsideData: null,
      zoomIn: false,
      zoomOut: false,
    };
  },
  components: {
    Cropper,
    CropperComponent,
  },
  watch:{
    remountCropper(value, oldValue){
      
      if(value !== oldValue && value === true && this.selectedBlock === this.blockKey){
        this.componentKey = !this.componentKey
      }


    }
  },
  computed: {

    remountCropper() {
      return this.$store.state.remountCropper;
    },
    blockKey() {
      const pageId = this.pageObject.id;
      const blockId = this.block.id;
      const order = this.block.order;
      return `${pageId}-${blockId}-${order}`;
    },
    pageObject() {
      for (let i = 0; i < this.currentJournal.pages.length; i++) {
        if (this.currentJournal.pages[i].order == this.page)
          return this.currentJournal.pages[i];
      }
    },
    selectedBlock() {
      return this.$store.state.selectedBlock;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    displayedPage() {
      return this.$store.state.displayedPage;
    },
    imageBlock() {
      if(this.pageObject.blocks.images){
      if (this.pageObject.blocks.images.length > 0) {
        if(this.block.order){
        return this.pageObject.blocks.images.find(
          (item) => item.order === this.block.order
        );
      }}
      }
    },
  },
  methods: {

    blockIsVertical(width, height) {
      return height > width ? true : false;
    },
    async onDrop() {
      const currentJournal = this.currentJournal;
      if (this.imageBlock) {
        for (let i = 0; i < this.$store.state.uploadedImages.length; i++) {
          for (
            let j = 0;
            j < this.$store.state.uploadedImages[i].data.length;
            j++
          ) {
            if (
              this.$store.state.uploadedImages[i].data[j].id ===
              this.imageBlock.media_id
            ) {
              this.$store.state.uploadedImages[i].data[
                j
              ].is_used_previously = false;
            }
          }
        }
        const journalId = currentJournal.id;
        const blockId = this.imageBlock.block_id;
        await Blocks.deleteBlock(journalId, blockId);

        const orginalArray = currentJournal.pages[this.page - 1].blocks.images;
        let newArray = [];
        for (let i = 0; i < orginalArray.length; i++) {
          if (orginalArray[i].order !== this.block.order) {
            newArray.push(orginalArray[i]);
          }
        }
        currentJournal.pages[this.page - 1].blocks.images = newArray;
        this.$store.commit("setCurrentJournal", currentJournal);
        await utils.journalEvent('image_removed')
      }
      if (this.$store.state.dragImage) {
        let body = {};
        body["page_id"] = this.pageObject.id;
        body["media_id"] = this.$store.state.dragImage;
        body["order"] = this.block.order;
        const res = await Blocks.insertNewImageBlock(body);
        currentJournal.pages[this.page - 1].blocks.images.push(res);
        this.$store.commit("setCurrentJournal", currentJournal);

        for (let i = 0; i < this.$store.state.uploadedImages.length; i++) {
          for (
            let j = 0;
            j < this.$store.state.uploadedImages[i].data.length;
            j++
          ) {
            if (
              this.$store.state.uploadedImages[i].data[j].id ===
              this.$store.state.dragImage
            ) {
              this.$store.state.uploadedImages[i].data[
                j
              ].is_used_previously = true;
            }
          }
        }

        this.$store.commit("setDragImage", null);
        await utils.journalEvent('image_added')
      }
      if(localStorage.guide && this.$store.state.stepGuide === 5){
        this.$store.commit('setShowGuide',true)
      }
    },

    calculateSize(value, max) {
      return (max * (value * 100)) / 100;
    },
  },
};
</script>