<template>
  <div
    class="
      absolute
      w-full
      bg-black bg-opacity-50
      h-screen
      flex
      justify-center
      items-center
      -mt-10
      z-40
    "
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6"
      style="width:450px"
    >

    <div>
    <div class="w-full flex justify-end">
      <img @click="setAutofillModal" src="../assets/icons/cross-gray.svg" class="w-4 h-4 cursor-pointer"/>
    </div>
      <p class="text-center font-bold">{{$t("autofill_title1")}}</p>
      <p class="text-center font-bold">{{$t("autofill_title2")}}</p>
      <p class="text-left mt-5">{{$t("autofill_text1")}}</p>
      <p class="text-left">{{$t("autofill_text2")}}</p>
      <p class="text-left">{{$t("autofill_text3")}}</p>
      <p class="mb-3 text-red-600 mt-3 text-center text-sm flex"><img class="w-5 h-5 mr-2" src="../assets/icons/warning.svg"/>{{$t("autofill_warning")}}</p>
    </div>
      <button
      id="editor-autofill-validated"
      @click="fillJournal"
        class="
          bg-tribugreen-light
          text-tribugreen-regular
          w-full
          py-3
          rounded-full
          mt-5
          cursor-pointer
          text-sm
        "
      >
       {{ $t("start_autofill") }}

      </button>
      <button class="mt-4 cursor-pointer text-sm border-b border-gray-400 pb-1 hover:text-gray-400 hover:border-gray-200" @click="setAutofillModal">
        {{ $t("fill_manualy") }}
      </button>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";
import {  Journal } from "../api";
import Preloader from './Preloader.vue'
import utils from "../utils";
import moment from "moment";
export default {
  directives: {
    ClickAway: directive
  },
  methods: {
    onClickAway() {
        this.setAutofillModal()

    },
    setAutofillModal(){
      this.$store.commit("setOutsideAutofillModal",true)
      this.$store.commit('setModalAutofill',false)
    },
    async fillJournal(){
      const res = await Journal.fillJournal();
      utils.event('autofill_selected', {
        newspaper_id: this.$store.state.currentJournal.id,
        newspaper_period: moment(this.$store.state.currentJournal.created_at).format('YYYY-MM'),
        //image_count: "unknown", TODO: replace autofill_selected by backend implementation
      });
      this.$store.commit("setCurrentJournal",res);
      this.$store.commit("setLoader",false)
      this.$store.commit('setReloadSidebar',true)
    },
  },
  components:{
      Preloader
  }
};
</script>
