<template>
<guide v-if="guide"></guide>
  <modale-welcome v-if="showWelcomeMessage" :isMobile="isMobile"></modale-welcome>
  <modal-end-month v-if="showModalEndMonth" :days="remainingDay"></modal-end-month>
  <div class="font-inter bg-tribugray-light fixed w-full" >
    <div v-if="user && currentJournal">
      <header-app :user="user"></header-app>
   
      <div
        v-if="hasImpersonateToken && showBanner"
        class="w-full bg-red-400 text-white py-1 text-xs px-4 flex justify-between items-center"
      >
        <p>{{ $t("logged_as") }} <b>{{ user.firstname }} {{ user.lastname }} - {{user.email}}</b></p>
        <div @click="logoutAdmin" class="border border-white w-28 text-center py-1 rounded cursor-pointer hover:bg-red-300">{{$t("logout")}}</div>
      </div>
      <legend-modale v-if="showModalLegend"></legend-modale>
      <upload-modal
        v-if="showUploadModal && !outsideUploadModal"
      ></upload-modal>
      <delete-modale-text-area v-if="showDeleteModalTextArea"></delete-modale-text-area>
      <delete-modale
        v-if="showDeleteModal && !outsideDeleteModal"
      ></delete-modale>
      <layout-modal
        v-if="showLayoutModal && !outsideLayoutModal"
      ></layout-modal>
      <text-error-modal v-if="showTextErrorModal"></text-error-modal>
      <page-error-modal v-if="showModalErrorPage"></page-error-modal>
      <change-order-modal
        v-if="showChangeOrderErrorModal && !outsideChangeOrderErrorModal"
      ></change-order-modal>
      <autofill-modal v-if="modalAutofill && !outsideAutofillModal"></autofill-modal>
      <zoom-modal v-if="modalZoom && outsideModalZoom"></zoom-modal>
      <div class="flex">
        <sidebar class="absolute left-0" title="layout" v-if="!showImportPage"></sidebar>
        <journal-area
          style="padding-right: 280px; padding-left: 225px"
          v-if="layoutBlock && !showImportPage" 
          
        ></journal-area>
        <sidebar class="absolute right-0" title="photos"></sidebar>
      </div>

      <notifications
        position="bottom center"
        width="80%"
        classes="my-notification font-inter"
      />

    </div>
  </div>
</template>
<script>
import LegendModale from "./Blocks/ModaleLegend.vue";
import DeleteModale from "./ModaleDelete.vue";
import DeleteModaleTextArea from "./ModaleDeleteTextArea.vue"
import HeaderApp from "./Header.vue";
import Sidebar from "./Sidebar.vue";
import UploadModal from "./UploadImages/ModalUpload.vue";
import JournalArea from "./JournalArea.vue";
import LayoutModal from "./ModaleLayout.vue";
import TextErrorModal from "./ModalTextError.vue";
import PageErrorModal from "./ModalPageError.vue";
import ChangeOrderModal from "./ModalErrorChangeOrder.vue";
import AutofillModal from "./ModalAutofill.vue";
import ZoomModal from "./DisplayImages/ModalZoomImage.vue";
import ModaleWelcome from "../components/ModaleWelcome.vue";
import { User } from "../api";
import ModalEndMonth from "./ModalEndMonth.vue";
import Guide  from "../components/Guide.vue";

export default {
  async beforeMount(){
  if(localStorage.hardcover){
          this.menuObject = await User.getMenu();
      if (this.menuObject) {
        this.hardcoverMenu = this.menuObject.find(
          (item) => item.key === "album"
        );
      }
      localStorage.removeItem("hardcover");
      location.href = this.hardcoverMenu.link
    }
    if(localStorage.joinTribe){
      this.$store.commit('setShowWelcomeMessage',true)
    }
    let currentDate = new Date()
    let month = currentDate.getMonth() 
    if (localStorage.getItem("currentMonth") === null) {
      localStorage.currentMonth = month
    }
    if (localStorage.getItem("currentMonth") !== month.toString()) {
      localStorage.removeItem("showmodal");
      localStorage.currentMonth = month
    }
    localStorage.currentMonth = month
    let year = currentDate.getFullYear()
    let day = currentDate.getDate()
    let lastDayOfMonth = this.getLastDayOfMonth(year, month)
    this.remainingDay = (lastDayOfMonth+1) - day
    if(localStorage.guide) {
      this.$store.commit('setShowGuide',true)
    }
  },

  data(){
    return{
      remainingDay:30,
      showBanner:true,
      menuObject:null,
      hardcoverMenu:null
    }
  },
  computed: {
    guide(){
      return this.$store.state.showGuide
    },
    showModalEndMonth(){
      return this.$store.state.showModalEndMonth && localStorage.getItem("showmodal") !== 'false' && this.remainingDay < 4 ? true : false;      
    },
   showWelcomeMessage(){
      return this.$store.state.showWelcomeMessage
    },
    showImportPage(){
      return this.$store.state.showImportPage
    },
    user() {
      return this.$store.state.userInfos;
    },
    hasImpersonateToken() {
      return localStorage.impersonate_token ? true : false;
    },
    showModalErrorPage() {
      return this.$store.state.showModalErrorPage;
    },
    showChangeOrderErrorModal() {
      return this.$store.state.modalErrorChangeOrder;
    },
    showTextErrorModal() {
      return this.$store.state.modalErrorText;
    },
    outsideDeleteModal() {
      return this.$store.state.outsideDeleteModal;
    },
    showDeleteModalTextArea(){
      return this.$store.state.deleteModalTextArea
    },
    outsideUploadModal() {
      return this.$store.state.outsideUploadModal;
    },
    outsideLayoutModal() {
      return this.$store.state.outsideLayoutModal;
    },
    outsideChangeOrderErrorModal() {
      return this.$store.state.outsideChangeOrderErrorModal;
    },
    outsideTextErrorModal() {
      return this.$store.state.outsideTextErrorModal;
    },
    outsideModalZoom(){
      return this.$store.state.outsideModalZoom;
    },
    showLayoutModal() {
      return this.$store.state.showLayoutModal;
    },
    showModalLegend() {
      return this.$store.state.showModalLegend;
    },
    allLayoutBlock() {
      return this.$store.state.layoutBlock;
    },
    showDeleteModal() {
      return this.$store.state.showDeleteModal;
    },
    showUploadModal() {
      return this.$store.state.showUploadModal;
    },
    user() {
      return this.$store.state.userInfos;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    layoutBlock() {
      return this.$store.state.layoutBlock;
    },
    modalAutofill(){
      return this.$store.state.modalAutofill
    },
    modalZoom(){
      return this.$store.state.modalZoom
    },
    outsideAutofillModal(){
      return this.$store.state.outsideAutofillModal
    }
  },
  methods:{
    getLastDayOfMonth(y, m) {
      return  new Date(y, m +1, 0).getDate();
    },
    logoutAdmin(){
      this.$router.push({name: "Workspace"})
     this.showBanner = false
     setTimeout(() =>document.location.reload(),1500)
    }
  },
  components: {
    ModaleWelcome,
    LegendModale,
    DeleteModale,
    HeaderApp,
    Sidebar,
    UploadModal,
    JournalArea,
    LayoutModal,
    TextErrorModal,
    ChangeOrderModal,
    PageErrorModal,
    DeleteModaleTextArea,
    AutofillModal,
    ZoomModal,
    ModalEndMonth,
    Guide
  },
};
</script>
