<template>
  <div class="w-full flex h-screen absolute background-auth2 text-tribugray-dark" :class="screen===3?'justify-end':''" style="z-index:1000">
    <div :class="screen===3?'':'w-1/2'"></div>
    <div class="bg-tribugreen-light rounded-xl my-10  h-full font-inter relative" :style="[screen===3?'width:690px':'max-width:500px',screen===4?'max-height:450px':'max-height:550px', screen === 4 && showInviteMembers?'max-height:530px':'']" :class="screen === 3?'mx-3':'mx-10 w-1/2'">
      <div class="flex flex-col w-full items-center">
        <div class="w-full flex justify-center border-b border-tribugreen-regular">
          <img src="../assets/logo-black.svg" class="w-12 my-5"/>
        </div>
        <!--SCREEN 1-->
        <div class="mx-auto flex flex-col items-center mt-7" v-if="screen === 1">
            <p class="text-sm text-center" style="max-width:190px">{{$t('onboarding_screen_1')}}</p>
            <button @click="screen = 2" class="cursor-pointer hover:bg-tribugreen-medium text-sm rounded-full bg-tribugreen-regular text-white py-2 mt-10" style="width:230px">{{$t("start_new_album")}}</button>
            <button @click="screen = 'joinTribe'" class="cursor-pointer hover:bg-tribugreen-medium text-sm rounded-full bg-white text-tribugreen-regular py-2 mt-2" style="width:230px">{{$t("join_tribe")}}</button>
        </div>
        <!--SCREEN JOIN TRIBE-->
        <div class="mx-auto flex flex-col items-center mt-7" v-if="screen === 'joinTribe'">
            <p class="text-sm text-center" style="max-width:190px">{{$t('hello')}} {{user.firstname}} !</p>
            <p class="text-sm text-center" style="width:200px">{{$t('onboarding_join_tribe')}}</p>
            <div class="flex bg-white rounded-lg px-3 py-3 mt-10 shadow-sm" style="width:300px">
              <img style="width:20px" class="mr-4" src="../assets/icons/link.svg">
              <input type="text" v-model="url" :placeholder="$t('link')"/>
            </div>
            <button @click="splitUrl()" class="bg-tribugreen-regular cursor-pointer text-white rounded-full text-sm px-5 py-2 mt-10 hover:bg-tribugreen-medium">{{$t('onboarding_join_tribe_button')}}</button>
        </div>
        <!--SCREEN 2-->
        <div class="mx-auto flex flex-col items-center mt-7" v-if="screen === 2">
            <div class="text-sm text-center">
                <p>{{$t('hello')}} {{user.firstname}} !</p>
                <p>{{$t('onboarding_screen_2')}}</p>
            </div>
            <input class="input-auth px-5 py-3 rounded-xl shadow mt-10" style="width:280px" v-model="tribeName" :placeholder="$t('enter_tribe_name')" @input="limitLength" type="text" />
            <div style="width:250px; background-color: #f6f9f9" class="border border-tribugreen-medium rounded-xl px-3 py-5 mt-5 flex flex-col items-center">
                <img class="w-7" src="../assets/icons/chat_smile.svg"/>
                <p class="text-sm text-center mt-3">{{$t('tribe_name_info')}}</p>
            </div>
            <button v-if="tribeName.length < 1" class="bg-tribugreen-medium text-white cursor-pointer rounded-full text-sm px-5 py-2 mt-10">{{$t('create_tribe')}}</button>
            <button v-else @click="editTribeName()" class="hover:bg-tribugreen-medium cursor-pointer bg-tribugreen-regular text-white rounded-full text-sm px-5 py-2 mt-10">{{$t('create_tribe')}}</button>
        </div>
        <!--SCREEN 3-->
        <div class="mx-auto flex flex-col items-center mt-4" v-if="screen === 3">
            <div class="text-sm text-center" style="width:180px">
                <p>{{$t('onboarding_screen_3')}}</p>
            </div>
            <div class="flex mt-4">
               <!-- <div style="width:190px" class="bg-white rounded shadow text-xs pl-2 pr-2 pt-2">
                    <img style="width:70px; margin:auto" src="../assets/icons/baby.svg"/>
                    <p class="text-tribugreen-regular text-center mt-2">Album <b>Kids</b></p>
                    <p class="text-center mt-4 mb-3">{{$t('kids_resume')}}</p>
                    <p>• {{$t('kids_point_1')}}</p>
                    <p class="mt-2">• {{$t('kids_point_2')}}</p>
                    <p class="mt-2">• {{$t('kids_point_3')}}</p>
                    <div class="w-full flex justify-center cursor-pointer">
                        <button @click="changeType('kids')" class="hover:bg-tribugreen-medium bg-tribugreen-regular text-white rounded-full text-xs px-5 py-2 mb-3 mt-10">{{$t('choose_album')}}</button>
                    </div>
                </div>-->
                <div style="width:190px" class="bg-white rounded shadow text-xs pl-2 pr-2 pt-3  mx-3">
                    <img style="width:80px; margin:auto" src="../assets/icons/book-login.svg"/>
                    <p class="text-tribugreen-regular text-center mt-2">Album <b>{{$t('classic')}}</b></p>
                    <p class="text-center mt-4 mb-3">{{$t('classic_resume')}}</p>
                    <p>• {{$t('classic_point_1')}}</p>
                    <p class="mt-2">• {{$t('classic_point_2')}}</p>
                    <p class="mt-2">• {{$t('classic_point_3')}}</p>
                    <div class="w-full flex justify-center cursor-pointer">
                        <button @click="changeType('standard')" class="hover:bg-tribugreen-medium cursor-pointer bg-tribugreen-regular text-white rounded-full text-xs px-5 py-2 mb-3 mt-10">{{$t('choose_album')}}</button>
                    </div>
                </div>
                <div style="width:190px" class="bg-white rounded shadow text-xs pl-2 pr-2 pt-2">
                    <img style="width:70px; margin:auto" src="../assets/icons/premium.svg"/>
                    <p class="text-tribugreen-regular text-center mt-2">Album <b>Premium</b></p>
                    <p class="text-center mt-4 mb-3">{{$t('premium_resume')}}</p>
                    <p>• {{$t('premium_point_1')}}</p>
                    <p class="mt-2">• {{$t('premium_point_2')}}</p>
                    <p class="mt-2">• {{$t('premium_point_3')}}</p>
                    <div class="w-full flex justify-center cursor-pointer">
                        <button @click="changeType('premium')" class="hover:bg-tribugreen-medium cursor-pointer bg-tribugreen-regular text-white rounded-full text-xs px-5 py-2 mb-3 mt-6">{{$t('choose_album')}}</button>
                    </div>
                </div>
            </div>
            <div @click="nextStep()" class="text-xs hover:text-gray-500 cursor-pointer mt-5">{{$t('later')}}</div>
        </div>
        <!--SCREEN 4-->
        <div class="mx-auto flex flex-col items-center mt-7" v-if="screen === 4 && !showInviteMembers">
          <div class="text-sm text-center" style="width:192px">
              <p>{{$t('onboarding_screen_4_1')}} "{{currentTribeName}}" {{$t("onboarding_screen_4_2")}}</p>
          </div>
          <div class="flex text-sm w-full items-center mt-10">
            <div  @click="inviteMembers()" class="cursor-pointer border border-white hover:border-tribugreen-regular w-1/2 text-tribugreen-regular bg-white rounded-xl flex pt-5 pb-3 px-3 mr-2">
              <img style="width:60px" src="../assets/icons/members_2.svg"/>
              <div class="ml-3">{{$t('invite_members')}}</div>
            </div>
            <div  @click="closeOnboarding()" class="cursor-pointer border border-white hover:border-tribugreen-regular w-1/2 text-tribugreen-regular bg-white rounded-xl flex items-center pt-5 pb-3 px-3 ml-2">
              <img style="width:60px" src="../assets/icons/member_2.svg"/>
              <div class="ml-3" style="width:80px">{{$t('create_album_alone')}}</div>
            </div>
          </div>
        </div>
        <!--SCREEN 4 - INVITE MEMBERS-->
        <div class="mx-auto flex flex-col items-center mt-7" v-if="screen === 4 && showInviteMembers">
          <div class="text-tribugreen-regular bg-white rounded-xl items-center flex pt-3 pb-2 px-3 mr-2 text-xs">
            <img style="width:30px" src="../assets/icons/members_2.svg"/>
            <div class="ml-3">{{$t('invite_members')}}</div>
          </div>
          <div class="text-sm text-center mt-4" style="width:192px">
              <p>{{$t('share_invitation')}}</p>
          </div>
          <div class="mt-7 text-xs flex flex-col items-center w-full">
            <div @click="showInputEmail = true" class="cursor-pointer border border-white hover:border-tribugreen-regular w-1/2 text-tribugreen-regular bg-white rounded-xl flex items-center pt-3 pb-2 px-3">
              <img style="width:30px" src="../assets/icons/letter.svg"/>
              <p style="width:350px" class="ml-3 text-black">{{$t('invitation_mail')}}</p>
            </div>
              <ShareNetwork
              network="whatsapp"
              :url="link"
              :title="$t('invitation_title') +' ' +currentTribeName"
              :description="$t('invitation_text')"
              media="../../assets/img/metadata-image.jpg"
              >
                <div @click="linkMessage = false" class="mt-3 cursor-pointer border border-white hover:border-tribugreen-regular w-1/2 mx-auto text-tribugreen-regular bg-white rounded-xl flex items-center pt-3 pb-2 px-3">
                  <img style="width:30px" src="../assets/icons/whatsapp-logo.svg"/>
                  <p style="width:350px" class="ml-3 text-black">{{$t('invitation_whatsapp')}}</p>
                </div>
              </ShareNetwork>
       
            <div @click="copyLink()" class="mt-3 cursor-pointer border border-white hover:border-tribugreen-regular w-1/2 text-tribugreen-regular bg-white rounded-xl flex items-center pt-3 pb-2 px-3">
              <img style="width:30px" src="../assets/icons/share_icon.svg"/>
              <p style="width:350px" class="ml-3 text-black">{{$t('invitation_link')}}</p>
            </div>
            <div v-if="linkMessage" class="mt-2 text-tribugreen-regular">{{$t('link_copied')}}</div>
            <div @click="closeOnboarding()" class="rounded-full bg-tribugreen-regular text-white py-2 px-5 cursor-pointer mt-4 hover:bg-tribugreen-medium">{{$t('continue')}}</div>
          </div>
        </div>
      </div>
      <!--STEPS-->
      <div v-if="screen !== 1 && screen !== 'joinTribe'" class="absolute bottom-0 flex justify-between w-full">
        <div class="flex ml-7 mb-5">
            <div class="w-2 h-2 rounded-full mx-1" @click="prevStep(1)" :class="screen === 1?'bg-tribugreen-dark':'bg-tribugreen-regular'"></div>
            <div class="w-2 h-2 rounded-full mx-1"  @click="prevStep(2)" :class="screen === 2?'bg-tribugreen-dark':'bg-tribugreen-regular'"></div>
            <div class="w-2 h-2 rounded-full mx-1" @click="prevStep(3)" :class="screen === 3?'bg-tribugreen-dark':'bg-tribugreen-regular'"></div>
            <div class="w-2 h-2 rounded-full mx-1"  :class="screen === 4?'bg-tribugreen-dark':'bg-tribugreen-regular'"></div>
        </div>
   
        <div v-if="screen !== 4" @click="closeOnboarding()" class="-mt-1 hover:text-tribugreen-dark text-tribugreen-regular text-sm cursor-pointer mr-7">
            <p>{{$t('pass')}}</p>
        </div>
        <div v-if="screen === 4" @click="closeOnboarding()" class="-mt-1 hover:text-tribugreen-dark text-tribugreen-regular text-sm cursor-pointer mr-7">
            <p>{{$t('pass')}}</p>
        </div>
      </div>
      <!--MODAL EMAIL-->
      <div v-if="showInputEmail" class="text-xs absolute bg-black bg-opacity-50 h-full top-0">
        <div style="z-index:2000; width:80%; margin:20% 10% auto 10%;" class="bg-white rounded-xl pb-4">
            <div @click="showInputEmail = false" class="cursor-pointer w-3 right-0 absolute mt-3" style="margin-right:15%"><img src="../assets/icons/cross-gray.svg"/></div>
            <div class="pt-10 pl-10 pb-2"><b>{{$t('invitation_mail')}}</b></div>
            <hr/>
            <input v-model="emails" style="width:75%" class="border border-gray-200 mx-10 px-2 py-2 rounded-full mt-5" type="text" placeholder="email1@tribu.com,email2@tribu.com,..."/>
            <div class="w-full flex justify-center">
              <button class=" mx-auto border border-gray-200 mx-10 px-5 py-2 rounded-full mt-5 text-center bg-tribugreen-regular text-white cursor-pointer hover:bg-tribugreen-medium" @click="sendInvitationByMail()">{{$t('send_email')}}</button>
            </div>
            <div class="text-xs mx-10 mt-4" style="font-size:10px">*{{$t('multiple_sending')}}</div>
            <div v-if="sendMessage" class="text-tribugreen-regular text-center mx-5 my-2">{{$t('email_sent')}} {{emailToDisplay}}</div>
            <div v-if="errorMessage" class="text-red-400 text-center mr-6 mt-2">{{$t('email_error')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Journal, Tribes, User } from "../api";
import utils from "../utils"
export default {
  data() {
    return {
      token:null,
      screen: 1,
      window: {
        width: 0,
        height: 0,
      },
      tribeName: '',
      maxLength: 30,
      showInviteMembers:false,
      showInputEmail:false,
      showMessageMultipleEmail:true,
      sendMessage : false,
      errorMessage: false,
      emails:null,
      emailToDisplay:null,
      code:null,
      link:'',
      errorCode:null,
      linkMessage:false,
      url:null,
      newTribe:null
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
     this.getCode()
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed:{
    currentTribeName() {
      if(this.user.tribes){
        const tribe = this.user.tribes.find(tribu => tribu.id === this.user.current_tribe)
        return tribe.name;
      }
    },
    language() {
      return this.$store.state.language;
    },
    isMobile() {
      return this.window.width < 880 ? true : false;
    },
    user() {
      return this.$store.state.userInfos;
    },
    currentJournal(){
      return this.$store.state.currentJournal
    },
    currentTribe() {
      if(this.user.current_tribe){
        return this.user.tribes.find((item) => item.id === this.user.current_tribe)
      }
    },
    env(){
      return import.meta.env.VITE_APP_URL
    },
  },
  methods:{
    async splitUrl(){
      let segments = this.url.split('/');
      let code = segments[segments.length - 1];
      if (code.includes("?")) {
      let param = code.indexOf("?");
      code = code.substring(0, param);  
       this.newTribe = await Tribes.joinTribeWithCode(code)
    } else {
      this.newTribe = await Tribes.joinTribeWithCode(code)
    }
      localStorage.joinTribe = true
      await Tribes.switchTribe(this.newTribe.id)
    },
    async editTribeName(){
      await Tribes.editTribeName(this.tribeName)
      await User.getUserInformations()
      this.nextStep()
    },
    inviteMembers(){
      this.showInviteMembers = true
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    closeOnboarding(){
        localStorage.guide = true
        this.$router.push({name: "Workspace"})
    },
    limitLength() {
      if (this.tribeName.length > this.maxLength) {
        this.tribeName = this.tribeName.substring(0, this.maxLength);
      }
    },
    changeName(){
        this.screen = 3
    },
    nextStep(){
        this.screen = this.screen + 1;
        this.linkMessage = false

    },
    prevStep(step){
        this.screen = step;
        this.linkMessage = false

    },
   async getCode(){
      try{
        this.code = await Tribes.getInvitationCode()
        this.link = `${this.env}/invite/${this.code.code}`
  
      }catch(e){
        document.location.reload()
      }
    },
    async sendInvitationByMail(){
      try{
       this.linkMessage = false
       this.emailToDisplay = this.emails
       const arrayOfEmail = this.emails.split(',')
       await Tribes.inviteMembersByMail(arrayOfEmail)
       this.emails=null
       this.sendMessage = true
       this.showMessageMultipleEmail = false
       this.showInputEmail = false
      }catch(e){
        this.errorMessage = true
        this.showMessageMultipleEmail = false
      }

    },
    async changeType(type){
      try{
        this.$store.commit('setChosenType',type)
        if(type !== 'standard'){
            await Journal.changeType(type)
            utils.event('album_type_initialy_selected', {
            newspaper_id: this.$store.state.currentJournal.id,
            newspaper_subscription_type: moment(type),
        
            });
        }
        }catch{

        }
        this.nextStep()
    },
    copyLink(){
      navigator.clipboard.writeText(this.link);    
      this.linkMessage = true
    }

  
  }
};
</script>
<style>
.background-auth2{
  background-image: url("../assets/img/new-login.jpg");
  background-size: cover;
}
.input-auth{
  font-size:12px;
}
.input-auth::placeholder{
  color:#B4B6B8;
  font-family: 'Inter', sans-serif;
}
</style>