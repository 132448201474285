<template>
  <div
    :style="[title === 'layout' ? 'width:225px' : '', title === 'photos' && showImportPage?'width:100%':'', title === 'photos' && !showImportPage?'width:280px;':'']"
    class="
      bg-gradient-to-t
      from-white
      to-tribugreen-light
      h-screen
      shadow-md
    "
    :id="title==='photos'?'sidebarphotos':'sidebarlayouts'"
    @scroll="onScroll"
  >
  <button v-if="title === 'photos' && !onTop" class="fixed z-50 bg-white hover:bg-tribugreen-medium rounded-full bottom-0 right-0 mb-20 mr-5 cursor-pointer flex border border-tribugreen-regular hover:border-tribugreen-medium shadow-lg" @click="scrollToTop"><i class="fas fa-chevron-up text-tribugreen-regular hover:text-white pt-2" style="width:35px; height:35px"></i></button>
    <div
      class="
        font-bold
        pt-3
        bg-tribugreen-light
        w-full
        fixed
        z-20
      "
      :style="[title === 'layout' ? 'width:225px' : '', title === 'photos' && showImportPage?'width:100%':'', title === 'photos' && !showImportPage?'width:280px;':'']"
    >
      <p class="pl-6" :class="title === 'layout' ? '' : 'pb-3'" style="font-size : 16px">
        {{ $t(title) }}
      </p>
      <div
        v-if="title === 'photos'"
        class="bg-white items-center flex rounded-tr-3xl rounded-tl-3xl py-3"
        :style="showImportPage?'width:100%':'width: 280px'"
      >
        <div
          @click="
            setShowFiltersState(false);
            setShowUploadModal();
            setSelectedBlock();
          "
          class="upload-button"
          :class="
            showFilters
              ? 'inactive-button'
              : 'active-button hover:bg-tribugreen-medium'
          "
          :style ="showImportPage?'justify-content:center;  margin:auto 10px 0px 30px':''"
        >
          <p class="text-center text-fourteenpix font-normal"  :style ="showImportPage?'font-size:18px!important; padding: 5px; text-align:center':''">
            {{ $t("import_photos") }}
          </p>
          <i v-if="!showImportPage" class="fas fa-plus" style="font-size: 12px"></i>
        </div>
        <div
        v-if="!showImportPage"
          @click="
            setShowFiltersState(true);
            setSelectedBlock();
          "
          class="filter-button cursor-pointer"
          :class="
            showFilters
              ? 'active-button px-3'
              : 'inactive-filter-button hover:bg-gray-50'
          "
          style="height: 28px; width: 28px"
        >
          <i
            class="fas fa-bars"
            :class="showFilters ? 'text-white' : 'text-gray-500'"
            style="font-size: 12px"
          ></i>
        </div>
      </div>
    </div>
    <div :class="title === 'layout' ? '' : 'bg-white'">
      <layout-sidebar v-if="title === 'layout' && layouts"></layout-sidebar>
      <images-sidebar v-if="title === 'photos'"></images-sidebar>
    </div>
  </div>
</template>
<script>
import LayoutSidebar from "./SidebarLayout.vue";
import ImagesSidebar from "./SidebarImages.vue";
import Preloader from "./Preloader.vue";
export default {
  name: "Sidebar",
  props: {
    title: String,
  },
  components: {
    LayoutSidebar,
    ImagesSidebar,
    Preloader
  },
  data(){
    return{
      onTop:true
    }
  },
  computed: {
    showImportPage(){
      return this.$store.state.showImportPage
    },
    lang() {
      return this.$store.state.language;
    },
    loader() {
      return this.$store.state.loader;
    },
    layouts() {
      return this.$store.state.layouts;
    },
    showFilters() {
      return this.$store.state.showFilters;
    },
    uploadedImages() {
      return this.$store.state.uploadedImages;
    },
    scrollTopPhotos(){
      let sideBar = document.getElementById("sidebarphotos");
      if(sideBar.scrollTop){
        return sideBar.scrollTop
      }
      
    }
  },
  methods: {
    setModalAutofill() {
      if (this.imgPerMonth.length > 0) {
        if(this.imgPerMonth[0].images){
        if (this.imgPerMonth[0].images.length > 0) {
          this.$store.commit("setOutsideAutofillModal", false);
          this.$store.commit("setModalAutofill", true);
        } else {
          this.showMessageAutofill = true;
        }
        }
      } else {
        this.showMessageAutofill = true;
      }
    },
    setSelectedBlock() {
      this.$store.commit("setSelectedBlock", { id: null });
    },
    setShowFiltersState(bool) {
      this.$store.commit("setShowFilters", bool);
    },
    setShowUploadModal() {
      this.$store.commit("setOutsideUploadModal", false);
      this.$store.commit("setShowUploadModal", true);
    },
    scrollToTop(){
      let sideBar = document.getElementById("sidebarphotos");
      sideBar.scrollTop = 0;
    },
    onScroll(){
      let sideBar = document.getElementById("sidebarphotos");
      if(sideBar.scrollTop !== 0){
        this.onTop = false
      }
      else{
        this.onTop = true
      }
    }
  },
};
</script>
<style>
#sidebarlayouts{
  z-index: 50;
}
</style>
