<template>
  <div
    class="w-full h-screen flex-col"
    v-if="currentJournal && layouts"
    :key="componentKey"
    @mouseup="setMouseUp"
  >
      <button
      id="editor-changetype"
      v-if="!currentTribe.is_subscribed"
      @click="setShowChangeType"
      class="changesub-btn cursor-pointer mt-12 bg-tribugreen-dark hover:bg-tribugreen-semidark absolute z-20 text-white flex rounded-r-full items-center left-0 shadow"
      style="margin-left: 110px"
    >

      <div class="ml-6 mr-4 text-xs  py-1">
        <p class="w-20">
          {{ $t("change_type") }}
        </p>
      </div>
            <img
        class="w-5 mr-3"
        src="../assets/icons/change-subs.svg"
      />
    </button>
    <button
      id="editor-autofill-2"
      @click="setModalAutofill"
      class="autofill-btn cursor-pointer mt-12 bg-tribugreen-dark hover:bg-tribugreen-semidark absolute z-20 text-white flex rounded-l-full items-center right-0 shadow"
      style="margin-right: 180px"
    >
      <img
        v-if="!loader"
        class="w-7 h-7 ml-2"
        src="../assets/icons/chrono.svg"
      />
      <div class="ml-3" v-if="loader"><Preloader /></div>
      <div class="ml-4 text-xs mr-6 py-1">
        <p :class="lang.toLowerCase() === 'en' ? 'py-2' : ''">
          {{ $t("autofill_1") }}
        </p>
        <p>{{ $t("autofill_2") }}</p>
      </div>
    </button>
    <div v-if="!currentMonthHasImages && showMessageAutofill">
      <div
        class="absolute z-30 right-0"
        style="margin-right: 370px; margin-top: 102px"
      >
        <i
          class="fas fa-sort-up text-tribugreen-regular text-xl absolute -mt-3 cursor-pointer"
        ></i>
      </div>
      <div
        class="flex bg-tribugreen-regular items-center text-center text-white text-xs px-3 py-3 absolute z-30 right-0 shadow"
        style="width: 200px; margin-right: 285px; margin-top: 102px"
      >
        <div>
          <p class="font-bold mb-2">{{ $t("autofill_no_image_1") }}</p>
          <p>{{ $t("autofill_no_image_2") }}</p>
        </div>
        <div class="ml-3 cursor-pointer" @click="showMessageAutofill = false">
          <img class="w-7 h-7 opacity-60" src="../assets/icons/cross.svg" />
        </div>
      </div>
    </div>
    <journal-comp></journal-comp>
    <div class="flex justify-center">
    <pagination-drag
      class="mt-3 absolute bottom-0"
      :style="showPagination ? 'height:100px; margin-bottom:80px' : 'display:none'"
    ></pagination-drag>
    <pagination-close
     class="mt-3 absolute bottom-0"
      :style="showPagination ? 'display:none' : ''"
    ></pagination-close>
    </div>
  </div>
</template>
<script>
import JournalComp from "./Journal/Journal.vue";
import PaginationDrag from "./Journal/PaginationDrag.vue";
import PaginationClose from "./Journal/PaginationClose.vue";
import Preloader from "./Preloader.vue";
export default {
  name: "JournalArea",
  mounted() {
    if (this.imgPerMonth.length === 0) {
      this.currentMonthHasImages = false;
    }
  },

  components: {
    JournalComp,
    PaginationDrag,
    PaginationClose,
    Preloader,
  },
  data() {
    return {
      componentKey: 0,
      currentMonthHasImages: false,
      showMessageAutofill: false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  watch: {
    imgPerMonth(value, oldValue) {
      if (value !== oldValue) {
        if (this.imgPerMonth[0].images.length > 0) {
          this.currentMonthHasImages = true;
        }
      }
    },
    paginationIsReduce(value, oldValue) {
      if (value !== oldValue) {
        this.componentKey = !this.componentKey;
      }
    },
  },
  computed: {
    currentTribe() {
      const currentTribeInfos = this.getCurrentTribe();
      this.$store.commit("setCurrentTribe", currentTribeInfos);

      return currentTribeInfos;
    },
    uploadedImages() {
      return this.$store.state.uploadedImages;
    },
    imgPerMonth() {
      return this.$store.state.imagesPerMonth;
    },
    lang() {
      return this.$store.state.language;
    },
    paginationIsReduce() {
      return this.$store.state.paginationIsReduce;
    },
    layouts() {
      return this.$store.state.layouts;
    },
    user() {
      return this.$store.state.userInfos;
    },
    showPagination() {
      return this.$store.state.showPagination;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    loader() {
      return this.$store.state.loader;
    },
  },
  methods: {
     getCurrentTribe() {
      if (this.user.tribes) {
        const tribes = this.user.tribes;
        let currentTribe = tribes.find(
          (item) => item.id == this.user.current_tribe
        );
        if (currentTribe) {
          this.$store.commit("setMembers", currentTribe.members);
        }
        return currentTribe;
      }
    },
    setShowChangeType(){
      this.$store.commit("setShowChangeType",true)
    },
    setMouseUp(){
      this.$store.commit("setMouseUp",true)
    },
    setModalAutofill() {
      if (this.imgPerMonth.length > 0) {
        if(this.imgPerMonth[0].images){
        if (this.imgPerMonth[0].images.length > 0) {
          this.$store.commit("setOutsideAutofillModal", false);
          this.$store.commit("setModalAutofill", true);
        } else {
          this.showMessageAutofill = true;
        }
        }
      } else {
        this.showMessageAutofill = true;
      }
    },
  },
};
</script>
<style>
.autofill-btn{
  transition: transform 250ms;
}
.autofill-btn:hover{
     transform: translateX(-100px);

}
.changesub-btn{
  transition: transform 250ms;
}
.changesub-btn:hover{
     transform: translateX(110px);

}


</style>