import APICONF from "../api-config";
import store from "../store";
import utils from "../utils";
import axios from "axios";

export default {
  async getCurrentJournal() {
    try {
      const res = await APICONF.get("/current-journal");
      const currentJournal = res.data;
      store.commit("setPages", currentJournal.pages);
      store.commit("setCurrentJournal", currentJournal);
      store.commit("setJournalType", currentJournal.type);
      return currentJournal;
    } catch (error) {
      if(error.response){
        if(error.response.status !== 401){
          store.commit("setErrorMessage", "error_current_journal");
         }
        }
      
     // localStorage.removeItem("token");
     // document.location.reload();
    }
  },

  async fillPage(journalId, pageId){
    const res = await APICONF.put("/journals/"+journalId+"/pages/"+pageId+"/fill")
    const currentJournal = await this.getCurrentJournal();
    store.commit("setCurrentJournal", currentJournal)
    return res.data
  },
  async fillJournal(){
    store.commit("setOutsideAutofillModal",true)
    store.commit('setModalAutofill',false)
    store.commit("setLoader",true)
    let res = null;
    if (localStorage.getItem("impersonate_token")) {

      const root = import.meta.env.VITE_API_ROOT;
      const headers = {
        Authorization: "Bearer " + localStorage.impersonate_token,
        "Content-Type": "application/json",
        "X-WEB": "true",
      };
      res = await axios.put(root + "/fill-journal",null, { headers });
    } else {
      const root = import.meta.env.VITE_API_ROOT;
      const headers = {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
        "X-WEB": "true",
      };
      res = await axios.put(root + "/fill-journal",null, { headers });
    } 
    const currentJournal = await this.getCurrentJournal();
    store.commit("setCurrentJournal", currentJournal)
    return res.data
  },
  async changeType(type){
    const body = {
      type: type
    };
    const res = await APICONF.put("/current-journal",body)
    store.commit("setCurrentJournal", res.data)
    store.commit("setType",type)
    
  },
  async getSpecificJournal(tribeId, journalId) {
    try {
      let res = null;
      if (localStorage.getItem("impersonate_token")) {

        const root = import.meta.env.VITE_API_ROOT;
        const headers = {
          Authorization: "Bearer " + localStorage.impersonate_token,
          "Content-Type": "application/json",
          "X-WEB": "true",
        };
        res = await axios.get(root + "/past-journal/" + journalId, { headers });
      } else {
        const root = import.meta.env.VITE_API_ROOT;
        const headers = {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
          "X-WEB": "true",
        };
        res = await axios.get(root + "/past-journal/" + journalId, { headers });
      }
      const currentJournal = res.data;
      store.commit("setPages", currentJournal.pages);
      store.commit("setCurrentJournal", currentJournal);
      store.commit("setJournalType", currentJournal.type);
      return currentJournal;
    } catch (e) {
      store.commit("setErrorMessage", "error_admin");
    }
  },
  async updatePageOrder(journalId, pageId, body) {
    const root = import.meta.env.VITE_API_ROOT;
    const headers = {
      Authorization: "Bearer " + localStorage.impersonate_token,
      "Content-Type": "application/json",
      "X-WEB": "true",
    };
    store.commit("setCallisDone", false);
    try {
      let currentJournal = store.state.currentJournal;
      localStorage.impersonate_token
        ? await axios.put(
            root + "/journals/" + journalId + "/pages/" + pageId + "/order",
            body,
            { headers }
          )
        : await APICONF.put(
            "/journals/" + journalId + "/pages/" + pageId + "/order",
            body
          );
      let originalArray = currentJournal.pages;
      let newArray = [];
      const indexA = body.current_order - 1;
      const indexB = body.new_order - 1;
      if (indexA > indexB) {
        for (let i = 0; i < indexB; i++) {
          newArray.push(originalArray[i]);
        }
        newArray.push(originalArray[indexA]);
        newArray[indexB].order = indexB + 1;
        for (let i = indexB; i <= indexA - 1; i++) {
          newArray.push(originalArray[i]);
          newArray[i].order = i + 1;
        }
        newArray[indexA].order = indexA + 1;
        for (let i = indexA + 1; i <= originalArray.length - 1; i++) {
          newArray.push(originalArray[i]);
        }
      }
      if (indexA < indexB) {
        for (let i = 0; i < indexA; i++) {
          newArray.push(originalArray[i]);
        }
        for (let i = indexA + 1; i <= indexB; i++) {
          newArray.push(originalArray[i]);
          newArray[i - 1].order = i;
        }
        newArray.push(originalArray[indexA]);
        newArray[indexB].order = indexB + 1;
        for (let i = indexB + 1; i <= originalArray.length - 1; i++) {
          newArray.push(originalArray[i]);
        }
      }
      currentJournal.pages = newArray;
      store.commit("setCurrentJournal", currentJournal);

      utils.changeLastSave();
    } catch (e) {
      console.log(e);
      store.commit("setModalErrorChangeOrder", true);
      store.commit("setOutsideChangeOrderErrorModal", false);
    }
  },
  async copyPage(journalId, pageIdTopaste){
    const pageId = localStorage.pageId;
      await APICONF.post("/journals/"+journalId+"/pages/"+pageId+"/paste-to/"+pageIdTopaste)
      localStorage.removeItem('pageId') 
      if(localStorage.FromKids){
        localStorage.removeItem('FromKids')
      }
      if(localStorage.FromNotKids){
        localStorage.removeItem('FromNotKids')
      }
      
      const currentJournal = await this.getCurrentJournal();
      store.commit("setCurrentJournal", currentJournal)

  }
};
