<template>
  <div
    class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center items-center -mt-10"
    style="z-index: 1000"
  >
    <div
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6"
    >
      <p>
        <b>{{ $t("delete_text_1") }}</b>
      </p>
      <p>
        <b>{{ $t("delete_text_2") }}</b>
      </p>
      <button
        @click="setRemoveText()"
        class="bg-tribugreen-light text-tribugreen-regular w-full py-3 rounded-full mt-5 cursor-pointer"
      >
        <p>{{ $t("delete") }}</p>
      </button>
      <button class="mt-3 cursor-pointer" @click="closeModal">
        {{ $t("cancel") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMessage: true,
    };
  },
  computed: {
    removeText() {
      return this.$store.state.removeText;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("setDeleteModalTextArea", false);
    },
    setRemoveText() {
      this.$store.commit("setRemoveText", true);
      this.$store.commit("setDeleteModal", false);
       this.$store.commit("setDeleteModalTextArea", false);
    },
  },
};
</script>
