<template>
  <div class="mt-12" @click="setSelectedBlock" v-if="displayedPage">
    <div
      class="bg-white z-30 pt-3 pb-3 rounded-tr-3xl rounded-tl-3xl"
      :class="dropdownLayout ? 'h-40 ' : ''"
      style="width: 225px"
    >
    
      <div
        v-if="!dropdownLayout"
        @click="showdropdownLayout"
        class="mx-6 border border-tribugray-semilight rounded-full flex text-tribugray-medium py-2 cursor-pointer hover:bg-gray-50"
        style="width: 170px;"
      >
        <div
          v-if="!numberOfImage"
          class="flex justify-between items-center w-full"
        >
          <p class="pl-2 text-fourteenpix">{{ $t("photos_number") }}</p>
          <i class="fas fa-sort-down mr-3 -mt-1"></i>
        </div>
        <div v-else class="flex justify-between w-full">
          <p
            v-if="numberOfImage === 'all'"
            class="pl-3 text-fourteenpix text-tribugray-medium"
          >
            {{ $t("all") }}
          </p>
          <p
            v-if="numberOfImage === 'text'"
            class="pl-3 text-fourteenpix text-tribugray-medium"
          >
            {{ $t("text") }}
          </p>
          <p
            v-if="numberOfImage === 1"
            class="pl-3 text-fourteenpix text-tribugray-medium"
          >
            {{ numberOfImage }} {{ $t("photo") }}
          </p>
          <p
            v-if="
              numberOfImage !== 1 &&
              numberOfImage !== 'all' &&
              numberOfImage !== 'text'
            "
            class="pl-3 text-fourteenpix text-tribugray-medium"
          >
            {{ numberOfImage }} {{ $t("photos") }}
          </p>
          <i class="fas fa-sort-down mr-6"></i>
        </div>
      </div>
      <div
        v-click-away="onClickAway"
        v-if="dropdownLayout"
        class="mx-6 border border-tribugray-semilight rounded-2xl flex flex-col text-tribugray-medium py-1 cursor-pointer bg-white absolute"
        style="width: 170px; z-index:100"
      >
        <div class="flex justify-between" @click="showdropdownLayout">
          <p class="pl-2 text-fourteenpix text-tribugray-medium">
            {{ $t("photos_number") }}
          </p>
          <i class="fas fa-sort-up mt-2 text-tribugray-medium mr-3"></i>
        </div>
        <div v-if="currentJournal.type !== 'kids'" class="scrollbarDropdawn" style="overflow: auto; max-height:70vh" >
          <div class="mt-1 pb-1">
            <p
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayouts('all')"
            >
              {{ $t("all") }}
            </p>
            <p
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayouts('text')"
            >
              {{ $t("text") }}
            </p>
            <p
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayouts(1)"
            >
              1 {{ $t("photo") }}
            </p>

            <p
              v-for="index in totalImg"
              :key="index"
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayouts(index)"
            >
              {{ index }} {{ $t("photos") }}
            </p>
          </div>
        </div>
        <div v-if="currentJournal.type === 'kids'" :style="window.height < 685?'overflow: auto; height:370px ':''" class="scrollbarDropdawn">
          <div class="mt-1 pb-1">
            <p
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayoutKids('all')"
            >
              {{ $t("all") }}
            </p>
            <p
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayoutKids('text')"
            >
              {{ $t("text") }}
            </p>
            <p
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayoutKids(1)"
            >
              1 {{ $t("photo") }}
            </p>

            <p
              v-for="index in totalImgKids"
              :key="index"
              class="py-1 hover:bg-tribugray-light pl-6"
              @click="setFilteredLayoutKids(index)"
            >
              {{ index }} {{ $t("photos") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--LAYOUT LIST-->
    <div
      class="flex flex-wrap px-6 bg-white overflow-auto scrollbarLayout h-screen"
      style="width: 225px; padding-bottom:200px"
    >

      <div
        v-for="layout in filteredLayouts"
        :key="layout.id"
        class="rounded-sm cursor-pointer relative "
        :class="[
          selectedLayout == layout.id
            ? 'border-2 border-tribugreen-regular'
            : 'border border-tribugray-semilight ',
          currentJournal.type === 'kids'
            ? 'layout-style-kids'
            : 'layout-style ',
        ]"
        @click="changeLayout(layout.id)"
      >
        <img
          @mouseover="hover = layout.id"
          @mouseleave="hover = null"
          :style="
            hover === layout.id && selectedLayout !== layout.id
              ? 'border: 2px solid #ccc'
              : ''
          "
          :src="layout.thumbnail.src"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Layout } from "../api";
import { directive } from "vue3-click-away";
export default {
  name: "LayoutSideBar",
  directives: {
    ClickAway: directive,
  },
  async mounted() {
    const layouts = this.$store.state.layouts;
    this.standardLayouts = layouts.filter(
      (item) =>
        item.is_premium === false &&
        item.type !== "premium" &&
        item.type !== "kids" &&
        item.is_cover === false &&
        item.is_back === false
    );
    this.allLayouts = layouts.filter(
      (item) => item.is_cover === false && item.is_back === false
    );
    this.filteredLayouts = this.standardLayouts;
    if (this.currentJournal.type === "premium") {
      
      this.filteredLayouts = this.allLayouts.filter(
        (item) => item.type !== "kids"
      );
     
    }

    if (this.currentJournal.type === "kids") {
      this.filteredLayouts = layouts.filter(
        (item) =>
          item.type === "kids" &&
          item.is_cover === false &&
          item.is_back === false
      );
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramPage = parseInt(urlParams.get("page"));
    const displayedPage = this.currentJournal.pages.find(
      (item) => item.order === paramPage
    );
    this.$store.commit("setDisplayedPage", displayedPage);
  },
  watch: {
    currentJournal() {
      this.setDisplayedPage();
    },
    selectedPage() {
      this.setDisplayedPage();
      if (this.displayedPage.layout_id) {
        this.selectedLayout = this.displayedPage.layout_id;
      }
    },
    cancelChangeLayout() {
      if (this.cancelChangeLayout === true) {
        if (this.currentLayout) {
          this.selectedLayout = this.currentLayout.id;
          this.$store.commit("setCancelChangeLayout", false);
        }
      }
    },
    async authorisationToChangeLayout() {
      if (this.authorisationToChangeLayout === true) {
        let body = {};
        body["current_layout_id"] = this.displayedPage.layout_id;
        body["new_layout_id"] = this.selectedLayout;
        if (this.currentJournal.type === "premium") {
          if (
            ![1, 2, 3, this.pages.length - 1, this.pages.length].includes(
              this.displayedPage.order
            )
          ) {
            await Layout.updatePageLayout(
              this.currentJournal.id,
              this.displayedPage.id,
              body
            );
          }
        } else {
          if (![1, this.pages.length].includes(this.displayedPage.order)) {
            await Layout.updatePageLayout(
              this.currentJournal.id,
              this.displayedPage.id,
              body
            );
          }
        }
        this.$store.commit("setAuthorisationToChangeLayout", false);
      }
    },
  },
  data() {
    return {
      outsideLayout: true,
      dropdownLayout: false,
      standardLayouts: null,
      allLayouts: null,
      filteredLayouts: null,
      kidsLayout: null,
      numberOfImage: null,
      selectedLayout: null,
      showModal: true,
      hover: false,
      currentLayout: null,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    cancelChangeLayout() {
      return this.$store.state.cancelChangeLayout;
    },
    authorisationToChangeLayout() {
      return this.$store.state.authorisationToChangeLayout;
    },
    pages() {
      return this.$store.state.pages;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    totalImg() {
      return [...Array(21).keys()].filter(
        (item) => ![0, 1].includes(item)
      );
    },
    totalImgKids() {
      return [2, 3, 4, 5, 6, 7, 8, 9];
    },
    selectedPage() {
      return this.$store.state.selectedPage;
    },
    displayedPage() {
      return this.$store.state.displayedPage;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
      handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onClickAway() {
      this.outsideLayout = true;
      this.dropdownLayout = false;
    },
    onClickAwayInfosSideBar() {
      this.$store.commit("setShowModalInfosSelectLayout", false);
    },
    setSelectedBlock() {
      this.$store.commit("setSelectedBlock", { id: null });
    },
    async changeLayout(id) {
      if (this.$store.state.callisDone === true) {
        let changelayout = true;
     /*   if (this.currentJournal.type === "premium") {
          if (
            this.selectedPage === 1 ||
            this.selectedPage === 2 ||
            this.selectedPage === 3 ||
             this.selectedPage === this.currentJournal.pages.length -1 ||
            this.selectedPage === this.currentJournal.pages.length
          ) {
            changelayout = false;
          }
        } 
      if (this.currentJournal.type === "kids") {
          if (
            this.selectedPage === 1 ||
            this.selectedPage === 2 ||
            this.selectedPage === this.currentJournal.pages.length -1 ||
            this.selectedPage === this.currentJournal.pages.length
          ) {
            changelayout = false;
          }
        } 
        else {
          if (
            this.selectedPage === 1 ||
            this.selectedPage === this.currentJournal.pages.length
          ) {
            changelayout = false;
          }
        }*/
        if (changelayout && this.displayedPage) {
          this.selectedLayout = id;
          this.$store.commit("setShowModalInfosSelectLayout", false);
          const currentLayout = this.allLayouts.find(
            (item) => item.id === this.displayedPage.layout_id
          );
          this.currentLayout = currentLayout;
          const newLayout = this.allLayouts.find((item) => item.id === id);

          if (
            newLayout.texts_count < this.displayedPage.blocks.texts.length ||
            newLayout.images_count < this.displayedPage.blocks.images.length
          ) {
            this.$store.commit("setShowLayoutModal", true);
            this.$store.commit("setOutsideLayoutModal", false);
          } else {
            let body = {};
            body["current_layout_id"] = this.displayedPage.layout_id;
            body["new_layout_id"] = id;

            if (this.currentJournal.type === "premium") {
             /*if (
                ![1, 2, 3, this.pages.length - 1, this.pages.length].includes(
                  this.displayedPage.order
                )
              ) {*/
                await Layout.updatePageLayout(
                  this.currentJournal.id,
                  this.displayedPage.id,
                  body
                );
              //}
            } 
            if (this.currentJournal.type === "kids") {
            /*  if (
                ![1, 2, this.pages.length - 1, this.pages.length].includes(
                  this.displayedPage.order
                )
              ) {*/
                await Layout.updatePageLayout(
                  this.currentJournal.id,
                  this.displayedPage.id,
                  body
                );
             // }
            } 
            if (this.currentJournal.type !== "premium" && this.currentJournal.type !== "kids") {
             /* if (![1, this.pages.length].includes(this.displayedPage.order)) {*/
                await Layout.updatePageLayout(
                  this.currentJournal.id,
                  this.displayedPage.id,
                  body
                );
             // }
            }
            this.setDisplayedPage();
          }
        } else {
          this.$store.commit("setShowModalInfosSelectLayout", true);
        }
      }
      if(localStorage.guide && this.$store.state.stepGuide === 4){
        this.$store.commit('setShowGuide',true)
      }
    },
    setDisplayedPage() {
      const displayPage = this.pages.find(
        (item) => item.order === this.selectedPage
      );
      this.$store.commit("setDisplayedPage", displayPage);
    },
    showdropdownLayout() {
      this.dropdownLayout = !this.dropdownLayout;
    },
    setFilteredLayouts(choice) {
      this.dropdownLayout = false;
      this.numberOfImage = choice;
      if (this.currentJournal.type !== "premium") {
           
        if (choice === "all") {
          this.filteredLayouts = this.standardLayouts;
          return;
        }
        if (Number.isInteger(choice)) {
          this.filteredLayouts = this.standardLayouts.filter(
            (item) => item.images_count === choice
          );
          return;
        }
        if (choice === "text") {
          this.filteredLayouts = this.standardLayouts.filter(
            (item) => item.texts_count > 0
          );
          return;
        }
      }

      if (this.currentJournal.type === "premium") {

        if (choice === "all") {
          this.filteredLayouts = this.allLayouts.filter(
            (item) => item.type !== "kids"
          );
           
          return;

        }

        if (Number.isInteger(choice)) {
          this.filteredLayouts = this.allLayouts.filter(
            (item) => item.images_count === choice
          );
          this.filteredLayouts = this.filteredLayouts.filter(
            (item) => item.type !== "kids"
          );
          return;
        }

        if (choice === "text") {
          this.filteredLayouts = this.allLayouts.filter(
            (item) => item.texts_count > 0 
          );
          this.filteredLayouts = this.filteredLayouts.filter(
            (item) => item.type !== 'kids'
          );
          return;
        }
      
      }
    },
    setFilteredLayoutKids(choice) {
      this.dropdownLayout = false;
      this.numberOfImage = choice;
      const layouts = this.$store.state.layouts;
      const allLayoutkids = layouts.filter(
        (item) =>
          item.type === "kids" &&
          item.is_cover === false &&
          item.is_back === false
      );
      if (choice === "all") {
        this.filteredLayouts = allLayoutkids;
        return;
      }

      if (Number.isInteger(choice)) {
        this.filteredLayouts = allLayoutkids.filter(
          (item) => item.images_count === choice
        );
        return;
      }

      if (choice === "text") {
        this.filteredLayouts = allLayoutkids.filter(
          (item) => item.texts_count > 0
        );
        return;
      }
    },
  },
};
</script>
<style>
.layout-style {
  width: 80px;
  height: 113px;
  margin: 5px auto;
}
.layout-style:hover {
  background-color: #ccc;
}
.layout-style-kids {
  width: 80px;
  height: 80px;
  margin: 5px auto;
}
.layout-style-kids:hover {
  background-color: #ccc;
}

/* CUSTOM SCROLLBAR */
.scrollbarLayout::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.4);
}
.scrollbarLayout {
  scrollbar-color: #f7f6f6 #fff !important;
  -moz-appearance: none !important;
}
.scrollbarLayout::-webkit-scrollbar-thumb {
  background: #e0dcdc !important;
  border-radius: 10px;
}
.scrollbarDropdawn::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.4);
}
.scrollbarDropdawn {
  scrollbar-color: #f7f6f6 #fff !important;
  -moz-appearance: none !important;
}
.scrollbarDropdawn::-webkit-scrollbar-thumb {
  background: #f7f6f6 !important;
  border-radius: 10px;
}
</style>
