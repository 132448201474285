<template>
  <div
    class="
      w-full
      h-screen
      bg-tribugreen-light
      flex flex-col
      items-center
      pt-10
      font-inter
    "
  >
    <img src="../../assets/logo-black.svg" class="w-10 mb-5" />
    <div class="bg-white rounded-xl shadow-sm p-7" style="width: 370px">
      <div class="text-center"></div>
      <div class="flex flex-col items-center">
        <div class="font-bold mb-5" style="font-size:24px">{{ $t("reset_password") }}</div>
        <input
          type="email"
          placeholder="E-mail"
          v-model="email"
          class="input-form"
        />
        <input
          type="password"
          :placeholder="$t('password')"
          v-model="password"
          class="input-form mt-2"
          required
        />
        <input
          type="password"
          :placeholder="$t('confirm_password')"
          v-model="confirmPassword"
          class="input-form mt-2"
          required
        />
         <div v-if="errorMessage" class="text-sm text-red-400 mt-3 text-center">{{$t(errorMessage)}}</div>

        <button
          @click="changePassword()"
          class="w-full bg-tribugreen-regular hover:bg-tribugreen-medium text-white text-sm py-3 rounded-full mt-4"
        >
          {{ $t("reset_password") }}
        </button>
      </div>
    </div>
    <div class="flex justify-between text-xs px-4 pt-3" style="width:370px">
      <div @click="goToSignin" class="cursor-pointer hover:text-gray-500">{{ $t("login") }}</div>
      <div class="cursor-pointer hover:text-gray-500" @click="goToSignup">{{ $t("signup") }}</div>
    </div>
    <div class="font-semibold text-xs flex mt-10">
      <div
        @click="setLanguage('FR')"
        class="px-2 cursor-pointer"
        :class="language === 'FR' ? 'text-tribugreen-regular' : ''"
      >
        FR
      </div>
      <div
        @click="setLanguage('NL')"
        class="px-2 cursor-pointer"
        :class="language === 'NL' ? 'text-tribugreen-regular' : ''"
      >
        NL
      </div>
      <div
        @click="setLanguage('EN')"
        class="px-2 cursor-pointer"
        :class="language === 'EN' ? 'text-tribugreen-regular' : ''"
      >
        EN
      </div>
    </div>
  </div>
</template>
<script>
import redirect from '../../libraries/redirect'

export default {
  name: "ChangePassword",
    beforeMount(){
    if(localStorage.token){
      this.$router.push({name: "Workspace"})
    }
  },
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      errorMessage:''
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  methods: {
    async changePassword() {
      if (!this.password || !this.confirmPassword || !this.email) {
        this.$notify({
          title: this.$t("complete_all_info"),
        });
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.$notify({
          title: this.$t("wrong_password_confirmation"),
        });
        if(this.isMobile){
          this.errorMessage = "wrong_password_confirmation"
        }
        return;
      }
      if (this.password.length <8) {
        this.$notify({
          title: this.$t("eight_characters"),
        });
        if(this.isMobile){
          this.errorMessage = "eight_characters"
        }
        return;
      }
      const params = new URLSearchParams(window.location.search)
      const token = params.get('data');
      try {
        const resetRoot = import.meta.env.VITE_API_RESET_PASSWORD;
        await this.$http.post(resetRoot, {
          token: token,
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword
        })
        this.$notify({
          title: this.$t("password_updated")
        })
        await redirect()
        setTimeout(this.$router.push({name:"Login"}),1000)
      } catch (err) {
        this.$notify({
          title: this.$t("server_error")
        })
      }
    },
    setLanguage(lang) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramLang = urlParams.get("lang");
      if (paramLang) {
        window.location.href = window.location.origin;
      }
      this.$store.commit("setLanguage", lang);
      this.$i18n.locale = lang;
    },
    goToSignup() {
      this.$router.push({name:"SignUp"});
    },
    goToSignin() {
      this.$router.push({name:"Login"});;
    },
    sendLink() {
        this.$notify({
          title: this.$t("email_sent"),
        });
    },
  },
};
</script>
