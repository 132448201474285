import router from "../router";
import store from "../store";

async function routerRedirect(webRedirectUrl) {
    if (Object.keys(webRedirectUrl).length === 0) {
        return
    }

    return router.push(webRedirectUrl);
}

export default async function redirect(webRedirectUrl = {}, androidTimeoutUrl = null, IPhoneRedirectUrl = null) {
    const userAgent = navigator.userAgent;
    const isAndroid = userAgent.match(/Android/)
    const isIPhone = userAgent.match(/iPhone/)
    const isIPad= userAgent.match(/iPad/)
    if (! isAndroid || ! isIPhone || !isIPad) {
        await routerRedirect(webRedirectUrl)
        return
    }

    if (isAndroid && router.currentRoute.value.query.redirect) {
        window.location.replace(router.currentRoute.value.query.redirect.toString());

        if (! androidTimeoutUrl) {
            androidTimeoutUrl = 'https://play.google.com/store/apps/details?id=be.mytribunews.app'
        }

        setTimeout(() => {window.location.replace(androidTimeoutUrl)}, 700)
        return
    }

    if (isIPhone && router.currentRoute.value.query.redirect) {
        window.location.replace(router.currentRoute.value.query.redirect.toString())

        if (! IPhoneRedirectUrl) {
            IPhoneRedirectUrl = `https://apps.apple.com/${store.state.language}/app/tribu-news/id1474359727`
        }
        setTimeout(() => {window.location.replace(IPhoneRedirectUrl)}, 700)
        return
    }
    if (isIPad && router.currentRoute.value.query.redirect) {
        window.location.replace(router.currentRoute.value.query.redirect.toString())

        if (! IPhoneRedirectUrl) {
            IPhoneRedirectUrl = `https://apps.apple.com/${store.state.language}/app/tribu-news/id1474359727`
        }
        setTimeout(() => {window.location.replace(IPhoneRedirectUrl)}, 700)
        return
    }

    await routerRedirect(webRedirectUrl);
}
