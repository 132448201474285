<template>
  <div
    v-if="currentTribe"
    class="bg-tribugreen-dark text-white flex justify-between px-3 py-3"
    :style="showImportPage?'height: 80px': 'height: 50px'"
    @click="setSelectedBlock"
  >
    <div class="bottom-0 right-0 " style=" z-index:1000000000!important; margin-bottom:20px;position: fixed!important" v-if="showCrispWindow">
        <img class="absolute right-0 mt-3 mr-4 cursor-pointer" style="width:20px;height:20px" @click="closeCrispWindow()" src="../assets/icons/close-white.svg"/>
          <iframe
            style="height:450px!important;width:350px!important; border-radius:2%"
            src="https://go.crisp.chat/chat/embed/?website_id=1d03008e-c78d-4645-9ef9-cbf8ed1a3f74"
            frameborder="0" >
           </iframe>
    </div>
    <div class="text-eighteenpix font-bold flex">
      <img
        alt="Tribu logo"
        src="../assets/logo.svg"
        :style="showImportPage?'width: 60px':'width: 100px'"
        class="ml-2 mr-10"
        @click='goToWebsite()'
      />
      <div class="flex items-center">
        <div
          class="flex items-center cursor-pointer hover:text-tribugreen-medium"
          v-click-away="onClickAwayMenu"
          @click="showdropdownMenu"
          :style="showImportPage?'max-width:170px; word-break: break-all;line-height: 14px;':''"
        >
          <p>{{ currentTribe.name }}</p>
          <i class="fas fa-sort-down ml-1 -mt-1" style="font-size: 10px"></i>
        </div>
        <div
          class="absolute shadow left-0 ml-40 top-0 bg-white text-black border py-2 overflow-auto rounded shadow-sm menu-container"
          style="margin-top: 50px; z-index: 60"
          v-if="dropdownMenu && !outsideMenu"
        >
          <div>
            <div
              v-for="tribe in user.tribes"
              :key="tribe.id"
              class="cursor-pointer pr-10 pl-4 py-2 hover:bg-gray-50 flex text-sm"
              :class="
                currentTribe.name === tribe.name ? 'font-bold' : 'font-normal	'
              "
              @click="
                changeTribe(tribe.id);
                showdropdownMenu();
              "
            >
              <div class="flex items-center">
                <p>{{ tribe.name }}</p>
                <div
                  v-if="tribe.is_subscribed"
                  class="bg-tribugreen-regular h-2 w-2 rounded-full ml-2"
                ></div>
              </div>
            </div>
            <hr class="my-2" />
            <p class="pb-1 mt-2 pr-10 pl-4 hover:bg-gray-50 text-sm">
              <a target="_blank" :href="addTribe.link">{{ addTribe.label }}</a>
            </p>
          <div
          @click="logout()" v-if="showImportPage"
          class="pb-1 pr-10 pl-4 cursor-pointer border-t  pt-3"
          style="font-size:14px"
        >
          {{ $t("logout") }}
        </div>
          </div>
        </div>
        <div
          v-if="!currentTribe.is_subscribed && !showImportPage"
          class="mx-7 py-1 cursor-pointer bg-white font-normal text-sm text-tribugreen-regular rounded-full px-4 hover:bg-tribugreen-medium hover:text-white"
        >
          <a v-if="subscribeMenu" :href="subscribeMenu.link">
            {{ $t("header_subscription") }}
          </a>
        </div>
        <p v-if="!showImportPage"
          class="text-tenpix pt-1 cursor-default flex items-center"
          :class="!currentTribe.is_subscribed ? '' : 'ml-10'"
        >
          {{ $t("last_save") }}
          <span class="text-tribugreen-medium ml-1">{{ savedDate }}</span>
          <span
            class="text-xs ml-2 hover:text-tribugreen-regular"
            style="padding-top: 1px"
            @click="
              showInfosSave = true;
              outsideShowInfos = false;
            "
            ><i class="fas fa-info-circle"></i
          ></span>
        </p>
      </div>
    </div>
    <div class="flex text-base font-normal">

      <!--<div class="mx-10 cursor-pointer">{{ $t("header_guide") }}</div>-->
          <div
          v-click-away="onClickAwayMenuDashboard"
          v-if="!showImportPage"
          @click="showdropdownMenuDashboard"
          class="mx-7 py-1 cursor-pointer flex items-center font-normal text-sm text-white rounded-full px-4  hover:text-tribugreen-medium"
        >
          <p>
            {{ $t("dashboard") }}
          </p>
          <i class="fas fa-sort-down ml-1 -mt-1" style="font-size: 10px"></i>
        </div>
    <div
        :style="user.tribes.length > 2 ? 'max-height:600px' : ''"
        class="absolute right-0 mr-60 bg-white text-black border py-2 overflow-auto rounded shadow-sm mt-8 z-40 menu-container"
        v-if="dropdownMenuDashboard && !outsideMenuDashboard"
      >
        <div>
          <div class="mt-2 flex flex-col" v-for="menu in menu1" :key="menu.key">
            <a
              target="_blank"
              :href="menu.link"
              class="py-1 hover:bg-gray-50 pr-10 pl-4"
              >{{ menu.label }}</a
            >
          </div>
        </div>
        <div class="border-t mb-4">
          <div class="mt-2 flex flex-col" v-for="menu in menu2" :key="menu.key">
            <a
              target="_blank"
              :href="menu.link"
              class="py-1 hover:bg-gray-50 pr-10 pl-4"
              >{{ menu.label }}</a
            >
          </div>
        </div>

        <div
          @click="logout()"
          class="pb-1 pr-10 pl-4 cursor-pointer border-t -mt-2 pt-3"
        >
          {{ $t("logout") }}
        </div>
      </div>
      <div class="mx-5 cursor-pointer" @click="openCrispWindow()" v-if="!showImportPage">
        <p class="hover:text-tribugreen-medium">{{ $t("help") }}</p>
      </div>
      <div class="mx-10 cursor-pointer" v-click-away="onClickAwayLanguage" :style="showImportPage?'padding-top:15px':''">
        <p
          @click="showdropdownLanguage"
          class="flex items-center hover:text-tribugreen-medium"
        >
          {{ language }}
          <i class="fas fa-sort-down ml-1 -mt-1" style="font-size: 10px"></i>
        </p>
        <div
          v-if="dropdownLanguage && !outsideLang"
          class="absolute bg-white text-black border py-2 pr-14 -ml-16 rounded pl-4 shadow-sm z-50"
          
        >
          <div v-for="lang in langs" :key="lang">
            <p @click="setLanguage(lang)" v-if="language !== lang" class="my-1">
              {{ lang }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showInfosSave"
      v-click-away="onClickAwayShowInfos"
      class="absolute z-50 flex flex-col items-center"
      style="margin-left: 230px; margin-top: 33px"
    >
      <div>
        <i
          class="fas fa-sort-up text-tribugreen-regular text-xl absolute -mt-3 cursor-pointer"
        ></i>
      </div>
      <div
        class="text-tenpix bg-tribugreen-regular text-white w-72 shadow rounded-xs px-4 py-4 cursor-default flex items-center"
      >
        <div>
          <p>{{ $t("last_save_explaination_1") }}</p>
          <p class="mt-1">{{ $t("last_save_explaination_2") }}</p>
          <p class="mt-1">
            <span class="cursor-pointer" @click="reloadPage"
              ><b>{{ $t("last_save_explaination_3") }}</b></span
            >
            {{ $t("last_save_explaination_4") }}
          </p>
        </div>
        <div
          class="ml-3 cursor-pointer"
          @click="
            showInfosSave = false;
            outsideShowInfos = true;
          "
        >
          <img class="w-10 h-10 opacity-60" src="../assets/icons/cross.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tribes, User } from "../api";
import { directive } from "vue3-click-away";
import moment from "moment/min/moment-with-locales";
import store from "../store";
import * as Sentry from "@sentry/vue";
import utils from '../utils';
export default {
  directives: {
    ClickAway: directive,
  },
  name: "AppHeader",
  async mounted() {
    this.menuObject = await User.getMenu();
    if (this.menuObject) {
      this.subscribeMenu = this.menuObject.find(
        (item) => item.key === "subscription"
      );
    }
    this.$store.commit(
      "setUpdateAtJournal",
      this.$store.state.currentJournal.updated_at
    );
    if (
      moment(this.$store.state.updateAtJournal).fromNow() === "Invalid date"
    ) {
      this.savedDate = "";
    } else {
      if (this.language === "FR") {
        moment.locale("fr");
        this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
      }
      if (this.language === "EN") {
        moment.locale("en");
        this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
      }
      if (this.language === "NL") {
        moment.locale("nl");
        this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
      }
      if (this.language === "DE") {
        moment.locale("de");
        this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
      }
    }
    setInterval(function () {
      store.commit("setReloadHeader", true);
    }, 10000);
  },

  props: {
    user: Object,
  },
  data() {
    return {
      componentKey: 0,
      subscribeMenu: null,
      dropdownLanguage: false,
      dropdownMenu: false,
      dropdownMenuDashboard:false,
      langs: ["FR", "NL", "EN", "DE"],
      menuObject: null,
      hover: false,
      outsideMenu: true,
      outsideMenuDashboard:true,
      outsideLang: true,
      outsideShowInfos: true,
      showInfosSave: false,
      timer: "",
      textLastSave: "",
      savedDate: "",
      showCrispWindow:false
    };
  },
  watch: {
    
    reloadHeader() {
      if (this.reloadHeader) {
        if (this.language === "FR") {
          moment.locale("fr");
          this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
        }
        if (this.language === "EN") {
          moment.locale("en");
          this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
        }
        if (this.language === "NL") {
          moment.locale("nl");
          this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
        }
        if (this.language === "DE") {
          moment.locale("de");
          this.savedDate = moment(this.$store.state.updateAtJournal).fromNow();
        }
        this.$store.commit("setReloadHeader", false);
      }
    },
  },
  computed: {
    showImportPage(){
      return this.$store.state.showImportPage
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    reloadHeader() {
      return this.$store.state.reloadHeader;
    },
    addTribe() {
      if (this.menuObject) {
        return this.menuObject.find((item) => item.key === "add_tribe");
      }
    },
    menu1() {
      return this.menuObject.filter(
        (item) =>
          item.key !== "add_tribe" &&
          item.key !== "account" &&
          item.key !== "account" &&
          item.key !== "billing_information" &&
          item.key !== "referral" &&
          item.key !== "logout" &&
          item.key !== "terms" &&
          item.key !== "faq"

      );
    },
    menu2() {
      if (this.currentTribe.is_subscribed) {
        return this.menuObject.filter(
          (item) =>
            item.key !== "add_tribe" &&
            item.key !== "list_members" &&
            item.key !== "archives" &&
            item.key !== "subscription" &&
            item.key !== "shipping" &&
            item.key !== "album" &&
            item.key !== "logout" &&
            item.key !== "terms"
        );
      } else {
        return this.menuObject.filter(
          (item) =>
            item.key !== "add_tribe" &&
            item.key !== "list_members" &&
            item.key !== "archives" &&
            item.key !== "subscription" &&
            item.key !== "shipping" &&
            item.key !== "album" &&
            item.key !== "referral" &&
            item.key !== "logout" &&
            item.key !== "terms"
        );
      }
    },
    addTribeMenu() {
      if (this.menuObject) {
        return this.menuObject.find((item) => item.key === "account");
      }
    },
    accountMenu() {
      if (this.menuObject) {
        return this.menuObject.find((item) => item.key === "account");
      }
    },
    billingInformationMenu() {
      if (this.menuObject) {
        return this.menuObject.find(
          (item) => item.key === "billing_information"
        );
      }
    },
    referralMenu() {
      if (this.menuObject) {
        return this.menuObject.find((item) => item.key === "referral");
      }
    },
    language() {
      return this.$store.state.language;
    },

    currentTribe() {
      const currentTribeInfos = this.getCurrentTribe();
      this.$store.commit("setCurrentTribe", currentTribeInfos);

      return currentTribeInfos;
    },
    lastSave() {
      let lastSave = this.$store.state.updateAtJournal;
      if (moment(lastSave).fromNow() === "Invalid date") {
        return (lastSave = "");
      }
      if (this.language === "FR") {
        moment.locale("fr");
        return (lastSave = moment(lastSave).fromNow());
      }
      if (this.language === "EN") {
        moment.locale("en");
        return (lastSave = moment(lastSave).fromNow());
      }
      if (this.language === "NL") {
        moment.locale("nl");
        return (lastSave = moment(lastSave).fromNow());
      }
      if (this.language === "DE") {
        moment.locale("de");
        return (lastSave = moment(lastSave).fromNow());
      }
    },
  },

  methods: {

    onClickAwayShowInfos() {
      this.showInfosSave = false;
      this.outsideShowInfos = true;
    },
    onClickAwayMenu() {
      if(!this.showImportPage){
      this.outsideMenu = true;
      this.dropdownMenu = false;
      }

    },
    onClickAwayMenuDashboard(){
      this.outsideMenuDashboard = true;
      this.dropdownMenuDashboard = false;
    },
    onClickAwayLanguage() {
      this.outsideLang = true;
    },
    openCrispWindow() {
     this.showCrispWindow = true;
    let crispIcon= document.getElementsByClassName('cc-dc5e')[0]
    crispIcon.style.opacity =0;
    },
    closeCrispWindow(){
     this.showCrispWindow = false;
    let crispIcon= document.getElementsByClassName('cc-dc5e')[0]
    crispIcon.style.opacity =100;
    },
    reloadPage() {
      document.location.reload();
    },
    setSelectedBlock() {
      this.$store.commit("setSelectedBlock", { id: null });
    },
    showdropdownLanguage() {
      this.outsideLang = false;
      this.dropdownLanguage = !this.dropdownLanguage;
      if (this.dropdownMenu) {
        this.dropdownMenu = false;
      }
    },
    showdropdownMenu() {
      this.outsideMenu = false;
      this.dropdownMenu = !this.dropdownMenu;
      if (this.dropdownLanguage) {
        this.dropdownLanguage = false;
      }
    },
    showdropdownMenuDashboard() {
      this.outsideMenuDashboard = false;
      this.dropdownMenuDashboard = !this.dropdownMenuDashboard;
      if (this.dropdownLanguage) {
        this.dropdownLanguage = false;
      }
    },
    async setLanguage(language) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramLang = urlParams.get("lang");
      if (paramLang) {
        window.location.href = window.location.origin;
      }
      this.$store.commit("setLanguage", language);
      this.$i18n.locale = language;
      this.dropdownLanguage = false;
      this.menuObject = await User.getMenu();
      this.$store.commit("setReloadHeader", true);
    },
    getCurrentTribe() {
      if (this.user.tribes) {
        const tribes = this.user.tribes;
        let currentTribe = tribes.find(
          (item) => item.id == this.user.current_tribe
        );
        if (currentTribe) {
          this.$store.commit("setMembers", currentTribe.members);
        }
        return currentTribe;
      }
    },
    changeTribe(id) {
   
      Tribes.switchTribe(id);
      this.getCurrentTribe(id);
    },
    goToWebsite(){
      if(this.showImportPage){
        window.location.href = "https://mytribunews.com/fr"
      }
    },
    logout() {

      localStorage.removeItem("token")
      Sentry.configureScope(scope => scope.setUser(null))
      utils.event('logout')

      document.location.reload();
    },
  },
};
</script>
<style>
/* CUSTOM SCROLLBAR */
.menu-container::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.4);
}
.menu-container::-webkit-scrollbar-thumb {
  background: #f7f6f6 !important;
  border-radius: 10px;
}
</style>
