<template>
  <div
    class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center items-center z-40"
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6 relative " 
    >
        <img class="w-4 h-4 absolute right-0 mr-7 cursor-pointer" @click="setShowDashboard()" src="../assets/icons/cross-gray.svg"/>
      <div  class="text-gray-500">
        <p>{{ $t("what_you_want") }}</p>
      </div>
      <div class="flex mt-5">
        <div
          class="rounded-xl shadow pb-4 flex flex-col items-center py-3 px-5"
          style="width: 220px; height:220px"
        >
          <img src="../assets/icons/members.svg" class="w-12" />
        <p class="text-xs  mt-4">{{ $t("members_text") }}</p>
        <div  @click="changeType('members')"
          v-if="linkMembers"

            class="absolute bottom-0 mb-10 bg-tribugreen-regular text-white rounded-full py-1 px-3 hover:bg-tribugreen-medium  hover:text-tribugreen-regular cursor-pointer text-sm"
          >
            {{ $t("members_button") }}
         
        </div>
        </div>
        <div
          class="rounded-xl pb-4 shadow white flex flex-col items-center mx-5 py-3 px-5"
          style="width: 220px"
  
        >
          <img src="../assets/icons/mailbox.svg" class="w-11" />
          <p class="text-xs mt-4">{{ $t("mailbox_text") }}</p>
        <div  @click="changeType('subscription')"
          v-if="linkSubscription"
           :href="linkSubscription.link"
            class="absolute bottom-0 mb-10 bg-tribugreen-regular text-white rounded-full py-1 px-3 hover:bg-tribugreen-medium hover:text-tribugreen-regular cursor-pointer text-sm"
          >
            {{ $t("mailbox_button") }}
        </div>
        </div>
        <div
          class="rounded-xl shadow white pb-4 flex flex-col items-center py-3 px-5"
          style="width: 220px"
        >
          <img src="../assets/icons/book-green.svg" class="w-10 mt-2" />

          <p class="text-xs mt-4">"{{ $t("fill_album") }}"</p>
          <div
         @click="changeType('change')"
            class="absolute bottom-0 mb-10 bg-tribugreen-regular text-white rounded-full py-1 px-3 hover:bg-tribugreen-medium hover:text-tribugreen-regular cursor-pointer text-sm"
          >
            {{ $t("album_button") }}
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";
import { User, Journal } from "../api";
export default {
  directives: {
    ClickAway: directive,
  },
  async mounted(){
   this.menuObject = await User.getMenu();
   const linkMembers = this.menuObject.find(item => item.key==="list_members")
   this.linkMembers = linkMembers
    const linkSubscription = this.menuObject.find(item => item.key==="subscription")
   this.linkSubscription = linkSubscription
  },
  data(){
    return{
        menuObject:null,
        linkMembers:null,
        linkSubscription:null
    }
  },
  computed: {
    type() {
      return this.$store.state.type;
    },
    chosenType(){
      return this.$store.state.chosenType
    }
  },
  methods: {
    onClickAway() {
     this.changeType()
    },
    setShowDashboard(){
      this.$store.commit('setShowDashboard',false)
    },
    async changeType(link){
      const type = this.chosenType
      if(link === 'members'){
        window.location.href = this.linkMembers.link
        return
      }
      if(link === 'subscription'){
        window.location.href = this.linkSubscription.link
        return
      }
      if(link === 'change'){
        if(this.chosenType !== 'standard'){
        await Journal.changeType(this.chosenType)
        document.location.reload()
        }else{
         document.location.reload()
        }
       return
      }
      }
    
  },
};
</script>
