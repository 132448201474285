<template>
  <div
    class="
      absolute
      w-full
      bg-black bg-opacity-50
      h-screen
      flex
      justify-center
      items-center
    "
    style="z-index:1000"
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center py-7 -mt-24 relative text-center"
    
      style="max-width:400px"
    > 
    <div class="flex items-center text-tribugreen-regular font-bold border-b border-gray-100" style="font-size:20px"   :class="isMobile?' px-2':' px-12'">
        <img src="../assets/img/clock.gif" style="width:50px"/>
        <p class="ml-4">{{$t('reminder_title')}}</p>
    </div>  
    <div v-if="days > 1" style="width:300px" class="my-5 font-bold" :class="isMobile?' px-2':' px-12'">
        <p class="">{{$t('reminder_text1')}} <span class="font-bold text-tribugreen-regular " style="font-size:20px">{{days}} {{$t('reminder_text2')}}</span>  {{$t('reminder_text2_2')}} </p>
    </div>
    <div v-if="days === 1"  class="mb-5" :class="isMobile?' px-2':' px-12'">
        <p class="font-bold text-base mt-5">{{$t('reminder_text3')}}</p>
        <p class="font-bold text-base text-tribugreen-regular mt-5"> {{$t('reminder_text4')}}</p>
    </div>

    <div class="flex mt-4" style="font-size:12px">
        <input type="checkbox" id="checkbox" v-model="checked" />
        <label for="checkbox" class="ml-3">{{ $t('reminder_checkbox') }}</label>
    </div>
    <button
        @click="saveChoice"
        class="
          bg-tribugreen-light
          text-tribugreen-regular
          w-full
          py-2
          rounded-full
          mt-5
          cursor-pointer
          hover:bg-tribugreen-regular
          hover:text-white
          px-3
        "
        style="width:250px"
      >
        {{$t("reminder_button")}}
      </button>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";

export default {
  directives: {
    ClickAway: directive
  },
  props:{
    isMobile:Boolean,
    days:Number
   
    
  },
  data(){
    return{
      checked:false,
    }
  },

  methods: {
    secondUntilMidnight() {
    var midnight = new Date();
    midnight.setHours( 24 );
    midnight.setMinutes( 0 );
    midnight.setSeconds( 0 );
    midnight.setMilliseconds( 0 );
    return ( midnight.getTime() - new Date().getTime() );
    },
    onClickAway() {
        this.closeModale()

    },
    closeModale(){
        this.$store.commit('setShowModalEndMonth',false)
    },
    goToMember(){
        window.location.href=this.link
    
    },
    saveChoice(){
        if(this.checked){
            localStorage.showmodal = false
        }
        this.$store.commit('setShowModalEndMonth',false)
    }


  },
};
</script>
