import axios from 'axios';
import store from '../store'
import APICONF from "../api-config";
import router from "../router";
import redirect from '../libraries/redirect';
const apiRoot = import.meta.env.VITE_APP_OLDURL + '/api';
const headers = {
    "X-WEB": "true",
};
export default {
    async signIn(email, password) {
        const loginRoot = import.meta.env.VITE_API_LOGIN;
        const body = {
            email: email,
            password: password,
        };
        try {
            const response =  await axios.post(`${loginRoot}`, body, {headers});
            if (response.status === 200) {
                localStorage.token = response.data.token;
                store.state.loginMethod = 'manual'
                APICONF.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
                    await redirect()
                    await router.push({
                      path: '/',
                      query: router.currentRoute.value.query
                    })
            } else {
    
                document.location.reload();
            }
        } catch (e) {
            store.commit('setLoginError',true)
            store.commit("setErrorMessage", "error_signin")
        }
    },
  
    async signup(firstname, lastname, email, password, password_confirmation) {
        let code = null
        try {
            if(localStorage.code){
                code = localStorage.code
            }
            const response = await axios.post(`${apiRoot}/register`, {
                firstname: firstname,
                lastname: lastname,
                email: email,
                password: password,
                password_confirmation: password_confirmation,
                lang: store.state.language,
                codetribe:code
            })
            if (response.data) {
                localStorage.token = response.data.token;
                return localStorage.token
            }
            localStorage.token = token;
            APICONF.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            
          
        } catch (e) {
            store.commit('setSignupError',true)
            if(e.response.data.message ==="account already exists"){
                store.commit("setErrorMessage", "account_already_exists")
            }else{
             store.commit("setErrorMessage", "error_signup")
            }
           
        }
    },
  
    async forgetPassword(email) {
        const forgetRoot = import.meta.env.VITE_API_FORGOT_PASSWORD;
        try {
            return await axios.post(forgetRoot, {email: email});
        } catch (e) {
            store.commit("setErrorMessage", "error_forget_password")
        }
    },

    async useSocialLogin(provider, code, hash) {
        let codetribe = null
  
        try {
            if(localStorage.code){
                codetribe = localStorage.code
            }
            const response = await axios.post(`${apiRoot}/web/oauth/${provider}/callback`, {
                code: code,
                hash: hash,
                codetribe:codetribe
            })
            if (response.data.token !== undefined) {


                localStorage.token = response.data.token;
                store.state.loginMethod = provider
                APICONF.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
          
            } else {
                document.location.reload()
            }
        } catch (err) {
            console.log('error',err)
            store.commit("setErrorMessage", "error_social_login")
        }
    }

};
