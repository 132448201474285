import APICONF from "../api-config";
import store from "../store";
import axios from 'axios';

export default {
  async switchTribe(id) {
    try{
      const root = import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
    const res = localStorage.impersonate_token? await axios.post(`${root}/switch-tribe/${id}`,{headers}):await APICONF.post(`/switch-tribe/${id}`);
    const newTribe = res.data;
    store.commit("setMembers", newTribe);
   document.location.reload()

    return newTribe;
    }catch(e){
      store.commit("setErrorMessage", "error_switch_tribe")  

    }
  },
  async joinTribeWithCode(code){
    const root = import.meta.env.VITE_API_ROOT
    const headers= {
        Authorization: "Bearer " + localStorage.token,
      'Content-Type' : 'application/json',
       'X-WEB' : 'true' 
     }
    try{
      const res = await axios.post(root + "/join-tribe",{'code':code},{headers})
      return res.data

    }catch(e){

    }
  },
  async getTribeWithCode(code){
    const root = import.meta.env.VITE_API_ROOT
    const headers= {
      'Content-Type' : 'application/json',
       'X-WEB' : 'true' 
     }
    try{
      const res = await axios.get(`${root}/tribes-invitation/${code}`,{headers})
      return res.data
    }catch(e){

    }
  },
  async inviteMembersByMail(emails){
    const root = import.meta.env.VITE_API_ROOT
    const headers= {
      'Content-Type' : 'application/json',
       'X-WEB' : 'true' 
     }
    const email = emails
    await APICONF.post(root+'/email-invite-members',{'emails':email},{headers});
  },
  async getInvitationCode(){
          const res = await APICONF.get(`/share-invite-code`);
          return res.data   
  },
  async editTribeName(name){
    try{
        await APICONF.put('/current-tribe',{ name : name});        

    }catch(e){
        console.log(e)
    }
},
};
