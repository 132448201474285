<template>
  <div style="margin-top:-3px!important">
    <display-images v-if="!showFilters"></display-images>
    <filters-images v-else></filters-images>
  </div>
</template>
<script>
import DisplayImages from "./DisplayImages/DisplayImages.vue";
import FiltersImages from "./DisplayImages/FiltersImages.vue";
export default {
  name: "SidebarImages",

  data() {
    return {};
  },
  components: {
    DisplayImages,
    FiltersImages,
  },
  computed: {
    showImportPage(){
      return this.$store.state.showImportPage
    },
    showFilters() {
      return this.$store.state.showFilters;
    },
  },
};
</script>
