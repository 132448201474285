<template>
  <div
    class="
      absolute
      w-full
      bg-black bg-opacity-50
      h-screen
      flex
      justify-center
      items-center
      -mt-10
      z-40
    "
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6"
    >
    <p class="mt-3">{{$t("nbr_of_block_layout_1")}}</p>
    <p class="mb-3">{{$t("nbr_of_block_layout_2")}}</p>
      <button
      @click="changeLayout"
        class="
          bg-tribugreen-light
          text-tribugreen-regular
          w-full
          py-3
          rounded-full
          mt-5
          cursor-pointer
        "
      >
        <p>{{ $t("continue") }}</p>
      </button>
      <button class="mt-3 cursor-pointer" @click="setLayoutModal">
        {{ $t("cancel") }}
      </button>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";

export default {
  directives: {
    ClickAway: directive
  },
  methods: {
    onClickAway() {
        this.setLayoutModal()

    },
    setLayoutModal(){
      this.$store.commit("setOutsideLayoutModal",true)
      this.$store.commit("setShowLayoutModal", false);
      this.$store.commit("setCancelChangeLayout", true)
    },
    changeLayout(){
        this.$store.commit("setAuthorisationToChangeLayout",true)
        this.setLayoutModal()
    }
  },
};
</script>
