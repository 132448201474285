<template>
  <div class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center items-center z-40">
    <div v-if="step === 1">
      <div class="arrow-left-1"></div>
      <div class="bubble-1">
        <img style="width:35px" class="mx-auto py-5" src="../assets/icons/chat_smile.svg"/>
        <p>{{$t('guide_1_a')}}</p>
        <p>{{$t('guide_1_b')}}</p>
        <p>{{$t('guide_1_c')}}</p>
        <div class="flex flex-col items-center py-2">
          <button @click="nextStep(2)" style="width:200px" class="mt-3 bg-tribugreen-regular py-1 text-white rounded-full hover:bg-tribugreen-medium">OK</button>
          <button @click="skip()" class="underline mt-2 hover:text-tribugreen-dark">{{$t('skip_tuto')}}</button>
        </div>
      </div>
    </div>
    <div v-if="step === 2">
      <div class="arrow-left-2"></div>
      <div class="bubble-2">
        <img style="width:35px" class="mx-auto py-5" src="../assets/icons/chat_smile.svg"/>
        <p>{{$t('guide_2_a')}}</p>
        <p class="mt-2">{{$t('guide_2_b')}}</p>

        <div class="flex flex-col items-center py-2">
          <button @click="nextStep(3)" style="width:200px" class="mt-3 bg-tribugreen-regular py-1 text-white rounded-full hover:bg-tribugreen-medium">OK</button>
          <button @click="skip()" class="underline mt-2 hover:text-tribugreen-dark">{{$t('skip_tuto')}}</button>
        </div>
      </div>
    </div>
    <div v-if="step === 3">
      <div class="arrow-left-3"></div>
      <div class="bubble-3 px-5">
        <img style="width:35px" class="mx-auto py-5" src="../assets/icons/chat_smile.svg"/>
        <p>{{$t('guide_3_a')}}</p>
        <p>{{$t('guide_3_b')}}</p>
        <div class="flex flex-col items-center py-2">
          <button @click="nextStep(4)" style="width:200px" class="mt-3 bg-tribugreen-regular py-1 text-white rounded-full hover:bg-tribugreen-medium">OK</button>
          <button @click="skip()" class="underline mt-2 hover:text-tribugreen-dark">{{$t('skip_tuto')}}</button>
        </div>
      </div>
    </div>
    <div v-if="step === 4">
      <div class="arrow-left-4"></div>
      <div class="bubble-4 px-5">
        <img style="width:35px" class="mx-auto py-5" src="../assets/icons/chat_smile.svg"/>
        <p>{{$t('guide_4')}}</p>
        <div class="flex flex-col items-center py-2">
          <button @click="nextStep(5)" style="width:200px" class="mt-3 bg-tribugreen-regular py-1 text-white rounded-full hover:bg-tribugreen-medium">OK</button>
          <button @click="skip()" class="underline mt-2 hover:text-tribugreen-dark">{{$t('skip_tuto')}}</button>
        </div>
      </div>
    </div>
    <div v-if="step === 5">
      <div class="bubble-5 px-5">
        <img style="width:85px" class="mx-auto py-5" src="../assets/icons/validate.svg"/>
        <p>{{$t('guide_5')}}</p>
        <div class="flex flex-col items-center py-2">
          <a @click="skip()" :href="linkSubscription" style="width:230px" class="mt-3 bg-tribugreen-regular py-2 text-white rounded-full hover:bg-tribugreen-medium">{{$t('guide_5_button_1')}}</a>
          <button @click="skip()" style="width:230px" class="mt-2 bg-tribugreen-medium py-2 text-tribugreen-regular rounded-full hover:bg-tribugreen-light">{{$t('guide_5_button_2')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from "../api"
export default {
 async mounted(){
    const menuObject = await User.getMenu();

    const linkSubscription = menuObject.find(objet => objet.key === 'subscription');
    this.linkSubscription = linkSubscription.link

  },
  data(){
    return{
      linkSubscription:null
    }
  },
  computed:{
    step(){
      return this.$store.state.stepGuide
    }
  },
  methods:{
    skip(){
      localStorage.removeItem('guide')
      this.$store.commit('setGuide',1)
      this.$store.commit('setShowGuide',false)
    },
    nextStep(step){
      this.$store.commit('setShowGuide',false)
      this.$store.commit('setGuide',step)
    }
  }
};
</script>
<style>
.bubble-1 {
	height: 255px;
	width: 300px;
	background: white;
	position: absolute;
  right:0;
  top:0;
  margin-top:170px;
  margin-right:230px;
	border-radius: 20px 0 20px 20px;
	font-size: 14px;
	text-align: center;
}

.arrow-left-1 {
  width: 0; 
  height: 0; 
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent; 
  border-right:30px solid white; 
	position: absolute;
  border-radius: 8px;
  right:0;
  top:0;
  margin-top:143px;
  margin-right:230px;
}
.bubble-2 {
	height: 245px;
	width: 320px;
	background: white;
	position: absolute;
  left:50%;
  bottom:0;
  margin-bottom:135px;
  margin-left:-170px;
	border-radius: 20px;
	font-size: 14px;
	text-align: center;
}

.arrow-left-2 {
  width: 0; 
  height: 0; 
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 40px solid white;
	position: absolute;
  border-radius: 8px;
  left:50%;
  bottom:0;
  margin-bottom:115px;
  margin-left:-50px;
}
.bubble-3 {
	height: 255px;
	width: 300px;
	background: white;
	position: absolute;
  left:0;
  top:0;
  margin-top:170px;
  margin-left:160px;
	border-radius: 0 20px 20px 20px;
	font-size: 14px;
	text-align: center;
}

.arrow-left-3 {
  width: 0; 
  height: 0; 
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent; 
  border-left:30px solid white; 
	position: absolute;
  border-radius: 8px;
  left:0;
  top:0;
  margin-top:143px;
  margin-left:160px;
}
.bubble-4 {
	height: 240px;
	width: 300px;
	background: white;
	position: absolute;
  left:50%;
  top:50%;
  margin-left:50px;
  margin-top:-50px;
	border-radius: 0 20px 20px 20px;
	font-size: 14px;
	text-align: center;
}

.arrow-left-4 {
  width: 0; 
  height: 0; 
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid white;
	position: absolute;
  margin-top:-50px;
  margin-left:25px;
  left:50%;
  top:50%;

}
.bubble-5 {
	height: 310px;
	width: 300px;
	background: white;
	position: absolute;
  left:50%;
  top:50%;
  margin-top:-180px;
  margin-left:-150px;
	border-radius: 20px;
	font-size: 14px;
	text-align: center;
}
</style>