<template>
  <div v-if="currentJournal" :key="componentKey" :style="maxWidth">
    <div class="flex justify-center">
     
      <button
        v-if="!showUploadModal"
        @click="setShowPagination"
        class="bg-gray-50 px-4 rounded-full py-1 shadow text-xs absolute z-20 -mt-3"
      >
        {{ $t("reduce") }}
      </button>
    </div>
    <div class="flex justify-center">
      <div
        class="bg-tribugreen-light shadow mx-10 rounded overflow-auto text-center  pt-2 scrollbar-pagination"
        style="font-size: 10px"
        id="paginationDrag"
      >
        <div class="flex">
          <div>
            <div
              id="page-1"
              @click="setSelectedPage('frontcover')"
              class="mx-2"
              :class="
                selectedPage === 1
                  ? 'border-2 border-tribugreen-regular'
                  : 'mb-2'
              "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
            >
              <page
                :isMiniature="true"
                :page="1"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                class="pagination-page mb-0 cursor-pointer"
              ></page>
            </div>
            <p :class="selectedPage === 1 ? 'mt-1' : ''">C</p>
          </div>
            <div v-if="currentJournal.type === 'kids'" class="flex">
            <div>
              <div
                id="page-2"
                @click="setSelectedPage(2)"
                class="mx-2"
                :class="
                  selectedPage === 2
                    ? 'border-2 border-tribugreen-regular'
                    : 'mb-2'
                "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
              >
                <page
                  :isMiniature="true"
                  :page="2"
                  class="pagination-page mb-0 cursor-pointer"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                ></page>
              </div>
              <p :class="selectedPage === 2 ? 'mt-1' : ''">2</p>
            </div>
            </div>
          <div v-if="currentJournal.type === 'premium'" class="flex">
            <div>
              <div
                id="page-2"
                @click="setSelectedPage(2)"
                class="mx-2"
                :class="
                  selectedPage === 2
                    ? 'border-2 border-tribugreen-regular'
                    : 'mb-2'
                "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
              >
                <page
                  :isMiniature="true"
                  :page="2"
                  class="pagination-page mb-0 cursor-pointer"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                ></page>
              </div>
              <p :class="selectedPage === 2 ? 'mt-1' : ''">2</p>
            </div>
            <div>
              <div
                id="page-3"
                @click="setSelectedPage(3)"
                class="mx-2"
                :class="
                  selectedPage === 3
                    ? 'border-2 border-tribugreen-regular'
                    : 'mb-2'
                "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
              >
                <page
                  :isMiniature="true"
                  :page="3"
                  class="pagination-page mb-0 cursor-pointer"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                ></page>
              </div>
              <p :class="selectedPage === 3 ? 'mt-1' : ''">3</p>
            </div>
          </div>
          <vue-draggable-next
            :list="editablePages"
            class="flex"
            @change="setOrderPage"
          >
            <div v-for="page in editablePages" :key="page">
              <div
                :class="
                  page.order % 2 === 0
                    ? 'ml-2 border-r border-tribugray-light'
                    : 'mr-2'
                "
              >
                <div>
                  <div
                    :id="`page-${page.order}`"
                    @click="setSelectedPage(page.order)"
                    :class="
                      selectedPage === page.order
                        ? 'border-2 border-tribugreen-regular'
                        : 'mb-2'
                    "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                  >
                    <page
                      :isMiniature="true"
                      :page="page.order"
                      class="pagination-page mb-0 cursor-pointer"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                    ></page>
                  </div>
                  <p
                    :class="selectedPage === page.order ? 'mt-1' : ''"
                    v-if="currentJournal.type === 'premium' || currentJournal.type === 'kids'"
                  >
                    {{ page.order }}
                  </p>

                  <p  v-else :class="selectedPage === page.order ? 'mt-1' : ''">
                    {{ page.order - 1 }}
                  </p>
                </div>
              </div>
            </div>
          </vue-draggable-next>
          <div v-if="currentJournal.type === 'kids'">
            <div>
              <div
                id="page-15`"
                @click="setSelectedPage(15)"
                class="ml-4 mr-2"
                :class="
                  selectedPage === 15
                    ? 'border-2 border-tribugreen-regular '
                    : 'mb-2'
                "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
              >
                <page
                  :isMiniature="true"
                  :page="15"
                  class="pagination-page mb-0 cursor-pointer"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                ></page>
              </div>
              <p :class="selectedPage === 15 ? 'mt-1' : ''">15</p>
            </div>
          </div>
          <div v-if="currentJournal.type === 'premium'">
            <div>
              <div
                id="page-23"
                @click="setSelectedPage(23)"
                class="ml-4 mr-2"
                :class="
                  selectedPage === 23
                    ? 'border-2 border-tribugreen-regular '
                    : 'mb-2'
                "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
              >
                <page
                  :isMiniature="true"
                  :page="23"
                  class="pagination-page mb-0 cursor-pointer"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                ></page>
              </div>
              <p :class="selectedPage === 23 ? 'mt-1' : ''">23</p>
            </div>
          </div>
          <div>
            <div
              :id="`page-${this.pages.length}`"
              @click="setSelectedPage('backcover')"
              class="mx-2"
              :class="
                selectedPage === this.pages.length
                  ? 'border-2 border-tribugreen-regular mb-1'
                  : 'mb-2'
              "
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
            >
              <page
                :isMiniature="true"
                :page="this.pages.length"
                :style="currentJournal.type=== 'kids'?'min-width: 52px !important; min-height: 52px':'min-width: 52px !important; min-height: 74px'"
                class="cursor-pointer"
              ></page>
            </div>
            <p :class="selectedPage === this.pages.length ? 'mt-1' : ''">C</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";
import { Journal, Blocks } from "../../api";
import Page from "./Page.vue";
import utils from '../../utils';
export default {
  mounted() {
    this.setEditablePages();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramPage = parseInt(urlParams.get("page"));
    if (paramPage) {
      setTimeout(this.scrollPagination, 500);
    }
  },
  components: {
    VueDraggableNext,
    Page,
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      editablePages: null,
      nbrOfPages: null,
      displayedPage: 1,
      componentKey: 0,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  watch: {
    currentJournal(value, oldValue) {
      if (value.type !== oldValue.type) {
        this.setEditablePages();
      }
    },
    selectedPage(value, oldValue) {
      let element = document.getElementById("paginationDrag");
      if (value - oldValue === 1) {
        element.scrollLeft += 50;
      }
      if (oldValue - value === 1) {
        element.scrollLeft -= 50;
      }
    },
  },
  computed: {
    maxWidth(){
      const screenSize = this.screenWidthSize - 225 - 280
      const style = {
          maxWidth: `${screenSize}px`,
        };
      return style
    },
    screenWidthSize(){
      return this.window.width;
    },
    evenNumber() {
      return this.page % 2 === 0;
    },

    user() {
      return this.$store.state.userInfos;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    pages() {
      return this.$store.state.currentJournal.pages;
    },
    selectedPage() {
      return this.$store.state.selectedPage;
    },
    showPagination() {
      return this.$store.state.showPagination;
    },
    showUploadModal() {
      return this.$store.state.showUploadModal;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    scrollPagination() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramPage = parseInt(urlParams.get("page"));
      let element = document.getElementById("paginationDrag");
      let containerWidth = element.offsetWidth;
      const pageWidth = 64;
      const nbrOfVisiblePage = Math.floor(containerWidth / pageWidth);
      const totalOfPages = this.pages.length;
      const percentOfVisibleScrollBar = (100 / totalOfPages) * nbrOfVisiblePage;
      const spaceToAddForOnePage = containerWidth / totalOfPages;
      if (paramPage > 4 && paramPage < totalOfPages - 4) {
        element.scrollLeft = spaceToAddForOnePage * paramPage;
      }
      if (paramPage <= 4) {
        element.scrollLeft = 0;
      }
      if (paramPage >= totalOfPages - 4) {
        element.scrollLeft = containerWidth;
      }
    },
    async setSelectedPage(page) {
      if(this.$store.state.blockTextIsSelected){
        this.$store.commit('setBlockTextIsSelected',false)
      }
      let pageNumber = page;
      if (pageNumber === "frontcover") {
        pageNumber = 1;
      }
      if (pageNumber === "backcover") {
        pageNumber = this.pages.length;
      }
      if(!this.$route.params.tribeId ){
        this.$router.push({ name: "Workspace", query: { page: pageNumber } })
      };
      this.$store.commit("setSelectedPage", pageNumber);
      this.setDisplayedPage();
      if (typeof this.$store.state.selectedBlock === "string") {
        for (let i = 0; i < this.currentJournal.pages.length; i++) {
          if (this.currentJournal.pages[i].order === page - 1) {
            if (this.$store.state.hasBlockImage) {
              const journalId = this.currentJournal.id;
              const blockId = this.$store.state.blockId;
              const updatedAt = this.$store.state.blockUpdatedAt;
              const res = await Blocks.editImage(
                journalId,
                blockId,
                this.$store.state.top,
                this.$store.state.left,
                this.$store.state.scale,
                updatedAt,
                "pagination drag"
              );

              let currentJournal = this.currentJournal;
              for (let i = 0; i < currentJournal.pages.length; i++) {
                for (
                  let j = 0;
                  j < currentJournal.pages[i].blocks.images.length;
                  j++
                ) {
                  if (
                    currentJournal.pages[i].blocks.images[j].block_id ===
                    blockId
                  ) {
                    currentJournal.pages[i].blocks.images[j] = res;
                    this.$store.commit("setCurrentJournal", currentJournal);
                    this.$store.commit("setRemountCropper", true);
                  }
                }
              }

              this.$store.commit("setHasBlockImage", false);
              this.$store.commit("setBlockId", null);
              this.$store.commit("setBlockUpdatedAt", null);
              this.$store.commit("setMetaData", null);
            }
          }
        }
      }
      this.$store.commit("setSelectedBlock", { id: null });
      await utils.journalEvent('newspaper_page_viewed')
      if(localStorage.guide && this.$store.state.stepGuide === 3){
       if(this.currentJournal.type === "premium"){
        if(page === 'frontcover' || page === 'backcover'|| page === 2 || page === 3 || page === 23 ){
          this.$notify({
            title: this.$t("guide_2_error"),
          });
        }else{
          this.$store.commit('setShowGuide',true)
        }
       }
      if(this.currentJournal.type === "kids"){
        if(page === 'frontcover' || page === 'backcover'|| page === 2 || page === 15 ){
          this.$notify({
            title: this.$t("guide_2_error"),
          });
        }else{
          this.$store.commit('setShowGuide',true)
        }
       }
      if(this.currentJournal.type === "standard"){
        if(page === 'frontcover' || page === 'backcover'){
          this.$notify({
            title: this.$t("guide_2_error"),
          });
        }else{
          this.$store.commit('setShowGuide',true)
        }
       }
      }
    },
    setShowPagination() {
      this.$store.commit("setShowPagination", !this.showPagination);
      this.$store.commit("setPaginationIsReduce", true);
    },
    setEditablePages() {
      let pagesArray = this.pages;
      let newPagesArray = [];

      if (this.currentJournal.type === "premium") {
        newPagesArray.push(
          pagesArray.filter(
            (item) =>
              ![1, 2, 3, pagesArray.length, pagesArray.length - 1].includes(
                item.order
              )
          )
        );
      }
       if (this.currentJournal.type === "kids") {
        newPagesArray.push(
          pagesArray.filter(
            (item) => ![1,2, pagesArray.length, pagesArray.length - 1].includes(item.order)
          )
        );
      }
      if (this.currentJournal.type !== "kids" && this.currentJournal.type !== "premium") {
        newPagesArray.push(
          pagesArray.filter(
            (item) => ![1, pagesArray.length].includes(item.order)
          )
        );
      }
      this.editablePages = newPagesArray[0];
    },

    async setOrderPage(event) {
      if (this.$store.state.callisDone === true) {
        const journalId = this.currentJournal.id;
        const pageId = event.moved.element.id;

        let body = {};
        if (this.currentJournal.type === "premium") {
         
          body["current_order"] = event.moved.oldIndex + 4;
          body["new_order"] = event.moved.newIndex + 4;
        }
        if (this.currentJournal.type === "kids") {
          body["current_order"] = event.moved.oldIndex + 3;
          body["new_order"] = event.moved.newIndex + 3;
        }
        if (this.currentJournal.type !== "kids" && this.currentJournal.type !== "premium") {
          body["current_order"] = event.moved.oldIndex + 2;
          body["new_order"] = event.moved.newIndex + 2;
        }

        await Journal.updatePageOrder(journalId, pageId, body);
        this.setEditablePages();
        if (this.currentJournal.type === "premium") {
          this.setSelectedPage(event.moved.newIndex + 4);
        } else {
          this.setSelectedPage(event.moved.newIndex + 2);
        }
        this.$store.commit("setCallisDone", true);
        this.componentKey = !this.componentKey;
      }
    },
    setDisplayedPage() {
      let displayedPage = this.pages.find(
        (item) => item.order === this.selectedPage
      );
      this.displayedPage = displayedPage;
      this.$store.commit("setDisplayedPage", this.displayedPage);
      this.$store.commit("setChangePage", true);
      
    },
  },
};
</script>
<style>
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.04),
    0px 0px 8px rgba(20, 20, 20, 0.08);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cae0dd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aeccc8;
}
.scrollbar-pagination {
  scrollbar-color: #aeccc8 #cae0dd !important;
  scrollbar-shadow-color: transparent !important;
}
</style>