<template>
  <div
    class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center items-center -mt-12 z-40"
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6 overflow-auto scrollZoom"
      style="width: 450px; max-height: 90%"
    >
      <div v-if="image">
        <div class="w-full flex justify-between items-center">
          <div class="flex">
            <div>
              <div v-if="creator.picture" class="flex justify-end">
                <div class="px-2 py-2">
                  <img
                    :src="creator.picture"
                    class="rounded-full border border-white"
                    style="height: 35px; width: 35px"
                  />
                </div>
              </div>
              <div
                class="flex justify-end"
                v-if="!creator.picture && creator.firstname && creator.lastname"
              >
                <div class="px-2 py-2">
                  <div
                    class="rounded-full bg-tribugreen-regular text-white border flex items-center justify-center border-white"
                    style="height: 35px; width: 35px; font-size: 12px"
                  >
                    <p>{{ getInitials(image.creator_id) }}</p>
                  </div>
                </div>
              </div>
              <div
                class="flex justify-end"
                v-if="
                  !creator.picture && !creator.firstname && !creator.lastname
                "
              ></div>
            </div>
            <div>
              <div class="font-bold">
                {{ creator.firstname }} {{ creator.lastname }}
              </div>
              <p class="text-sm">{{ uploadDate }}</p>
            </div>
          </div>
          <img
            @click="setZoomModal"
            src="../../assets/icons/cross-gray.svg"
            class="w-4 h-4 cursor-pointer mb-3"
          />
        </div>

        <div class="flex items-center">
          <div
            v-if="indexCurrentPage !== 0"
            class="relatif cursor-pointer shadow"
            @click="prevPage"
          >
            <img
              class="absolute -ml-4 bg-tribugreen-semidark w-7 h-7 pr-1 rounded-full mx-1 hover:bg-tribugreen-medium"
              src="../../assets/icons/left.svg"
            />
          </div>
          <div v-if="image">
            <img :src="image.formats.medium.path" class="object-cover" />
          </div>
          <div
            v-if="indexCurrentPage < imagesList.length - 1"
            class="relatif cursor-pointer shadow"
            @click="nextPage"
          >
            <img
              class="absolute -ml-4 bg-tribugreen-semidark w-7 h-7 rounded-full hover:bg-tribugreen-medium"
              src="../../assets/icons/right.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";
import moment from "moment/min/moment-with-locales";

export default {
  directives: {
    ClickAway: directive,
  },
  beforeMount() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  mounted() {
    this.image = this.selectedImageLib;
    this.creator = this.getCreator(this.image.creator_id);
  },
  data() {
    return {
      image: null,
      creator: null,
      disablePrevClick: false,
    };
  },

  computed: {
    uploadDate() {
      moment.locale(localStorage.language.toLowerCase());
      return moment(this.image.created_at).format("DD MMMM YYYY");
    },
    imagesList() {
      return this.$store.state.allImagesList;
    },
    selectedImageLib() {
      return this.$store.state.imageZoom;
    },
    indexCurrentPage() {
      return this.imagesList.indexOf(this.image);
    },
  },
  methods: {
    popupLeft() {
      this.prevPage();
    },

    popupRight() {
      this.nextPage();
    },
    handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          this.popupLeft();
          break;
        case 39:
          this.popupRight();
          break;
      }
    },
    getCreator(id) {
      const members = this.$store.state.members;
      let creator = null;
      for (let i = 0; i < members.length; i++) {
        if (members[i].id === id) {
          creator = members[i];
        }
      }
      if (!creator) {
        creator = {
          firstname: "",
          lastname: "",
          picture: null,
        };
      }
      if (creator.picture === null) {
        creator.picture = "profil-green.svg";
      }
      return creator;
    },
    getInitials(id) {
      const creator = this.getCreator(id);
      const initials = creator.firstname.charAt(0) + creator.lastname.charAt(0);
      return initials.toUpperCase();
    },
    onClickAway() {
      this.setZoomModal();
    },
    setZoomModal() {
      this.$store.commit("setOutsideModalZoom", true);
      this.$store.commit("setModalZoom", false);
    },
    prevPage() {
      if (this.indexCurrentPage > 0) {
        this.image = this.imagesList[this.indexCurrentPage - 1];
        this.creator = this.getCreator(this.image.creator_id);
      }
    },
    nextPage() {
      if (this.indexCurrentPage < this.imagesList.length - 1) {
        this.image = this.imagesList[this.indexCurrentPage + 1];
        this.creator = this.getCreator(this.image.creator_id);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>
<style>
/* CUSTOM SCROLLBAR */

.scrollZoom::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.4);
}
.scrollZoom::-webkit-scrollbar-thumb {
  background: #e4e4e4 !important;
  border-radius: 10px;
  min-height: 60px !important;
}
</style>
