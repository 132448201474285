<template>
  <div
    class="
      w-full
      h-screen
      bg-tribugreen-light
      flex flex-col
      items-center
      pt-10
      font-inter
    "
  >
    <img src="../../assets/logo-black.svg" class="w-10 mb-5" />
    <div class="bg-white rounded-xl shadow-sm p-7" style="width: 370px">
      <div class="text-center"></div>
      <div class="flex flex-col items-center">
        <div class="font-bold mb-5" stylz="font-size:24px">{{ $t("enter_email_address") }}</div>
        <input
          dusk="email"
          type="email"
          placeholder="E-mail"
          v-model="email"
          class="input-form py-2"
          @keydown.enter="sendLink"
        />
        <button
          dusk="submit-forget-password"
          @click="sendLink"
          class="w-full bg-tribugreen-regular text-white text-sm py-3 hover:bg-tribugreen-medium rounded-full mt-4"
        >
          {{ $t("send_link") }}
        </button>
      </div>
    </div>
    <div class="flex justify-between text-xs px-4 pt-3" style="width:370px">
      <div @click="goToSignin" class="cursor-pointer hover:text-gray-500">{{ $t("login") }}</div>
      <div class="cursor-pointer hover:text-gray-500" @click="goToSignup">{{ $t("signup") }}</div>
    </div>
    <div class="font-semibold text-xs flex mt-10">
      <div
        @click="setLanguage('FR')"
        class="px-2 cursor-pointer"
        :class="language === 'FR' ? 'text-tribugreen-regular' : ''"
      >
        FR
      </div>
      <div
        @click="setLanguage('NL')"
        class="px-2 cursor-pointer"
        :class="language === 'NL' ? 'text-tribugreen-regular' : ''"
      >
        NL
      </div>
      <div
        @click="setLanguage('EN')"
        class="px-2 cursor-pointer"
        :class="language === 'EN' ? 'text-tribugreen-regular' : ''"
      >
        EN
      </div>
    </div>
  </div>
</template>
<script>
import { Auth } from "../../api";
import redirect from '../../libraries/redirect'
export default {
  name: "ForgetPassword",
    beforeMount(){
    if(localStorage.token){
      this.$router.push({name: "Workspace"})
    }
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  methods: {
    setLanguage(lang) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramLang = urlParams.get("lang");
      if (paramLang) {
        window.location.href = window.location.origin;
      }
      this.$store.commit("setLanguage", lang);
      this.$i18n.locale = lang;
    },
    goToSignup() {
      this.$router.push({name:"SignUp"})
    },
    goToSignin() {
      this.$router.push({name:"Login"})
    },
    async sendLink() {
      if (!this.email) {
        this.$notify({
          title: this.$t("enter_email_address"),
        });
        return;
      }
      try{
        const res = await Auth.forgetPassword(this.email)
        if (res.status === 200) {
          await redirect()
        }
      }catch(e){
        console.log(e)
      }
      

      this.$notify({
        title: this.$t('password_reset_email')
        
      })
      setTimeout( () => this.$router.push({ name: "Login" }), 5000)
      
    },
  },
};
</script>
