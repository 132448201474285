<template>
  <div class="w-full flex h-screen" :class="isMobile?'bg-tribugreen-light':'background-auth'">
    <div v-if="!isMobile" class="w-1/2"></div>
    <div :class="isMobile?'w-full mx-3':'w-1/2 mx-10'" class="bg-white rounded-xl my-10 h-full relative" style="max-width:500px; max-height:620px;">
      <div class="absolute right-0 mr-5 mt-4 text-right">
        <div @click="showLanguages=true" class="flex pl-2 cursor-pointer">{{language}} <img class="ml-2" src="../../assets/icons/down-one.svg"/></div>
        <div class="border px-3 bg-white mt-1 cursor-pointer" v-if="showLanguages">
          <div @click="setLanguage('FR')" v-if="language !== 'FR'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">FR</div>
          <div @click="setLanguage('NL')" v-if="language !== 'NL'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">NL</div>
          <div @click="setLanguage('EN')" v-if="language !== 'EN'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">EN</div>
          <div @click="setLanguage('DE')" v-if="language !== 'DE'" class="hover:text-gray-600 py-1 text-gray-400 bg-white">DE</div>
        </div>
      </div>
      <div class="flex flex-col w-full items-center">
        <div class="w-full flex justify-center border-b border-tribugray-light">
          <img src="../../assets/logo-black.svg" class="w-12 my-5"/>
        </div>
        <p v-if="tribe" style="font-size:16px" class="mt-5 mb-4 font-inter font-bold text-center">{{$t('signup_invite_title')}}<br>{{tribe}}</p>
        <p v-else class="mt-7 mb-4 font-inter font-bold text-lg">{{$t('signup_title')}}</p>
        <!--FORM-->
        <div style="font-size:14px; width:330px" class="pt-2 pb-1 flex justify-between">
          <input type="text" required v-model="firstname" v-on:keyup.enter="signUp()" :placeholder="$t('firstname')" class="bg-tribugray-light w-1/2 rounded py-2 px-3 mr-2"/>
          <input type="text" required v-model="lastname" v-on:keyup.enter="signUp()" :placeholder="$t('lastname')" class="bg-tribugray-light w-1/2 rounded py-2 px-3 ml-2"/>
        </div>
        <input type="email" required v-model="email" placeholder="E-mail" style="font-size:14px; width:330px" class="input-auth bg-tribugray-light rounded py-2 px-3 mt-3"/>
        <div style="width:330px" class="px-4 py-2 mt-4 input-auth bg-tribugray-light rounded flex justify-between">
            <input
              type="password"
              :placeholder="$t('password')"
              v-model="password"    
              v-on:keyup.enter="signUp()"
              id="passwordfield"
              class="bg-tribugray-light"
              required
            />
          <div @click="switchVisibility()" class="cursor-pointer">
              <img v-if="showPassword" class="w-5" src="../../assets/icons/preview-close.svg"/>
               <img v-else class="w-5" src="../../assets/icons/preview-open.svg"/>
          </div>
        </div>        
        <div style="width:330px" class="px-4 py-2 mt-4 input-auth bg-tribugray-light rounded flex justify-between">
            <input
              type="password"
              :placeholder="$t('confirm_password')"
              v-model="confirmPassword"    
              v-on:keyup.enter="signUp()"
              id="passwordConfirmField"
              class="bg-tribugray-light w-full"
              required
            />
          <div @click="switchVisibility()" class="cursor-pointer">
              <img v-if="showPassword" class="w-5" src="../../assets/icons/preview-close.svg"/>
               <img v-else class="w-5" src="../../assets/icons/preview-open.svg"/>
          </div>
        </div>          
        
        <button @click="signUp()" style="width:330px; font-size:14px" class="flex mt-4 px-4 py-2 text-center justify-center items-center bg-tribugreen-regular text-white rounded-full hover:bg-tribugreen-medium">{{$t("signup")}}<img v-if="loading" class="w-9 absolute" style="margin-left:150px" src="../../assets/icons/spinner.gif"/></button>
        <!--OR-->
        <div class="flex my-4 items-center" style="width:330px">
          <div style="width:150px; height:1px" class="border-t border-tribugray-semilight"></div>
          <div class="text-tribugray-semilight px-4">{{$t('or')}}</div>
          <div style="width:150px; height:1px" class="border-t border-tribugray-semilight"></div>
        </div>
        <!--GOOGLE - APPLE -->
        <div class="text-sm text-white w-full" style="width:330px">
          <div
            class="rounded-full px-4 py-2 mb-3 flex text-black border bg-white hover:bg-gray-50 border-gray-200 items-center cursor-pointer"
            @click="useAuthProvider('google')"
          >
            <img
              src="../../assets/img/google-logo.svg"
              class="mr-2"
              style="width:18px"
              alt=""
            />
            <p class="ml-12">{{ $t("sign_up_with") }} Google</p>
          </div>
          <div
            class="rounded-full py-2 flex bg-black hover:bg-gray-800 items-center cursor-pointer"
            @click="useAuthProvider('apple')"
          >
            <img
              src="../../assets/img/apple-logo.svg"
              class="ml-4 mr-2"
              style="width:18px"
            />
            <p class="ml-12">{{ $t("sign_up_with") }} Apple</p>
          </div>
        </div>
        <!--SIGN IN-->
        <div class="flex justify-center text-xs px-4 mt-4 pt-3" style="width: 330px">
          <div @click="goToSignIn" class="cursor-pointer hover:text-gray-500">{{ $t("i_have_account") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Auth, Tribes } from "../../api";
import { Providers } from 'universal-social-auth';
import { directive } from "vue3-click-away";
import utils from "../../utils";
export default {
  name: "Signup",
      directives: {
    ClickAway: directive,
  },
  beforeMount() {
    if (localStorage.token) {
      this.$router.push({name: "Workspace"});
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramType = urlParams.get("type");
  if(paramType){
    this.type = paramType
  }
  },
  async mounted(){
    if(localStorage.code){
      console.log(localStorage.code)
      const code = localStorage.code
      const call = await Tribes.getTribeWithCode(code)
      this.tribe = call.tribe
    }
  },
  data() {
    return {
      showLanguages:false,
      type : null,
      lastname: null,
      firstname: null,
      email: null,
      password: null,
      confirmPassword: null,
       dropdownLanguage: false,
       outsideLang: true,
        langs: ["FR", "NL", "EN", "DE"],
              window: {
        width: 0,
        height: 0,
      },
      errorMessage:null,
      showPassword:false,
      loading:false,
      tribe:null,
    };
    
  },
  watch:{
    email(value,oldvalue){
      if(value !== oldvalue && this.signupError === true){
        this.$store.commit('setSignupError',false)
      }
      if(value !== oldvalue && this.errorMessage){
       this.errorMessage = null
      }
    },
    password(value,oldvalue){
      if(value !== oldvalue && this.signupError === true){
        this.$store.commit('setSignupError',false)
      }
      if(value !== oldvalue && this.errorMessage){
       this.errorMessage = null
      }
    },
    confirmPassword(value,oldvalue){
      if(value !== oldvalue && this.signupError === true){
        this.$store.commit('setSignupError',false)
      }
      if(value !== oldvalue && this.errorMessage){
       this.errorMessage = null
      }
    }
  },
  computed: {
    signupError(){
      return this.$store.state.signupError
    },
    language() {
      return this.$store.state.language;
    },
      isMobile() {
      return this.window.width < 880 ? true : false;
    }
  },
  methods: {
    switchVisibility(){
        this.showPassword = !this.showPassword
        const passwordField = document.querySelector('#passwordfield')
        if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text')
        else passwordField.setAttribute('type', 'password')
        const passwordConfirmField = document.querySelector('#passwordConfirmField')
        if (passwordConfirmField.getAttribute('type') === 'password') passwordConfirmField.setAttribute('type', 'text')
        else passwordConfirmField.setAttribute('type', 'password')
    },    
    onClickAwayLanguage() {
      this.outsideLang = true;
    },
      showdropdownLanguage() {
      this.outsideLang = false;
      this.dropdownLanguage = !this.dropdownLanguage;
      if (this.dropdownMenu) {
        this.dropdownMenu = false;
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    useAuthProvider(provider) {
      this.$Oauth
        .authenticate(provider, Providers[provider])
        .then(async (response) => {
          if (response.code) {
            await Auth.useSocialLogin(provider, response.code, response.hash);
            this.$store.state.hasSignup = true;
            if(this.isMobile){
              this.$router.push({name:"Workspace"});

             }else{
              if(localStorage.code){
                localStorage.guide = true
                this.$router.push({name:"Workspace"});

              }else{
                this.$router.push({name:"Welcome"});

              }

             }
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setLanguage(lang) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paramLang = urlParams.get("lang");
      if (paramLang) {
        window.location.href = window.location.origin;
      }
      this.$store.commit("setLanguage", lang);
      this.$i18n.locale = lang;
      this.showLanguages=false
    },
    async signUp() {
      if (
        !this.password ||
        !this.confirmPassword ||
        !this.firstname ||
        !this.lastname ||
        !this.email
      ) {
        this.$notify({
          title: this.$t("complete_all_info"),
        });
        if(this.isMobile){
          this.errorMessage = "complete_all_info"
        }
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.$notify({
          title: this.$t("wrong_password_confirmation"),
        });
        if(this.isMobile){
          this.errorMessage = "wrong_password_confirmation"
        }
        return;
      }
      if (this.password.length <8) {
        this.$notify({
          title: this.$t("eight_characters"),
        });
        if(this.isMobile){
          this.errorMessage = "eight_characters"
        }
        return;
      }
        this.loading = true
        await Auth.signup(
          this.firstname,
          this.lastname,
          this.email,
          this.password,
          this.confirmPassword,
          this.$i18n.locale
        );
        if(localStorage.token){
          if(localStorage.code){
            utils.event('sign_up', { method: 'manual' });
             this.loading = false
             if(this.isMobile){
              this.$router.push({name:"Workspace"});

             }else{
              if(localStorage.code){
                localStorage.guide = true
                this.$router.push({name:"Workspace"});

              }else{
                this.$router.push({name:"Welcome"});
              }

             }
          }else{
            utils.event('sign_up', { method: 'manual' });
            this.loading = false
             if(this.isMobile){
              this.$router.push({name:"Workspace"});

             }else{
              if(localStorage.code){
                localStorage.guide = true
                this.$router.push({name:"Workspace"});

              }else{
                this.$router.push({name:"Welcome"});

              }

             }
    
          }
        }

    },
    goToSignIn() {
      this.$router.push({name:"Login"});
    },
  },
    created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style>
.background-auth{
  background-image: url("../../assets/img/new-login.jpg");
  background-size: cover;
}
.input-auth{
  font-size:12px;
}
.input-auth::placeholder{
  color:#B4B6B8;
  font-family: 'Inter', sans-serif;
}

</style>