<template>
    <div
      v-if="switchBlockMode"
      class="absolute  w-full h-full"
      style="z-index:300"
      draggable="true"
      @drag.stop
      @dragstart.stop="onDrag(imageBlock)"
      @dragover.stop.prevent
      @dragenter.stop.prevent
      @dragend.stop.prevent
      @dragleave.stop.prevent
       @drop.stop.prevent="onDrop"
       :style="(detectBrowser() === 'Firefox' || detectBrowser() === 'Safari')&& !isMiniature?'cursor: copy':''"
    ></div>
    <div
      v-if="zoomIsSend && imageBlock && selectedBlock === blockKey"
      class="absolute w-full h-full flex justify-center items-center"
      style="z-index:300"
    >
    <!-- spinner html-css -->
    
      <div class="lds-spinner" :style="isMiniature?'transform: scale(0.1);':'transform: scale(0.4);'"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  <div 
   @mouseover="hover = true"
   @mouseleave="hover = false"
   :key="componentKey" 
   >
  <div v-if="!isSmallBlock">
     <div
      v-if="!isMiniature && imageBlock && hover && !switchBlockMode"
      class="absolute z-20 flex cursor-pointer ml-2 mt-2"
    >
   
     <div
        v-if="!maxdezoom"
        class="bg-white px-3 rounded-l-xl text-gray-400 hover:bg-gray-100"
        @click="zoom(0.97);"
        id="zoomOut"
      >
        -
      </div>
     <div
        v-else
        class="bg-gray-100 px-3 rounded-l-xl text-gray-400"
      >
        -
      </div>
      <div
        class="text-tribugreen-regular bg-white px-3 rounded-r-xl hover:bg-gray-100"
        v-if="scale < 2.5"
        @click="zoom(1.03)"
         id="zoomIn"
      >
        +
      </div>
       <div
        v-else
        class="text-gray-400 px-3 rounded-r-xl bg-gray-100"
      >
        +
      </div>
    </div>
      <div
        v-if="!isMiniature && imageBlock && hover"
        @click="deleteImage()"
        class="cursor-pointer absolute z-20 right-0 mr-2 mt-2 bg-opacity-90 bg-white active:bg-tribugray-light shadow h-5 w-5 flex justify-center items-center rounded-full hover:bg-gray-200"
      >
        <i
          class="far fa-trash-alt text-tribugray-semidark"
          style="font-size: 10px"
        ></i>
      </div>

      <div
        v-if="!isMiniature && imageBlock && hover && !switchBlockMode"
        class="flex w-full justify-center"
      >
        <div
          v-if="!imageBlock.legend"
          class="cursor-pointer absolute z-20 bg-opacity-75 px-2 py-1 mb-2 bottom-0 bg-white rounded-xl hover:bg-opacity-95"
          style="font-size: 10px"
          @click="editCaption()"
        >
          {{ $t("add_caption") }}
        </div>
      </div>
      <div v-if="imageBlock" class="absolute z-20 bottom-0 w-full">
        <div class="flex justify-center items-center px-1">
          <div
            v-if="!isMiniature && imageBlock.legend && hover && !switchBlockMode"
            class="cursor-pointer bg-opacity-75 px-3 py-1 bg-white rounded-xl text-center mb-2"
            style="font-size: 10px"
            @click="editCaption(imageBlock.legend)"
          >
            <p >{{ $t("edit") }}</p>
          </div>
        </div> 
        <div
          v-if="imageBlock.legend && !isMiniature"
          style="background-color: rgba(255, 255, 255, 0.6)"
          class="text-center w-full cursor-default break-words"
          :class="isMiniature ? '' : 'py-1 '"
          :style="[
            isMiniature ? 'font-size:0.5vh' : '',
            pageSize === 'small' ? 'font-size:4px' : '',
            pageSize === 'medium' ? 'font-size:8px' : '',
            pageSize === 'big' ? 'font-size:10px' : '',
          ]"
        >
          {{ imageBlock.legend }}
        </div>
      </div>
    </div>
    <div v-if="isSmallBlock">
      <div v-if="imageBlock" class="absolute z-20 bottom-0 w-full">
        <div
          v-if="imageBlock.legend && !isMiniature"
          style="background-color: rgba(255, 255, 255, 0.6)"
          class="text-center w-full cursor-default break-words"
          :class="isMiniature ? '' : 'py-1 '"
          :style="[
            isMiniature ? 'font-size:0.5vh' : '',
            pageSize === 'small' ? 'font-size:4px' : '',
            pageSize === 'medium' ? 'font-size:8px' : '',
            pageSize === 'big' ? 'font-size:10px' : '',
          ]"
        >
          {{ imageBlock.legend }}
        </div>
      </div>
    </div>
    <cropper
      v-click-away="onClickAway"
      @click="sendCall"
      @change="onChange"
      id="advancedCropper"
      :style="containerImage"
      v-if="imageBlock"
      ref="cropper"
      :stencil-size="stencilSize"
      :stencil-props="{
        handlers:{},
        movable: false,
        scalable: false,
      }"
      :resize-image="{
        adjustStencil: false,
        wheel: false,
      }"
      :transitions="false"
      :canvas="false"
      :debounce="false"
      :src="imageBlock.formats.medium.path"
    />
    <img
      v-if="!imageBlock && selectedBlock.id !== block.id"
       src="../../assets/icons/camera.svg"
      :class="isMiniature ? 'w-2' : 'w-7'"
    />
      <!--tool small image -->
    <div
      v-show="!isMiniature && isSmallBlock && imageBlock && selectedBlock === blockKey && !showModalLegend && !switchBlockMode"
      style="z-index: 100"
      :style="
        isTinyImage
          ? 'min-width:90px; margin-left:-30%'
          : 'min-width:90px; '
      "
      class="mt-1 bg-tribugray-light rounded-full absolute w-full flex justify-around items-center shadow"
    >
    
      <div
        class="flex cursor-pointer my-1"
        :style="pageSize === 'small' ? 'font-size:10px' : ''"
      >
     <div
        v-if="!maxdezoom"
        class="bg-white pr-1 pl-2 rounded-l-xl text-gray-400 hover:bg-gray-100 ml-1"
        @click="zoom(0.97);"
        id="zoomOut"
      >
        -
      </div>
     <div
        v-else
        class="bg-gray-100 pr-1 pl-2 rounded-l-xl text-gray-400 ml-1"
      >
        -
      </div>
        <div
          class="text-tribugreen-regular bg-white pr-2 pl-1 rounded-r-xl mr-1"
          :disabled="scale >= 2.5"
          @click="zoom(1.03);"
        >
          +
        </div>
      </div>
      <div
        :style="pageSize === 'small' ? 'font-size:10px' : ''"
        :key="componentKey"
        @click="editCaption(imageBlock.legend, isTinyImage)"
        class="cursor-pointer bg-white rounded-full cursor-pointer text-tribugray-medium text-sm pl-1 h-4 w-4 flex items-center justify-center"
      >
        <i class="far fa-edit"></i>
      </div>
      <div
        :style="pageSize === 'small' ? 'font-size:10px' : ''"
        @click="deleteImage()"
        class="cursor-pointer mx-1 bg-white h-4 w-4 flex justify-center items-center rounded-full text-tribugray-medium text-xs"
      >
        <i class="far fa-trash-alt text-tribugray-semidark"></i>
      </div>

    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { Blocks } from "../../api";
import { directive } from "vue3-click-away";
import utils from "../../utils";

export default {
  directives: {
    ClickAway: directive,
  },
  components: {
    Cropper
  },
  mounted(){
   this.$store.commit("setRemountCropper",false)
   this.oldScale = this.scale
    if(this.imageBlock){
      this.scale = this.imageBlock.metadata.scale;
      this.x = this.imageBlock.metadata.x;
      this.y = this.imageBlock.metadata.y; 
      this.oldScale = this.scale
    } 

  },

  props: {
    isMiniature: Boolean,
    block: Object,
    page: Number,
  },
  data() {
    return {
      zoomIsSend:false,
      counter :0,
      componentKey : 0,
      miniatureWidth: 51,
      scale: 1,
      x: 0.5,
      y: 0.5,
      changes:false,
      hover:false,
      isResizeMethod:false,
      isMounted:false,
      counter:0,
      positionIsSet:false,
      clickAway:false,
      maxdezoom:false,
      oldScale:1

    };
  },
  watch:{ 

    maxdezoom(value,oldValue){

      if(value !== oldValue && value === true && this.zoomIsSend){
       if(this.scale === 1 && this.oldScale !== 1){
        this.scale = this.oldScale
       }
      }
    },
    imageBlock(value,oldValue){
      if(value !== oldValue){
        this.scale = 1
      }
    },
    isMounted(value,oldValue){
      if(value !== oldValue && value === true){
       this.setPosition()
       if(!this.isMiniature){
         if(this.$refs.cropper.coordinates.width + 4 > this.imageBlock.formats.medium.width){
          this.maxdezoom = true
         }
       }
      }
    },
    x(value,oldValue){
      if(value !== oldValue){
        this.changes = true
        if(this.counter > 2){
         this.$store.commit('setSelectedBlock',this.blockKey)
        }     
      }
    },
    y(value,oldValue){
      if(value !== oldValue){
        this.changes = true
        if(this.counter > 2){
         this.$store.commit('setSelectedBlock',this.blockKey)
        }
        
      }
    },
    pageSize(value,oldValue){
      if(value !== oldValue){
        this.componentKey = !this.componentKey
      }
    }
  },
  computed: {
    switchBlockMode(){
      return this.$store.state.switchBlockMode
    },
    dragImageToSwitch(){
      return this.$store.state.dragImageToSwitch
    },
    showModalLegend(){
      return this.$store.state.showModalLegend
    },
      blockKey() {
      const pageId = this.pageObject.id;
      const blockId = this.block.id;
      const order = this.block.order;
      return `${pageId}-${blockId}-${order}`;
    },
    isTinyImage() {
      const { size } = this.block;
      const maxWidth = this.journalSize.width;
      return this.calculateSize(size.width, maxWidth) < 65 ? true : false;
    },
    isSmallBlock() {
      const { size } = this.block;
      const maxWidth = this.journalSize.width;
      return this.calculateSize(size.width, maxWidth) < 130 ? true : false;
    },
    miniatureHeight() {
      if (this.currentJournal.type === "kids") {
        return 51;
      } else {
        return 70;
      }
    },
    selectedBlock() {
      return this.$store.state.selectedBlock;
    },
    pageSize(){
      return this.$store.state.pageSize;
    },
    currentJournal() {
      return this.$store.state.currentJournal;
    },
    journalSize() {
      return this.$store.state.journalSize;
    },
    naturalImageWidth() {
      const naturalImageWidth = this.imageBlock.formats.medium.width;
      return naturalImageWidth;
    },
    naturalImageHeight() {
      const naturalImageHeight = this.imageBlock.formats.medium.height;
      return naturalImageHeight;
    },
    blockWidth() {
      const { size } = this.block;
      const [maxWidth] = this.isMiniature
        ? [this.miniatureWidth]
        : [this.journalSize.width];
      return Math.round(this.calculateSize(size.width, maxWidth));
    },
    blockHeight() {
      const { size } = this.block;
      const [maxHeight] = this.isMiniature
        ? [this.miniatureHeight]
        : [this.journalSize.height];
      return Math.round(this.calculateSize(size.height, maxHeight));
    },
    containerImage() {
      if (this.isMiniature) {
        const { size } = this.block;
        const [maxHeight, maxWidth] = [
          this.miniatureHeight,
          this.miniatureWidth,
        ];
        const styles = {
          height: `${Math.round(this.calculateSize(size.height, maxHeight))}px`,
          width: `${Math.round(this.calculateSize(size.width, maxWidth))}px`,
          border: "none",
        };
        return styles;
      } else {
        const { size } = this.block;
        const [maxHeight, maxWidth] = [
          this.journalSize.height,
          this.journalSize.width,
        ];
        const styles = {
          height: `${Math.round(this.calculateSize(size.height, maxHeight))}px`,
          width: `${Math.round(this.calculateSize(size.width, maxWidth))}px`,
          minWidth: `${size.width}px`,
          border: "none",
        };
        return styles;
      }
      
    },
    imageBlock() {
      if (this.pageObject.blocks.images) {
        if (this.pageObject.blocks.images.length > 0) {
          if (this.block.order) {
            return this.pageObject.blocks.images.find(
              (item) => item.order === this.block.order
            );
          }
        }
      }
    },
    pageObject() {
      for (let i = 0; i < this.currentJournal.pages.length; i++) {
        if (this.currentJournal.pages[i].order == this.page)
          return this.currentJournal.pages[i];
      }
    },
  },

  methods: {
    detectBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        return 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
        return 'IE';//crap
    } else {
        return 'Unknown';
    }
} ,
      async onDrop(){
        
        if(!this.imageBlock || this.imageBlock.block_id !== this.dragImageToSwitch.block_id){
        let journalId = this.currentJournal.id
        let blockId = this.dragImageToSwitch.block_id
        let page = this.pageObject.id
        await Blocks.updateBlockOrder(journalId,blockId,page,this.dragImageToSwitch.order, this.block.order)      
        this.$store.commit('setRemountPage',true)
        this.$store.commit("setSelectedBlock",{ id: null })
        
      }
    },
    onDrag(image){
      this.$store.commit('setDragImageToSwitch',image)
       this.setPosition()
    },  
    debounce(callback, delay) {

      return (...args) => {
        clearTimeout(this.timer);
    
        this.timer = setTimeout(() => callback.apply(this, args), delay)
      }
    },
    onClickAway(){
      if(this.changes && !this.isMiniature && !this.isResizeMethod){
        this.clickAway = true
        this.sendCall()     
      }
    },
     async sendCallZoom(){
      
     if(this.selectedBlock === this.blockKey  && !this.switchBlockMode && !this.showModalLegend){  
      this.zoomIsSend = true
     const journalId = this.currentJournal.id;
     const blockId = this.imageBlock.block_id;
     const updatedAt = this.imageBlock.updated_at;
     const res = await Blocks.editImage(
       journalId,
       blockId,
       this.y,
       this.x,
       this.scale,
       updatedAt
     );

     let currentJournal = this.currentJournal;
     for (let i = 0; i < currentJournal.pages.length; i++) {
       for (
         let j = 0;
         j < currentJournal.pages[i].blocks.images.length;
         j++
       ) {
         if (currentJournal.pages[i].blocks.images) {
           if (
             currentJournal.pages[i].blocks.images[j].block_id ===
             blockId
           ) {
            currentJournal.pages[i].blocks.images[j]= res;
             this.$store.commit("setCurrentJournal", currentJournal);
           }
         }
       }
     }    
      if(this.clickAway){
        this.$store.commit('setSelectedBlock', { id :null})
      }
      }
      this.isResizeMethod = false
    },
    async sendCall(){
     if(this.selectedBlock === this.blockKey  && !this.switchBlockMode && !this.showModalLegend  && !this.isResizeMethod){
     const journalId = this.currentJournal.id;
     const blockId = this.imageBlock.block_id;
     const updatedAt = this.imageBlock.updated_at;
     const res = await Blocks.editImage(
       journalId,
       blockId,
       this.y,
       this.x,
       this.scale,
       updatedAt
     );

     let currentJournal = this.currentJournal;
     for (let i = 0; i < currentJournal.pages.length; i++) {
       for (
         let j = 0;
         j < currentJournal.pages[i].blocks.images.length;
         j++
       ) {
         if (currentJournal.pages[i].blocks.images) {
           if (
             currentJournal.pages[i].blocks.images[j].block_id ===
             blockId
           ) {
            currentJournal.pages[i].blocks.images[j]= res;
             this.$store.commit("setCurrentJournal", currentJournal);
           }
         }
       }
     }    
      if(this.clickAway){
        this.$store.commit('setSelectedBlock', { id :null})
      }
      }

    },
    ratioImageHeightWidth(height,width){
      return height/width
    },
    ratioBlockHeightWidth(height,width){
      return height/width
    },
    blockIsVertical(width, height) {
      return height > width ? true : false;
    },

    ImageIsVertical(width, height) {
      return height > width ? true : false;
    },
    calculateSize(value, max) {
      return (max * value);
    },
    stencilSize({ boundaries }) {

   if(this.ImageIsVertical(this.naturalImageWidth,this.naturalImageHeight)){
      //ratio : calculation the size of the block is how many percent of the base image size in height
      const ratio = this.blockHeight/(this.naturalImageHeight/100)
      return {
        width:(this.naturalImageWidth/100)*ratio,
        height: this.blockHeight,
      }
   }      

      if(!this.ImageIsVertical(this.naturalImageWidth,this.naturalImageHeight)){
      //ratio : calculation the size of the block is how many percent of the base image size in width
      const ratio = this.blockWidth/(this.naturalImageWidth/100)
      return {
        width:this.blockWidth,
        height: (this.naturalImageHeight/100)*ratio,
      };
      }
    },
    setPosition(){
    if(!this.switchBlockMode){
      this.scale = this.imageBlock.metadata.scale
     this.$refs.cropper.zoom(this.scale);
      const coordinates = this.$refs.cropper.coordinates
       const centerY = Math.round(coordinates.height)/2
        const y = (this.imageBlock.metadata.y * this.naturalImageHeight) - centerY

        const centerX = Math.round(coordinates.width)/2
        const x = (this.imageBlock.metadata.x * this.naturalImageWidth) - centerX
        
        this.$refs.cropper.setCoordinates({
          width: coordinates.width,
          height: coordinates.height,
          left: x,
          top: y,
        })
        this.positionIsSet = true
        }
    },

    zoom(factor) {
     this.isResizeMethod = true
      if (factor === 1.03 && this.scale < 2.5) {
        this.scale = parseFloat((this.scale + 0.05).toFixed(2));
        this.$refs.cropper.zoom(1.03);

      } 
      if (factor === 0.97) {   
          this.oldScale=this.scale
          if(this.$refs.cropper.coordinates.width + 4 < this.imageBlock.formats.medium.width){
          this.$refs.cropper.zoom(0.97);

          if(this.imageBlock.formats.medium.width !== this.$refs.cropper.coordinates.width){
            this.scale = parseFloat((this.scale - 0.03).toFixed(2));
          } 
          else{
            this.maxdezoom = true
          } 
          }    else{
                this.maxdezoom = true
          }
      }

        this.debounce(() => this.sendCallZoom(), 450)();
        this.zoomIsSend = false
      
    },

    onChange({ coordinates}) {

      if(this.isMounted && this.positionIsSet && !this.maxdezoom){

      this.counter += 1
      const centerY = coordinates.height/2
      const newPositionY = centerY + coordinates.top
      const top = newPositionY/this.naturalImageHeight

      const centerX = coordinates.width/2
      const newPositionX = centerX + coordinates.left
      const left = newPositionX/this.naturalImageWidth
 
      this.y = top;
      this.x = left;
      }
      this.isMounted = true;
    },
    async deleteImage() {
      const res = await Blocks.deleteBlock(
        this.currentJournal.id,
        this.imageBlock.block_id
      );
      let imagesList = this.$store.state.imagesPerMonth;
      let counterMediaId = 0;
      for (let i = 0; i < this.currentJournal.pages.length; i++) {
        for (
          let j = 0;
          j < this.currentJournal.pages[i].blocks.images.length;
          j++
        ) {
          if (this.currentJournal.pages[i].blocks.images) {
            if (
              this.currentJournal.pages[i].blocks.images[j].media_id ===
              this.imageBlock.media_id
            ) {
              counterMediaId += 1;
            }
          }
        }
      }
      if (counterMediaId < 2) {
        for (let i = 0; i < imagesList.length; i++) {
          for (let j = 0; j < imagesList[i].images.length; j++) {
            if (imagesList[i].images[j].id === this.imageBlock.media_id) {
              imagesList[i].images[j].is_used_previously = false;
            }
          }
        }
      }
      this.$store.commit("setImagesPerMonth", imagesList);
      const currentJournal = this.currentJournal;
      const orginalArray = currentJournal.pages[this.page - 1].blocks.images;
      let newArray = [];
      for (let i = 0; i < orginalArray.length; i++) {
        if (orginalArray[i].order !== this.block.order) {
          newArray.push(orginalArray[i]);
        }
      }
      currentJournal.pages[this.page - 1].blocks.images = newArray;
      this.$store.commit("setCurrentJournal", currentJournal);
      await utils.journalEvent("image_removed");
      this.scale = 1;
      this.$store.commit("setMetaData", {
        changetop: null,
        changeleft: null,
        changescale: null,
      });
    },
   async editCaption(content) {
      
      this.$store.commit("setMetaData", {
        changetop: this.top,
        changeleft: this.left,
        changescale: this.scale,
      });
     
      if (content) {
        this.$store.commit("setContentLegend", content);
      }
      this.$store.commit("setBlockOrder", this.block.order);
      this.$store.commit("setShowModalLegend", true);
    
    },
  },
};
</script>
<style>
/*SPINNER CSS*/
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #ffffff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



</style>