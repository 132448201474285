<template>
  <div
    class="absolute w-full bg-black bg-opacity-50 h-screen flex justify-center items-center z-40"
  >
    <div
      v-click-away="onClickAway"
      class="bg-white rounded-xl flex flex-col items-center px-10 py-6 relative"
    >

      <div>
        <p class="text-center">
          {{ $t("welcome_text1") }}
          <span class="text-tribugreen-regular">{{ $t("welcome_text2") }}</span>
          {{ $t("welcome_text3") }}
        </p>
        <p>
          {{ $t("welcome_text4") }}
          <span class="text-tribugreen-regular">{{ $t("welcome_text5") }}</span>
          {{ $t("welcome_text6") }}
        </p>
      </div>
      <div class="text-gray-500">
        <p>{{ $t("change_type") }}</p>
      </div>
      <div class="flex mt-5">
        <div
          class="rounded-xl shadow pb-4 flex flex-col items-center py-3"
          style="width: 220px"
          :style="currentJournalType ==='standard'? ' background-color:#E4F8F5' : ''"
        >
          <img src="../assets/icons/book-login.svg" class="w-12" />
          <p class="text-tribugreen-regular capitalize" v-if="language !== 'NL'">
            {{ $t("icon2_login_3") }} <b>{{ $t("classic") }}</b>
          </p>
          <p class="text-tribugreen-regular capitalize" v-if="language === 'NL'">
           <b>Klassiek</b> {{$t('album_word_choice')}}
          </p>
          <p class="text-center my-2 text-xs">"{{ $t("classic_text") }}"</p>
          <div class="text-tribugreen-regular text-xs">
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("formatA4") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">15 {{ $t("customizable_pages") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("quality_paper") }}</p>
            </div>
          </div>
          <div
            v-if="currentJournalType !== 'standard'"
            @click="changeType('standard')"
            class="bg-tribugreen-regular text-white rounded-full py-1 px-3 hover:bg-tribugreen-medium mt-12 hover:text-tribugreen-regular cursor-pointer text-sm"
          >
            {{ $t("choose_album") }}
          </div>
          <div
            v-if="currentJournalType === 'standard'"
            class="text-tribugreen-regular font-bold  mt-12 cursor-pointer text-sm"
          >
          
            {{ $t("current_album") }}
          </div>
        </div>
        <div
          class="rounded-xl pb-4 shadow white flex flex-col items-center mx-5 py-3"
          style="width: 220px"
          :style="currentJournalType==='premium' ? ' background-color:#E4F8F5' : ''"
        >
          <img src="../assets/icons/premium.svg" class="w-11" />
          <p class="text-tribugreen-regular capitalize" v-if="language !=='NL'">
            {{ $t("album_word_choice") }} <b>Premium</b>
          </p>
           <p class="text-tribugreen-regular capitalize" v-if="language ==='NL'">
            <b>Premium</b> {{ $t("album_word_choice") }}
          </p>
          <p class="text-center my-2 text-xs">"{{ $t("premium_text") }}"</p>
          <div class="text-tribugreen-regular text-xs">
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("formatA4") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">20 {{ $t("customizable_pages") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("high_quality_paper") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("semi_rigid_cover") }}</p>
            </div>
          </div>
          <div
            v-if="currentJournalType !== 'premium'"
            @click="changeType('premium')"
            class="bg-tribugreen-regular text-white rounded-full py-1 px-3 hover:bg-tribugreen-medium mt-5 hover:text-tribugreen-regular cursor-pointer text-sm"
          >
            {{ $t("choose_album") }}
          </div>
          <div
            v-if="currentJournalType === 'premium'"
            class="text-tribugreen-regular  font-bold mt-5 cursor-pointer text-sm"
          >
            {{ $t("current_album") }}
          </div>
        </div>
     <!--  <div
          class="rounded-xl shadow white pb-4 flex flex-col items-center py-3"
          style="width: 220px"
          :style="currentJournalType==='kids' ? ' background-color:#E4F8F5' : ''"
        >
          <img src="../assets/icons/baby.svg" class="w-10" />
           <p class="text-tribugreen-regular capitalize" v-if="language !== 'NL'">
         {{ $t("album_word_choice") }} <b>Kids</b> 
          </p>
          <p class="text-tribugreen-regular capitalize" v-if="language === 'NL'">
           <b>Kids</b> {{ $t("album_word_choice") }} 
          </p>
          <p class="text-center my-2 text-xs">"{{ $t("kids_text") }}"</p>
          <div class="text-tribugreen-regular text-xs">
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("format_square") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">12 {{ $t("customizable_pages") }}</p>
            </div>
            <div class="flex mt-2">
              <img class="w-4 mr-2" src="../assets/icons/heart.svg" />
              <p class="text-xs">{{ $t("durable_paper") }}</p>
            </div>
          </div>
          <div
            v-if="currentJournalType !== 'kids'"
            @click="changeType('kids')"
            class="bg-tribugreen-regular text-white rounded-full py-1 px-3 hover:bg-tribugreen-medium mt-12 hover:text-tribugreen-regular cursor-pointer text-sm"
          >
            {{ $t("choose_album") }}
          </div>
          <div
            v-if="currentJournalType === 'kids'"
            class="text-tribugreen-regular font-bold mt-12 cursor-pointer text-sm"
          >
            {{ $t("current_album") }}
          </div>
        </div>-->
      </div>
      <div  class="flex mt-5 items-center " style="width:550px">
        <img class="w-4" src="../assets/icons/warning.svg" />
        <p class="text-red-500 text-xs text-center mt-4 font-bold">{{ $t("warning_red") }}</p>
      </div>
    <p @click="close" class="text-xs text-gray-500 mt-4 border-b border-gray-500 pb-1 cursor-pointer hover:text-black">{{$t('change_mind')}}</p>

    </div>
  </div>
</template>
<script>
import { directive } from "vue3-click-away";
import { Journal } from "../api"
import utils from '../utils';
export default {
  directives: {
    ClickAway: directive,
  },
  computed: {
    language(){
      return this.$store.state.language;
    },

    type() {
      return this.$store.state.type;
    },
    currentJournal(){
      return this.$store.state.currentJournal
    },
    currentJournalType(){
      if(this.currentJournal){
       return this.$store.state.currentJournal.type
      }
    },

  },
  methods: {
    onClickAway() {
       this.$store.commit("setShowChangeType", false);
    },
    close(){
      this.$store.commit("setShowChangeType", false);

    },
    async changeType(type){
      if(localStorage.pageSize === 'huge' && type === 'kids'){
        localStorage.pageSize = 'medium'
        this.$store.commit('setPageSize',localStorage.pageSize)
      }
      const oldType = this.currentJournal.type
      await Journal.changeType(type)
       utils.event('album_type_switched', {
        newspaper_id: this.$store.state.currentJournal.id,
        newspaper_subscription_type: type,
        newspaper_previous_subscription_type: oldType,
       
      });
      this.$store.commit("setShowChangeType", false); 
      document.location.reload()     
      }

    }
  ,
};
</script>
