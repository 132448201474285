<template>
  <div
    class="w-full bg-tribugreen-light flex flex-col items-center pt-10 min-h-screen h-full font-inter "
    style="image-rendering: auto"
  >
   <a v-if="tribe" :href="menuLink" class="font-bold text-lg absolute left-0 ml-6 border-b border-black">{{menu}}</a>
    <img src="../assets/logo-black.svg" class="w-14 mb-5" />
    <div style="width: 300px">
      <div :class="mobileOperatingSystem === 'desktop' ? '' : 'mt-7'">
        <p class="text-xl font-bold text-center">
          {{ $t("title_download_app1") }}
        </p>
        <p class="text-xl font-bold text-center">
          {{ $t("title_download_app2") }}
        </p>
      </div>
      <div
        class="text-center"
        :class="mobileOperatingSystem === 'desktop' ? 'mt-5' : 'px-10 mt-8'"
      >
        <p>{{ $t("download_app_content") }}</p>
      </div>
      <div :class="mobileOperatingSystem === 'desktop' ? 'mt-3' : 'mt-7'">
        <div v-if="mobileOperatingSystem === 'desktop'">
          <div v-if="language === 'FR'" class="flex items-center">
            <a
              href="https://play.google.com/store/apps/details?id=be.mytribunews.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              ><img
                alt="Disponible sur Google Play"
                style="width: 170px"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
            /></a>
            <a
              href="https://apps.apple.com/us/app/tribu-news/id1474359727?itsct=apps_box_badge&amp;itscg=30200"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 13px;
                width: 150px;
              "
              ><img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-fr?size=250x83&amp;releaseDate=1570406400&h=f57f05b4c8abb10ad7e9162b3b70eb05"
                alt="Download on the App Store"
                style="border-radius: 13px"
            /></a>
            <a
              href="https://appgallery.cloud.huawei.com/ag/n/app/C102633113?locale=fr_FR&source=appshare&subsource=C102633113"
              class="ml-2"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 10px;
                width: 150px;
              "
            >
              <img src="../assets/img/appgallery-fr.png" />
            </a>
          </div>
        <div v-if="language === 'EN' || language === 'DE'" class="flex items-center">
            <a
              href="https://play.google.com/store/apps/details?id=be.mytribunews.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              ><img
                alt="Get it on Google Play"
                style="width: 170px"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            /></a>
            <a
              href="https://apps.apple.com/us/app/tribu-news/id1474359727?itsct=apps_box_badge&amp;itscg=30200"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 13px;
                width: 150px;
              "
              ><img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1570406400&h=30fe9f092579fd3c6dbe98999b77126e"
                alt="Download on the App Store"
                style="border-radius: 13px"
            /></a>
            <a
              href="https://appgallery.cloud.huawei.com/ag/n/app/C102633113?locale=en_EN&source=appshare&subsource=C102633113"
              class="ml-2"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 10px;
                width: 150px;
              "
            >
              <img src="../assets/img/appgallery-en.png" />
            </a>
          </div>
        <div v-if="language === 'NL'" class="flex items-center">
            <a
              href="https://play.google.com/store/apps/details?id=be.mytribunews.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              ><img
                alt="Ontdek het op Google Play"
                style="width: 170px"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/nl_badge_web_generic.png"
            /></a>
            <a
              href="https://apps.apple.com/us/app/tribu-news/id1474359727?itsct=apps_box_badge&amp;itscg=30200"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 13px;
                width: 150px;
              "
              ><img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/nl-nl?size=250x83&amp;releaseDate=1570406400&h=e1a763cebb24a3195142f33224f797a7"
                alt="Download on the App Store"
                style="border-radius: 13px"
            /></a>
            <a
              href="https://appgallery.cloud.huawei.com/ag/n/app/C102633113?locale=nl_NL&source=appshare&subsource=C102633113"
              class="ml-2"
              style="
                display: inline-block;
                overflow: hidden;
                border-radius: 10px;
                width: 150px;
              "
            >
              <img src="../assets/img/appgallery-en.png" />
            </a>
          </div>
        </div>
        <div class="w-full flex justify-center mt-10">
          <div
            v-if="mobileOperatingSystem !== 'desktop'"
            class="rounded-full shadow bg-tribugreen-regular text-white text-center px-5 py-2 cursor-pointer"
          >
            <a
              v-if="mobileOperatingSystem === 'Android'"
              :href="
                'https://play.google.com/store/apps/details?id=be.mytribunews.app&hl=' +
                language.toLowerCase()
              "
              >{{ $t("download_app_button") }}</a
            >
            <a
              v-if="mobileOperatingSystem === 'iOS'"
              href="https://apps.apple.com/be/app/tribu-news/id1474359727"
              >{{ $t("download_app_button") }}</a
            >
          </div>
        </div>
        <div
          class="w-full flex justify-center"
          :class="mobileOperatingSystem === 'desktop' ? '-mt-5' : ' mt-10 '"
        >
          <img
            src="../assets/img/phone.png"
            :style="
              mobileOperatingSystem === 'desktop'
                ? 'margin-left:20%'
                : 'margin-left:30%'
            "
            :class="mobileOperatingSystem === 'desktop' ? 'w-8/12 mb-10' : ''"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center ">
    <a :href="urlFAQ"  target="_blank" class="text-sm text-center">{{$t("error_desktop_display_1")}} <span class="underline cursor-pointer">{{$t("error_desktop_display_2")}}</span></a>
   </div>
  </div>
</template>
<script>
import {  User } from "../api";
export default {
  name: "MobileScreen",
    async mounted() {
    this.menuObject = await User.getMenu();
  },
  data(){
    return{
       menuObject: null,
    }
  },
  computed: {
    menuLink(){
      for(let i=0; i < this.menuObject.length; i++ ){
        if(this.menuObject[i].key === "list_members"){
          return this.menuObject[i].link
        }
      }
    },
    route(){
        return import.meta.env.VITE_APP_OLDURL
    },
    tribe(){
      return this.$store.state.currentTribe
    },
    urlFAQ(){
      if(this.language === 'FR'){
        return "https://faq.mytribunews.com/fr/article/on-me-propose-de-telecharger-lapp-alors-que-je-suis-sur-pc-1dlb10u/?bust=1651050458503"
      }
      if(this.language === 'DE'){
        return "https://faq.mytribunews.com/de/article/mir-wird-angeboten-die-app-herunterzuladen-obwohl-ich-am-pc-bin-v4i2lw/"
      }
      if(this.language === 'EN'){
        return "https://faq.mytribunews.com/en/article/i-have-been-asked-to-download-the-app-when-i-am-on-a-pc-1qbbxxy/"
      }
      if(this.language === 'NL'){
        return "https://faq.mytribunews.com/nl/article/er-wordt-mij-gevraagd-de-app-te-downloaden-maar-ik-werk-op-een-pc-ozcour/"
      }
    },
    language() {
      return this.$store.state.language;
    },
    mobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "desktop";
    },
    menu() {
    let text = '';
    if (this.language === 'FR') {
        text = 'Menu';
    } else if (this.language === 'NL') {
        text = 'Menu';
    } else if (this.language === 'EN') {
        text = 'Menu';
    } else {
        text = 'Menü';
    }
    return text;
        },
  },
};
</script>
