<template>
  <div class="mt-24 pt-3 h-screen overflow-auto scrollbarPhotos pb-44" >
    <div
      :class="showImportPage?'mt-10':''"
      class="flex flex-wrap px-4 mb-6 justify-end items-center"
      style="direction: ltr"
      v-if="showProgressBar && numberOfUploadedImages != fakeImages"
    >
    <div>
      <img style="width:30px; margin-top:-9px; margin-right:5px" src="../../assets/icons/spinner.gif"/>
    </div>
      <p class="text-xs text-right mb-2">
        {{ numberOfUploadedImages }}/{{ fakeImages }}
        {{ $t("uploaded_pictures") }}
      </p>
      <base-progress :percentage="contentProgress" class="mx-2 mb-2 h-5">
      </base-progress>
    </div>
    <div
      v-if="imgList.length === 0 && !fakeImages "
      class="flex flex-wrap justify-center mt-5 h-screen"
    >
      <div v-for="index in fakeImageImport" :key="index">
        <div
          style="width: 114px; height: 100px"
          class="bg-tribugray-light mx-1 py-1"
        ></div>
      </div>
    </div>
    <div v-else :class="showImportPage?'mt-10':''">
      <div v-for="list in imgList" :key="imgList.indexOf(list)">
        <p
          class="font-semibold mr-7 mt-1 w-10/12 border-b border-tribugray-light pr-2 text-left"
          style="font-size: 15px"
        >
          {{ $t(list.date[0].month) }}
          <span class="text-tribugray-semilight ml-1">
            {{ list.date[0].year }}</span
          >
        </p>
        <div
          v-if="
            !showUploadModal &&
            currentMonth === list.date[0].month &&
            currentYear === list.date[0].year
          "
          class="flex flex-wrap justify-center mt-4 fake-image"
        ></div>
        <div class="flex justify-center">
          <images-list
            :list="columnLeft(list.images)"
            :key="componentKey"
          ></images-list>
          <images-list
            :list="columnRight(list.images)"
            :key="componentKey"
          ></images-list>
        </div>
       
      </div>
       <observer v-on:intersect="intersected"></observer>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { Images } from "../../api";
import moment from "moment";
import Observer from "../DisplayImages/Observer.vue";
import BaseProgress from "../BaseProgress.vue";
const ImagesList = defineAsyncComponent(() =>
  import("../DisplayImages/ImagesList.vue")
);
export default {
  name: "SidebarImages",
  beforeMount() {
    this.getImages();
  },

  data() {
    return {
      datesArray: [],
      progressStart: 0,
      contentProgress: 0,
      showProgressBar: false,
      firstCall: true,
      componentKey: 0,
    };
  },
  components: {
    ImagesList,
    Observer,
    BaseProgress,
  },
  watch: {

    reloadSidebar() {
      if (this.reloadSidebar) {
        this.$store.commit("setReloadSidebar", false);
        this.getImages();
      }
    },
    fakeImages() {
      if (this.fakeImages !== 0) {
        this.showProgressBar = true;
      }
    },
    userInfos() {
      this.datesArray = [];
      this.getImages();
    },
    numberOfUploadedImages(value, oldValue) {
      if(value > 0){
        if(localStorage.guide && this.$store.state.stepGuide === 2){
          this.$store.commit('setShowGuide',true)
        }
      }
      if (value > oldValue) {
        this.increasePercentage();
      }
      if (value === this.fakeImages && this.fakeImages !== 0) {
        this.$store.commit("setFakeImages", 0);
        this.$store.commit("setNumberOfImages", 0);
      }
    },
    contentProgress() {
      if (this.contentProgress === 100) {
        this.showProgressBar = false;
        this.contentProgress = 0;
        this.$store.commit("setNumberOfImages", 0);

      }
    },
  },

  computed: {
    fakeImageImport(){
      return this.showImportPage? 20 : 12
    },
    showImportPage(){
      return this.$store.state.showImportPage
    },
    reloadSidebar() {
      return this.$store.state.reloadSidebar;
    },
    numberOfUploadedImages() {
      return this.$store.state.numberOfImages;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    showUploadModal() {
      return this.$store.state.showUploadModal;
    },
    imgList() {
      return this.$store.state.imagesPerMonth;
    },
    currentMonth() {
      return moment().format("MMMM");
    },
    currentYear() {
      return moment().format("YYYY");
    },
    fakeImages() {
      return this.$store.state.fakeImages;
    },
    uploadedImages() {
      return this.$store.state.uploadedImages;
    },
    userInfos() {
      return this.$store.state.userInfos;
    },

    filter() {
      return this.$store.state.filter;
    },
    filterMember() {
      return this.$store.state.filterMember;
    },
    noImageView() {
      return this.$store.state.noImageView;
    },
    showUploadModal() {
      return this.$store.state.showUploadModal;
    },
  },
  methods: {
    increasePercentage() {
      this.contentProgress += 100 / this.fakeImages;
    },

    async getImages() {
      if (this.filter === "member") {
        const images = await Images.getAllImages(
          "filter[creator_id]=" + this.filterMember
        );
        this.$store.commit("cleanArrayImage", images);
        this.$store.commit("setArrayImage", images);
      }

      if (this.filter === "onlyUnused") {
        const images = await Images.getAllImages("filter[already_used]=false");
        this.$store.commit("cleanArrayImage", images);
        this.$store.commit("setArrayImage", images);
      }

      if (this.filter === "all") {
        const images = await Images.getAllImages();
        this.$store.commit("cleanArrayImage", images);
        this.$store.commit("setArrayImage", images);
      }
      if(this.$store.state.uploadedImages[0]){
      if (this.$store.state.uploadedImages[0].data.length !== 0) {
        this.getUploadDates();
        this.filterImagesPerMonths();
      }
      }
      for(let i = 0;i < this.$store.state.uploadedImages.length; i++ ){
        for(let j = 0; j < this.$store.state.uploadedImages[i].data.length; j++){
          this.$store.commit('setAllImagesList', this.$store.state.uploadedImages[i].data[j])
        }

      }
    },
    splitedDatesArray(date) {
      const datesObjectArray = [];
      let split = date.split(" ");
      const newObject = {};
      newObject["month"] = split[0];
      newObject["year"] = split[1];
      datesObjectArray.push(newObject);
      return datesObjectArray;
    },
    filterImagesPerMonths() {
      this.$store.commit("setImagesPerMonth", []);
      let imgList = [];

      for (let i = 0; i < this.datesArray.length; i++) {
        const newObject = {};
        const splitedDate = this.splitedDatesArray(this.datesArray[i]);
        newObject["date"] = splitedDate;
        let imgPerMonth = [];
        if(this.uploadedImages){
        this.uploadedImages.forEach((element) => {
          for (let j = 0; j < element.data.length; j++) {
            const createdAt = moment(element.data[j].created_at).format(
              "MMMM YYYY"
            );
            if (createdAt === this.datesArray[i]) {
              imgPerMonth.push(element.data[j]);
            }
          }
          newObject["images"] = imgPerMonth;

          imgList.push(newObject);
        });
        }
      }

      if (
        imgList[0].date[0].month !== this.currentMonth ||
        imgList[0].date[0].year !== this.currentYear
      ) {
        const emptyDateSection = {
          date: [{ month: this.currentMonth, year: this.currentYear }],
          images: [],
        };
        imgList.unshift(emptyDateSection);
      }
      Array.prototype.unique = function () {
        return Array.from(new Set(this));
      };
      this.$store.commit("setImagesPerMonth", imgList.unique());
    },
    getUploadDates() {
      if (this.uploadedImages) {
        for (let i = 0; i < this.uploadedImages.length; i++) {
          for (let j = 0; j < this.uploadedImages[i].data.length; j++) {
            const monthAndYear = moment(
              this.uploadedImages[i].data[j].created_at
            ).format("MMMM YYYY");
            if (this.datesArray.indexOf(monthAndYear) === -1) {
              this.datesArray.push(monthAndYear);
            }
          }
        }
      }
    },
    columnLeft(list) {
      const imgList = [];
      for (const [i, value] of list.entries()) {
        if (i % 2 === 0) {
          imgList.push(value);
        }
      }
      return imgList;
    },
    columnRight(list) {
      const imgList = [];
      for (const [i, value] of list.entries()) {
        if (i % 2 !== 0) {
          imgList.push(value);
        }
      }
      return imgList;
    },
    async intersected() {
      if (this.firstCall) {
        this.firstCall = false;
      } else {
        if (this.uploadedImages) {
          if (this.uploadedImages.length !== 0) {
            let cursorUrl = null
            if(this.uploadedImages[this.uploadedImages.length - 1]){
              cursorUrl = this.uploadedImages[this.uploadedImages.length - 1].links.next;
            }
            if (cursorUrl) {
              let params = new URL(cursorUrl).searchParams;
              let cursor = params.get("cursor");
              const images = await Images.getAllImages("cursor=" + cursor);
              this.uploadedImages.push(images);
             for(let i = 0; i< Object.keys(images.data).length; i++){
                this.$store.commit('setAllImagesList', images.data[i])

             }
              this.getUploadDates();
              this.filterImagesPerMonths();
            }
          }
        }
      }
    },
  },
};
</script>
<style>
.fake-image {
  opacity: 1;
  animation: fade 2s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* CUSTOM SCROLLBAR */
.scrollbarPhotos {
  direction: rtl;
}
.scrollbarPhotos::-webkit-scrollbar {
  width: 8px;
  -webkit-box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.23);
  box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.4);
}
.scrollbarPhotos::-webkit-scrollbar-thumb {
  background: #e0dcdc !important;
  border-radius: 10px;
  min-height: 60px !important;
}
.scrollbarPhotos {
  scrollbar-color: #f7f6f6 #fff !important;
  -moz-appearance: none !important;
}
</style>
