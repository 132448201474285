import APICONF from "../api-config";
import * as Sentry from "@sentry/vue";

import store from "../store";
export default {
  async getUserInformations(tribe) {
    try {
      let user = null;
      if (tribe && localStorage.token) {
        localStorage.removeItem("impersonate_token");
        const res = await APICONF.get(`/me?impersonate_tribe=${tribe}`);
        if (res.data.impersonate_token) {
          localStorage.impersonate_token = res.data.impersonate_token;
          user = res.data.me;
        } else{
          user = res.data;
        }
      } else {
        const res = await APICONF.get("/me");
        user = await res.data;
      }
      store.commit("setUserinfos", user);

      Sentry.setUser({
        id: user.id,
        email: user.email,
      })

      return user;
    } catch (error) {
      if(error.response){
        if(error.response.status !== 401){
          store.commit("setErrorMessage", "error_get_user");
         }
        }else{
          document.location.reload()
        }
        
    }
  },
  async getMenu() {
    try {
      const lang = localStorage.language.toLowerCase();
      const res = await APICONF.get(`/menu?web&&lang=${lang}`);
      const menu = await res.data;
      return menu;
    } catch (error) {
      if(error.response){
        if(error.response.status !== 401){
          store.commit("setErrorMessage", "error_get_menu");
         }
        }
    }
  },
};
