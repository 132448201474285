import APICONF from "../api-config";
import store from "../store";
import Journal from "./journal";
import utils from "../utils";
import axios from 'axios';

export default {
  async getAllLayouts() {
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }

      const res = localStorage.impersonate_token? await axios.get(root+"/layouts",{headers}): await APICONF.get("/layouts");
      store.commit("setLayouts", res.data);
      return res.data;
    } catch (error) {
      if(error.response){
        if(error.response.status !== 401){
          store.commit("setErrorMessage", "error_get_layouts");
         }
         else{
          document.location.reload()
        }
        }

    }
  },
  async getAllLayoutsBlocks() {
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      const res = localStorage.impersonate_token? await axios.get(root+"/blocks-layouts",{headers}) : await APICONF.get("/blocks-layouts");
      store.commit("setLayoutBlock", res.data);
      return res.data;
    } catch (error) {
      if(error.response){
        if(error.response.status !== 401){
          store.commit("setErrorMessage", "error_get_block_layouts");
         }
        }
      
    }
  },
  async updatePageLayout(journalId, pageId, body) {
    store.commit("setCallisDone", false);
    try {
      const root= import.meta.env.VITE_API_ROOT
      const headers= {
       'Authorization' : 'Bearer ' + localStorage.impersonate_token,
       'Content-Type' : 'application/json',
        'X-WEB' : 'true' 
      }
      const impersonateJournal = store.state.paramsJournal
      localStorage.impersonate_token? await axios.put(
        root+"/journals/" + impersonateJournal + "/pages/" + pageId + "/layout",
        body,{headers}
      ): await APICONF.put(
        "/journals/" + journalId + "/pages/" + pageId + "/layout",
        body
      );
      let currentJournal = null;
      if(localStorage.impersonate_token){
        currentJournal = await Journal.getSpecificJournal(store.state.userInfos.current_tribe,impersonateJournal)
      }else{
        currentJournal =  await Journal.getCurrentJournal();
      }
      let displayedPage = currentJournal.pages.find(item => item.order === store.state.selectedPage);
      store.commit("setDisplayedPage", displayedPage);
      store.commit("setCurrentJournal", currentJournal);

      await utils.journalEvent('layout_updated')
      utils.changeLastSave();
    } catch (e) {
      console.log('error', e)
      if (e.response) {
        if (e.response.status === 410) {
          store.commit("setModalErrorPage", true);
        }
      }
    }
    store.commit("setCallisDone", true);
  },
};
