import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/index.css'
import store from "./store"
import i18n from './i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import UniversalSocialAuth from 'universal-social-auth';
import * as Sentry from '@sentry/vue';
import {Integrations} from "@sentry/tracing";
import { createGtm } from 'vue-gtm';
import VueCountdown from '@chenfengyuan/vue-countdown';

//import {launch, configure } from 'notificare-web/core';
/*configure( {
	applicationId: '661fd9d7fa42b25d5fbf3b9b',
	applicationKey: 'bb9f282e0ecad6f2acf18e6d5f9b43fcdd1be33fc2d417b7a007aa5501352bea',
	applicationSecret: '189083d5f5ae67d4b9b5b6d599dc48bf07b6a9e00990c350aaef05221a32c5ef',
  });
import { } from 'notificare-web/core';
import { } from 'notificare-web/assets';
import { } from 'notificare-web/geo';
import { } from 'notificare-web/in-app-messaging';
import { } from 'notificare-web/inbox';
import { } from 'notificare-web/push';
import { } from 'notificare-web/push-ui';
import { } from 'notificare-web/user-inbox';

// CSS files
import 'notificare-web/in-app-messaging/in-app-messaging.css';
import 'notificare-web/push/push.css';
import 'notificare-web/push-ui/push-ui.css';
*/

import VueSocialSharing from 'vue-social-sharing'

const Oauth = new UniversalSocialAuth(axios, {
  providers: {
    facebook: {
      clientId: '558083000906279',
      redirectUri: import.meta.env.VITE_APP_URL+'/oauth/callback',
    },
    google: {
      clientId: '542237392229-6v5ols1g57k8413v0p9euqtdquhmg811.apps.googleusercontent.com',
      redirectUri: import.meta.env.VITE_APP_URL+'/oauth/callback',
    },
    apple: {
      clientId: 'be.mytribunews.app.service',
      responseMode: 'query',
      scope: '',
      optionalUrlParams: ['response_mode', 'state'],
      redirectUri: import.meta.env.VITE_APP_URL+'/oauth/callback',
    }
  }
})

const app = createApp(App)
if (import.meta.env.VITE_APP_ENV !== 'local') {
Sentry.init({
  app,
  dsn: 'https://572cb48e3ba34b759cdd7386ce83be77@o559421.ingest.sentry.io/5729543',
  integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['staging.mytribunews.com', 'app.mytribunews.com', 'alpha.mytribunews.com', 'editor.mytribunews.com'],
      }),
  ],
  environment: import.meta.env.VITE_APP_ENV,
  autoSessionTracking: true,
  tracesSampleRate: 0.2,
  release: import.meta.env.VITE_SENTRY_RELEASE,
})
}

app.component(VueCountdown.name, VueCountdown);
app.config.globalProperties.$Oauth = Oauth
app.use(store)
app.use(router)
//app.use(launch)

app.use(createGtm({
  id: import.meta.env.VITE_APP_GTM,
  enabled: true,
  loadScript: true,
  vueRouter: router,
}))
app.use(i18n)
app.use(Notifications)
app.use(VueAxios, axios)
app.use(VueSocialSharing)
app.mount('#app')
